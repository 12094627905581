import { SET_USER_ROLES } from './user-actions';

export const USER_STATE_KEY = 'USER_REDUCER';

export interface IUserState {
  roles: string[];
  isEditAllowed: boolean;
  isAccessDenied: boolean;
}

const defaultState: IUserState = {
  roles: [],
  isEditAllowed: false,
  isAccessDenied: true,
};

export const userReducer = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case SET_USER_ROLES:
      return {
        ...state,
        roles: payload,
        isEditAllowed: payload.includes('write'),
        isAccessDenied: !payload.length,
      };
    default:
      return state;
  }
};

export const getIsAccessDenied = (state: any) => state[USER_STATE_KEY].isAccessDenied;
export const getIsEditAllowed = (state: any) => state[USER_STATE_KEY].isEditAllowed;
export const getUserRoles = (state: any) => state[USER_STATE_KEY].roles;
export const getWriteTabsPermissions = (state: any) => state[USER_STATE_KEY].writeTabsPermissions;

import { COMMON_STATE_KEY } from './common-reducer';
import { get } from 'lodash';
import { ReasonRampSettingsDto } from '../../models/ramp-settings-dto';

export const getRampSettings = (state: any): ReasonRampSettingsDto => state[COMMON_STATE_KEY].reasonsRampSettings;
export const getSelectedWeekDaysPatterns = (state: any) => state[COMMON_STATE_KEY].selectedWeekDaysPatterns;
export const getSettingsLoader = (state: any) => state[COMMON_STATE_KEY].settingsLoading;
export const getSelectLoader = (state: any) => state[COMMON_STATE_KEY].selectLoading;
export const getSelectLoadingId = (state: any) => state[COMMON_STATE_KEY].selectLoadingId;
export const getSelectOptions = (state: any) => state[COMMON_STATE_KEY].selectOptions;
export const getPatternByWeekDay = (state: any, weekNumber, dayOfWeek) =>
  get(getSelectedWeekDaysPatterns(state), `[${weekNumber}][${dayOfWeek}]`, null);

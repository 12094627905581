import React from 'react';
import { isEmpty } from 'lodash';
import { ODScheduleCell } from '../cell-components/ODScheduleCell';
import { getIsSameOrLaterThanToday, joinTime } from '../../../common/helpers/date';
import { Dropdown, Input } from 'antd';
import styles from '../BaseGrid.module.scss';

export const weekGridSingleOfficeColumns = (isCellDisabled) => {
  return [
    {
      dataIndex: ['template'],
      key: 'template',
      width: 220,
      className: 'bordered-left',
    },
    {
      title: 'Description',
      dataIndex: 'templateDescription',
      key: 'templateDescription',
      width: 200,
    },
    {
      title: 'Range 0 template',
      dataIndex: 'templateName',
      key: 'templateName',
    },
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      width: 80,
      render: (text, { time, date }) => (
        <div className={isEmpty(time) || isCellDisabled(date) ? 'grayed-out' : ''}>{text}</div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 90,
      render: (text, { time, date }) => (
        <div className={isEmpty(time) || isCellDisabled(date) ? 'grayed-out' : ''}>{text}</div>
      ),
    },
    {
      title: 'Office HOO',
      dataIndex: 'ooh',
      key: 'ooh',
      width: 130,
      render: ({ start, end }, { date }) => (
        <div className={isCellDisabled(date) ? 'grayed-out' : ''}>{joinTime(start, end)}</div>
      ),
    },
    {
      title: 'OD Schedule',
      dataIndex: 'time',
      key: 'time',
      width: 130,
      render: ({ start, end }, { date }) => (
        <div className={isCellDisabled(date) ? 'grayed-out' : ''}>{joinTime(start, end)}</div>
      ),
    },
  ];
};

import styled from 'styled-components';
import { Calendar } from 'react-big-calendar';

const StyledCalendarContainer = styled.div`
  height: 750px;
`;

const StyledCalendar = styled(Calendar)`
  .rbc-time-view {
    border: none;
  }

  .rbc-time-slot {
    display: flex;
    align-items: flex-end;
  }

  .rbc-time-header {
    display: none;
  }

  .rbc-time-content {
    border-top: none;
  }

  .rbc-day-slot .rbc-event {
    min-height: 5px;
    border-radius: 2px;
    padding: 0;
    border: none;
    line-height: 1;
    width: 100% !important;
    left: 0 !important;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    transition: min-height 0.6s ease;
    color: #003366;

    &.custom-event {
      &:hover {
        min-height: 15px;
        z-index: 1;

        .rbc-event-label {
          display: block;
        }

        .rbc-event-content {
          display: block;
        }
      }
    }

    &.selected-event {
      opacity: 0.9;
      border: 1px solid #5a809a66;
      filter: drop-shadow(1px 1px 1px #5a809a7f) contrast(115%);
    }

    .rbc-event-label,
    .rbc-event-content {
      padding-top: 2px;
      font-size: 11px;
      height: max-content;
      width: max-content;
      margin-right: 5px;
      flex: none;
      display: none;
    }

    .rbc-event-content {
      font-weight: bold;
    }
  }

  .rbc-current-time-indicator {
    display: none;
  }
`;

const EventPopoverContent = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin: -5px;
  display: flex;

  > :first-child {
    margin-right: 20px;
  }
`;

export { StyledCalendarContainer, StyledCalendar, EventPopoverContent };

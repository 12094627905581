import React from 'react';
import { Checkbox, DatePicker, Input, Select } from 'antd';
import { SuffixIcon } from './SuffixIcon';
import NumberFormat from 'react-number-format';
import { getInvalidDates } from '@med-fe/util';
import { TagRenderer } from './TagRenderer';
import { get } from 'lodash';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const formItemMap = (
  {
    type,
    data,
    onSelect,
    placeholder,
    mode,
    onFocus,
    options,
    onSearch,
    onPopupScroll,
    onChange,
    onClear,
    onKeyPress,
    onPaste,
    isLoading,
    allowClear,
    inputType,
    min,
    max,
    step,
    defaultValue,
  }: any,
  isDisabled = false
): any => {
  const memorizeDate = moment().format('YYYY-MM-DD');
  switch (type) {
    case 'date':
      return (
        <DatePicker
          disabled={isDisabled}
          format={'YYYY-MM-DD'}
          disabledDate={getInvalidDates}
          allowClear={allowClear}
          inputReadOnly
        />
      );
    case 'daterange':
      return (
        <RangePicker
          disabled={isDisabled}
          format={'YYYY-MM-DD'}
          disabledDate={getInvalidDates}
          allowClear={allowClear}
          placeholder={placeholder}
          inputReadOnly
          defaultValue={defaultValue && [moment(memorizeDate)]}
        />
      );
    case 'select':
      return (
        <Select
          showSearch
          allowClear={allowClear}
          onClear={onClear}
          placeholder={placeholder}
          maxTagCount={'responsive'}
          mode={mode}
          tagRender={(props) => <TagRenderer {...props} />}
          onSelect={onSelect}
          onFocus={onFocus}
          onBlur={onFocus}
          suffixIcon={<SuffixIcon />}
          onSearch={onSearch}
          onPopupScroll={onPopupScroll}
          filterOption={() => true}
          loading={isLoading}
        >
          {(options || data).map((option) => {
            const key = options ? option.id : get(option, 'id', option[0]);
            const value = options ? option.value : get(option, 'name', option[1]);
            return (
              <Option key={key} value={key}>
                {value}
              </Option>
            );
          })}
        </Select>
      );
    case 'checkbox':
      return <Checkbox disabled={isDisabled} />;
    case 'phone-field':
      return (
        <NumberFormat
          format='###-###-####'
          mask='_'
          customInput={
            isDisabled
              ? (inputProps: any) => <Input {...inputProps} disabled={isDisabled} allowClear={allowClear} />
              : Input
          }
        />
      );
    default:
      return (
        <Input
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
          allowClear={allowClear}
          disabled={isDisabled}
          type={inputType}
          min={min}
          max={max}
          step={step}
        />
      );
  }
};

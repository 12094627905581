import React, { useEffect } from 'react';
import { Button, Form, Input, DatePicker, Row, Col, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useSpecialRanges } from '../../../common/hooks/specialRanges';
import { SuffixIcon, TagRenderer } from '@med-fe/ui';
import { getSearchNewOffices } from '../../../pace/new-offices/new-offices-selectors';
import { setSearchNewOffices } from '../../../pace/new-offices/new-offices-actions';

const { RangePicker } = DatePicker;

function SearchNewOffices({ activeTab, search }: any) {
  const { currentValue, setRegionIds, setDistrictIds } = useSpecialRanges(activeTab);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const newOfficesSearch = useSelector(getSearchNewOffices);
  const locStatusTypes = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Closed', value: 'CLOSED' },
    { label: 'Hold', value: 'HOLD' },
  ];

  useEffect(() => {
    form.setFieldsValue({
      dateRange: [],
      regionIds: [],
      districtIds: [],
    });
    setRegionIds([]);
    setDistrictIds([]);
  }, [activeTab]);

  const onSubmit = (formData) => {
    const { dateRange } = formData;
    const dates = {
      startDate: null,
      endDate: null,
    };

    if (dateRange) {
      dates['startDate'] = dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null;
      dates['endDate'] = dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null;
    }

    dispatch(setSearchNewOffices({ ...newOfficesSearch, ...formData, ...dates, page: 0 }));
  };

  return (
    <div className='search-container'>
      <Form
        form={form}
        layout='vertical'
        fields={[
          {
            name: ['searchReason'],
            value: currentValue,
          },
        ]}
        // className='search-container'
        onFinish={onSubmit}
      >
        <Row wrap={false}>
          <Col>
            <Row wrap={false}>
              <Form.Item
                key='office'
                label='Search By Office Name or Number'
                name='office'
                className='label-text input-field input-field_name'
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                key='dateRange'
                name='dateRange'
                label='Select Office Launch Date Range'
                className='label-text input-field input-field_date-range'
              >
                <RangePicker allowEmpty={[false, false]} format={['YYYY-MM-DD']} separator='-' />
              </Form.Item>
              <Form.Item
                key='statuses'
                label={'Select Office Status'}
                name='statuses'
                className='label-text status-type'
              >
                <Select
                  allowClear
                  mode={'multiple'}
                  tagRender={(props) => <TagRenderer {...props} />}
                  options={locStatusTypes}
                  suffixIcon={<SuffixIcon />}
                />
              </Form.Item>
              <Col style={{ display: 'flex', marginTop: '10px' }}>
                <Form.Item className='search-btn'>
                  <Button type='primary' htmlType='submit'>
                    SEARCH
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default SearchNewOffices;

import moment from 'moment';

export const OD_TO_NL_TEMPLATES_CONFIG = {
  dateRange: {
    label: 'Date Range Start and End',
    allowClear: true,
    type: 'daterange',
    defaultValue: [moment().format('YYYY-MM-DD')],
  },
};

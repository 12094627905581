import React from 'react';
import './HourPatternCell.scss';
import { isEmpty } from 'lodash';
import { getWeekAverage } from '../helpers/average';

export const HourPatternCell = ({ pattern: { hours, averages } }) => {
  return (
    <div className='hour-pattern-cell'>
      {hours.map((count, index) => (
        <div
          className={`hour-pattern-cell__day ${count === 0 ? 'hour-pattern-cell__day_closed' : ''}`}
          key={String(index)}
        >
          {new Array(Math.round(count > 0 || count === 0 ? count : count !== -1 ? Math.abs(count) : 0))
            .fill(0)
            .map((it, idx) => (
              <div className='hour-pattern-cell__hour' key={String(idx)} />
            ))}
          {!isEmpty(averages) && hours[index] !== 0 && hours[index] !== 0 && (
            <div className='hour-pattern-cell__day-average'>{Math.round(averages[index] * 10) / 10}</div>
          )}
        </div>
      ))}
      <div className='hour-pattern-cell__week-average'>{!isEmpty(averages) && getWeekAverage({ averages, hours })}</div>
    </div>
  );
};

export const SEARCH_TEMPLATES = 'SEARCH_TEMPLATES';
export const SEARCH_TEMPLATES_SUCCESS = 'SEARCH_TEMPLATES_SUCCESS';
export const SEARCH_TEMPLATES_FAILED = 'SEARCH_TEMPLATES_FAILED';
export const SET_SEARCH_TEMPLATES = 'SET_SEARCH_TEMPLATES';
export const SET_SORT_CRITERIAS = 'SET_SORT_CRITERIAS';

export const FETCH_TEMPLATE_DETAILS = 'FETCH_TEMPLATE_DETAILS';
export const FETCH_TEMPLATE_DETAILS_SUCCESS = 'FETCH_TEMPLATE_DETAILS_SUCCESS';
export const FETCH_TEMPLATE_DETAILS_FAILED = 'FETCH_TEMPLATE_DETAILS_FAILED';

export const REMOVE_TEMPLATES = 'REMOVE_TEMPLATES';
export const REMOVE_TEMPLATES_SUCCESS = 'REMOVE_TEMPLATES_SUCCESS';
export const REMOVE_TEMPLATES_FAILED = 'REMOVE_TEMPLATES_FAILED';

export const RENAME_TEMPLATE = 'RENAME_TEMPLATE';
export const RENAME_TEMPLATE_SUCCESS = 'RENAME_TEMPLATE_SUCCESS';
export const RENAME_TEMPLATE_FAILED = 'RENAME_TEMPLATE_FAILED';

export const SET_EDITED_EXAM = 'SET_EDITED_EXAM';

export const IMPORT_FILE = 'IMPORT_FILE';
export const IMPORT_FILE_SUCCESS = 'IMPORT_FILE_SUCCESS';
export const IMPORT_FILE_FAILED = 'IMPORT_FILE_FAILED';

export const CLEAN_IMPORT_LAYOUT = 'CLEAN_IMPORT_LAYOUT';
export const UPDATE_VALIDATED_IMPORT = 'UPDATE_VALIDATED_IMPORT';
export const UPDATE_VALIDATED_IMPORT_SUCCESS = 'UPDATE_VALIDATED_IMPORT_SUCCESS';
export const UPDATE_VALIDATED_IMPORT_FAILED = 'UPDATE_VALIDATED_IMPORT_FAILED';

export const setSearchTemplates = (payload) => ({
  type: SET_SEARCH_TEMPLATES,
  payload,
});

export const searchTemplates = (payload) => ({
  type: SEARCH_TEMPLATES,
  payload,
});

export const setSortCriterias = (payload) => ({
  type: SET_SORT_CRITERIAS,
  payload,
});

export const searchTemplatesSuccess = (payload) => ({
  type: SEARCH_TEMPLATES_SUCCESS,
  payload,
});

export const searchTemplatesFailed = (payload) => ({
  type: SEARCH_TEMPLATES_FAILED,
  payload,
});

export const fetchTemplateDetails = (payload) => ({
  type: FETCH_TEMPLATE_DETAILS,
  payload,
});

export const fetchTemplateDetailsSuccess = (payload) => ({
  type: FETCH_TEMPLATE_DETAILS_SUCCESS,
  payload,
});

export const fetchTemplateDetailsFailed = (payload) => ({
  type: FETCH_TEMPLATE_DETAILS_FAILED,
  payload,
});

export const setEditedExam = (payload) => ({
  type: SET_EDITED_EXAM,
  payload,
});

export const removeTemplate = (payload) => ({
  type: REMOVE_TEMPLATES,
  payload,
});

export const removeTemplateSuccess = (payload) => ({
  type: REMOVE_TEMPLATES_SUCCESS,
  payload,
});

export const removeTemplateFailed = (payload) => ({
  type: REMOVE_TEMPLATES_FAILED,
  payload,
});

export const renameTemplate = (payload) => ({
  type: RENAME_TEMPLATE,
  payload,
});

export const renameTemplateSuccess = (payload) => ({
  type: RENAME_TEMPLATE_SUCCESS,
  payload,
});

export const renameTemplateFailed = (payload) => ({
  type: RENAME_TEMPLATE_FAILED,
  payload,
});

export const importFile = (payload, actionType) => ({
  type: IMPORT_FILE,
  payload,
  actionType,
});

export const importFileSuccess = (payload, totalCount) => ({
  type: IMPORT_FILE_SUCCESS,
  payload,
  totalCount,
});

export const importFileFailed = (payload) => ({
  type: IMPORT_FILE_FAILED,
  payload,
});

export const cleanImportLayout = () => ({
  type: CLEAN_IMPORT_LAYOUT,
});

export const updateValidatedImport = (payload, actionType, flag) => ({
  type: UPDATE_VALIDATED_IMPORT,
  payload,
  actionType,
  flag,
});

export const updateValidatedSuccess = (payload) => ({
  type: UPDATE_VALIDATED_IMPORT_SUCCESS,
  payload,
});

export const updateValidatedFailed = (payload) => ({
  type: UPDATE_VALIDATED_IMPORT_FAILED,
  payload,
});

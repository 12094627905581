import { ExceptionOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ExceptionCategoryEnum } from 'apps/pace/src/models/exception-catergory-enum';
import { useHistory } from 'react-router-dom';

export const ExceptionCell = ({ exceptionData }: any) => {
  const category = Object.entries(ExceptionCategoryEnum)?.filter(
    (item) => item[0] === exceptionData?.exceptionCategory
  );
  const stateDate = {
    employeeID: exceptionData?.employeeID,
    employeeName: exceptionData?.employeeName,
    exceptionCategory: [exceptionData?.exceptionCategory],
    officeNumber: exceptionData?.officeNumber,
  };
  const history = useHistory();
  const tooltipTitle = `Exception Category: ${category?.length > 0 ? category[0][1] : ''}, Effective Date: ${
    exceptionData?.effectiveDate
  }, End Date : ${exceptionData?.endDate ? exceptionData?.endDate : 'N/A'}`;
  return (
    <>
      {exceptionData && (
        <Tooltip placement='top' title={tooltipTitle}>
          <ExceptionOutlined onClick={() => history.push({ pathname: '/exception-management', state: stateDate })} />
        </Tooltip>
      )}
    </>
  );
};

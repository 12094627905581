import { isEmpty, isNil } from 'lodash';
import { DOCTORS_STATE_KEY, IDoctorsState, TEMPLATES_STATE_KEY } from './pace-reducer';
import { filterOptionsByWeek } from '../common/helpers/date';
export const getIsLoading = (state: any) => state[DOCTORS_STATE_KEY].loading;
export const getIsDoctorLoading = (state: any) => state[DOCTORS_STATE_KEY].loadingDoctor;
export const getDoctorsTotal = (state: any) => state[DOCTORS_STATE_KEY].total;
export const getActiveTab = (state: IDoctorsState) => state[DOCTORS_STATE_KEY].activeTab;
export const getIsHooChangedAction = (state: IDoctorsState) => state[DOCTORS_STATE_KEY].isHooChangedAction;
export const getDoctorsForGrid = (state: any) =>
  state[DOCTORS_STATE_KEY].doctors.map((doctor, idx) => ({
    key: `${doctor?.employeeId}-${idx}`,
    employeeName: doctor?.name,
    employeeId: doctor?.employeeId,
    employeeWeekPattern: doctor?.personalSchedule?.map((num) => Boolean(num)),
    employeeHourPattern: { hours: doctor?.personalSchedule, averages: doctor?.averages },
    office: doctor.office,
    officeName: doctor?.officeName,
    officeHoursOfOperation: { hours: doctor?.futureOfficeSchedule || doctor?.officeSchedule },
    officeFutureStartDate: doctor?.officeFutureStartDate,
    exceptionData: doctor?.exceptionData,
  }));
export const getDoctorsWithSpecialRanges = (state: any) =>
  state[DOCTORS_STATE_KEY].doctorsWithSpecialRanges?.map((doctor, idx) => ({
    key: `${doctor?.employeeId}-${idx}`,
    employeeName: doctor?.employeeName,
    employeeId: doctor?.employeeId,
    office: doctor?.office,
    officeName: doctor?.officeName,
    startDate: doctor?.startDate !== doctor?.endDate ? doctor?.startDate + ' - ' + doctor?.endDate : doctor?.startDate,
    templateName: doctor?.templateName,
    officeHoursOfOperation: { hours: doctor?.officeSchedule },
    reason: doctor?.reason,
    exceptionData: doctor?.exceptionData,
  }));
export const getODDataIncomplete = (state: any) =>
  state[DOCTORS_STATE_KEY].odDataIncomplete.map((doctor, idx) => ({
    key: `${doctor?.employeeId}-${idx}`,
    employeeName: doctor?.employeeName,
    employeeId: doctor?.employeeId,
    employeeNumber: doctor?.employeeNumber,
    office: doctor?.office,
    npiNumber: doctor?.npiNumber,
    pattern: doctor?.pattern,
    employeeIdW2W: doctor?.employeeIdW2W,
    allowToBeScheduled: doctor?.allowToBeScheduled,
  }));
export const getSearchDoctors = (state: any) => state[DOCTORS_STATE_KEY].search;
export const getSpecialRangesSearch = (state: any) => state[DOCTORS_STATE_KEY].specialRangesSearch;
export const getSpecialRangesReasonsSearch = (state: any) => state[DOCTORS_STATE_KEY].specialRangesReasonsSearch;
export const getRegions = (state: any) => state[DOCTORS_STATE_KEY].regions;
export const getDistricts = (state: any) => state[DOCTORS_STATE_KEY].districts;
export const getSelectedRow = (state: any) => state[DOCTORS_STATE_KEY].selectedRow;
export const getSelectedDoctorSpecial = (state: any, employeeId: string, office: string) => {
  const data = state[DOCTORS_STATE_KEY].selectedDoctorsSpecial;
  return data.find((doc) => doc.employeeId === employeeId && doc.office === office);
};
export const getSelectedDoctorsSpecial = (state: any) => {
  const data = state[DOCTORS_STATE_KEY].selectedDoctorsSpecial;
  if (isEmpty(data)) return [];

  const getWeek = (weekNum, schedule) => schedule.filter((it, idx) => idx >= weekNum * 7 && idx < weekNum * 7 + 7);

  return data.map((item) => {
    const { schedule = [] } = item;

    item.weeks = Array(schedule.length / 7)
      .fill(null)
      .map((_, i) => getWeek(i, schedule));

    return item;
  });
};
export const getDoctor = (state: any) => {
  const doctor = state[DOCTORS_STATE_KEY].doctor;
  if (isEmpty(doctor)) return {};
  const { name, employeeId, offices } = doctor;
  const schedule = offices?.[0].schedule;

  const result = {
    name,
    employeeId,
    offices: Object.values(offices ?? {}).map(({ office, officeName }: any) => ({ office, officeName })),
  };
  if (!isNil(schedule)) {
    const getWeek = (weekNum) =>
      schedule
        ?.filter((it, idx) => idx >= weekNum * 7 && idx < weekNum * 7 + 7)
        ?.map(({ date, dayOfWeek }, i) => ({
          date,
          dayOfWeek,
          offices: offices?.reduce(
            (acc, officeData) => ({
              ...acc,
              [officeData?.office]: {
                officeName: officeData?.officeName,
                averages: officeData?.averages,
                officeSchedule: officeData?.schedule?.[i + weekNum * 7]?.office,
                personalSchedule: officeData?.schedule?.[i + weekNum * 7]?.personal,
                assignedTemplate: officeData?.schedule?.[i + weekNum * 7]?.assignedTemplate,
                suggestedTemplate: officeData?.schedule?.[i + weekNum * 7]?.suggestedTemplate,
              },
            }),
            {}
          ),
        }));

    result.startDate = schedule[0].date;
    result.endDate = schedule[schedule.length - 1].date;
    result.weeks = Array(schedule.length / 7)
      .fill(null)
      .map((_, i) => getWeek(i));
  }

  return result;
};
export const getDoctorSpecial = (state: any) => {
  const doctor = state[DOCTORS_STATE_KEY].doctor;
  const selectedRow = state[DOCTORS_STATE_KEY].selectedRow;
  if (isEmpty(doctor)) return {};
  const { schedule } = doctor;
  const getWeek = (weekNum) => schedule?.filter((it, idx) => idx >= weekNum * 7 && idx < weekNum * 7 + 7);
  const week0 = getWeek(0);
  const week1 = getWeek(1);
  const week2 = getWeek(2);
  const week3 = getWeek(3);
  const week4 = getWeek(4);
  const week5 = getWeek(5);
  const doctorFromGrid = state[DOCTORS_STATE_KEY].doctorsWithSpecialRanges[selectedRow];

  return {
    /*...omit(doctor, 'schedule'),*/
    ...doctor,
    name: doctorFromGrid?.employeeName,
    office: doctorFromGrid?.office,
    officeName: doctorFromGrid?.officeName,
    employeeId: doctorFromGrid?.employeeId,
    reason: doctorFromGrid?.reason,
    weeks: [week0, week1, week2, week3, week4, week5],
  };
};
export const getTemplatesLoading = (state: any) => state[TEMPLATES_STATE_KEY].loading;
export const getFetchingActivitiesInProgress = (state: any) => state[TEMPLATES_STATE_KEY].fetchingActivitiesInProgress;
export const getActivities = (state: any) => state[TEMPLATES_STATE_KEY].activities;
export const getAssignInProgress = (state: any) => state[TEMPLATES_STATE_KEY].assignInProgress;
export const getTemplates = (state: any) => state[TEMPLATES_STATE_KEY];
export const getAllTemplates = (state: any) => state[TEMPLATES_STATE_KEY].allTemplates;
export const getErrorAssign = (state: any) => state[TEMPLATES_STATE_KEY].errorAssign;
export const getSuccessAssign = (state: any) => state[TEMPLATES_STATE_KEY].successAssign;
export const getDoctorAvailability = (state: IDoctorsState) => state[DOCTORS_STATE_KEY].availability;
export const getSelectedTemplates = (state: any) => state[TEMPLATES_STATE_KEY].selectedTemplates;
export const getSelectedTemplatesById = (state: any, employeeId) =>
  state[TEMPLATES_STATE_KEY].selectedTemplates[employeeId];
export const getSelectedTemplatesByIdSingleWeek = (state: any, employeeId, week, rangeValue) => {
  return filterOptionsByWeek(state[TEMPLATES_STATE_KEY].selectedTemplates[employeeId], week, rangeValue, false);
};
export const getSelectedTemplateByIdSingleDay = (state: any, id, date) => {
  return state[TEMPLATES_STATE_KEY].selectedTemplates?.[id]?.[date];
};

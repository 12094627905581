import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Dropdown, Menu} from 'antd';
import styles from './navbarMenu.module.scss';
import styled from 'styled-components';
import { lowerCase, replace } from 'lodash';

function NavbarMenu({routes}: any) {
  const [isVisible, setIsVisible] = useState(false);
  const [current, setCurrent] = useState('0');
  const menu = (
    <Menu onClick={(e) => {
      setIsVisible(false);
      setCurrent(e.key);
    }} selectedKeys={[current]} className={styles['menu']}>
      {routes.map((route: any, index: number) => (
        <Menu.Item key={index.toString()} className={styles['menu-item']}>
          <Link to={`/${replace(lowerCase(route.title), / /g, '-')}`} className={styles['link']}>
            {route.title}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <StyledDropdown
      overlay={menu}
      trigger={['click']}
      visible={isVisible}
      onVisibleChange={(visible) => setIsVisible(visible)}
      placement="bottomLeft"
    >
      <Button className={styles['btn-menu']} onClick={(e) => e.preventDefault()}>
        <span className={styles['icon-menu']} />
      </Button>
    </StyledDropdown>
  );
}

export default NavbarMenu;

const StyledDropdown = styled(Dropdown)`
  position: absolute;
  left: 0;
`;

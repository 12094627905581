import { Button, Form } from 'antd';
import styled, { css } from 'styled-components';

const btnColors = css`
  background-color: #40668c;
  border-color: #40668c;
  color: #fff;
`;

const StyledParamsForm = styled(Form)`
  &&& {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    background: #fff;
    padding: 5px 0;
    margin: 0;

    .ant-form-item {
      margin-right: 25px;

      > .ant-form-item-label > label {
        color: #000;
      }
    }
  }
`;

const StyledLunchConfigForm = styled(StyledParamsForm)`
  &&& {
    margin-top: 20px;
    max-width: 320px;
  }
`;

const StyledExamConfigForm = styled(StyledParamsForm)`
  max-width: 450px;
`;

const StyledFormItemName = styled(Form.Item)`
  width: 220px;
`;

const StyledFormItem = styled(Form.Item)`
  width: 120px;
`;

const StyledFormItemRangePicker = styled(Form.Item)`
  width: 220px;
`;

const StyledFormItemCheckbox = styled(Form.Item)`
  text-align: left;

  &&& {
    flex-direction: row-reverse;
    align-items: self-end;

    .ant-form-item-control-input {
      width: 30px;
    }

    .ant-form-item-label > label {
      font-size: 12px;
      width: 100px;
    }
  }
`;

const StyledFormItemSummary = styled(StyledFormItem)`
  &&&.ant-form-item {
    margin: 5px 0;

    .ant-form-item-label {
      border-bottom: 1px solid #757575;
    }

    .ant-form-item-control {
      text-align: right;
    }
  }
`;

const StyledConfCornerForm = styled.div`
  position: absolute;
  width: 120px;
  right: 45px;
  bottom: 35px;
  display: flex;
  flex-direction: column;
`;

const StyledPrimaryButton = styled(Button)`
  &&&:not([disabled]) {
    ${btnColors};
  }

  font-weight: bold;

  &:focus,
  &:hover {
    ${btnColors};
  }
`;

const StyledFormItemDownloadBtn = styled(Form.Item)`
  &&&.ant-form-item {
    width: 100%;
    margin: 200px 0 30px;
    background-color: #fff;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }

    .ant-btn.ant-btn-dashed {
      background-color: #fff;
      font-weight: normal;
      border-color: #d9d9d9;
      &:hover {
        border-color: #003366;
      }
    }
  }
`;

export {
  StyledParamsForm,
  StyledExamConfigForm,
  StyledLunchConfigForm,
  StyledFormItemName,
  StyledFormItem,
  StyledConfCornerForm,
  StyledPrimaryButton,
  StyledFormItemCheckbox,
  StyledFormItemSummary,
  StyledFormItemRangePicker,
  StyledFormItemDownloadBtn,
};

import styled from 'styled-components';
import { Button, Drawer, Select } from 'antd';

export const StyledGearButton = styled(Button)`
  .anticon {
    font-size: 16px;
  }
`;

export const StyledRampsDrawer = styled(Drawer)`
  .ant-drawer-body {
    margin-top: 75px;
    position: relative;
  }
`;

export const ReasonSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 75px;
  z-index: 1;
  padding: 0 20px;

  > * {
    margin: 0 10px;

    &:last-child {
      margin-left: 40px;
    }
  }
`;

export const WeekPanelHeader = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin: 0 10px;
  }
`;

export const StyledPatternSelect = styled(Select)`
  font-weight: bold;
  height: 28px;
  display: flex;
  align-items: center;
  position: relative;
  width: 200px;
`;

export const StyledDayTitle = styled.span`
  width: 120px;
  font-weight: bold;
`;

import { call, put, select } from 'redux-saga/effects';
import { api } from '@med-fe/util';
import { showErrorNotification, showSuccessNotification } from '@med-fe/util';
import {
  fetchPatternsFailed,
  fetchPatternsSuccess,
  fetchRampSettingsSuccess,
  updateRampSettingsFailed,
  updateRampSettingsSuccess,
} from './common-actions';
import { getRampSettings, getSelectedWeekDaysPatterns, getSelectOptions } from './common-selector';
import { ReasonRampSettingsDto } from '../../models/ramp-settings-dto';

export function* fetchRampSettingsStartWorker({ payload }: any): Generator {
  try {
    const url = `/special-ranges/settings?specialRangesReasons=${payload.specialRangesReasons}`;
    const data: any = yield call(api.get, url);
    yield put(fetchRampSettingsSuccess(data));
  } catch (e: any) {
    showErrorNotification(e);
  }
}

export function* updateRampSettingsStartWorker(): Generator {
  try {
    const weekDaysPatterns: any = yield select(getSelectedWeekDaysPatterns);
    const rampSettings: ReasonRampSettingsDto | any = yield select(getRampSettings);
    const url = `/special-ranges/settings?specialRangesReasons=${rampSettings.specialRangesReasons}`;
    const requestData = Object.keys(weekDaysPatterns).reduce((acc, weekNumber): any => {
      const rampScheduleDtos = Object.entries(weekDaysPatterns[weekNumber]).map(([dayOfWeek, patternName]) => ({
        dayOfWeek,
        patternName,
      }));

      return rampScheduleDtos.length ? [...acc, { rampScheduleDtos, weekNumber }] : acc;
    }, []);

    const data: any = yield call(api.post, url, requestData);
    showSuccessNotification(`Ramps settings were updated successfully`);
    yield put(updateRampSettingsSuccess(data));
  } catch (e: any) {
    yield put(updateRampSettingsFailed(e));
    showErrorNotification(e);
  }
}

export function* fetchPatternsStartWorker({ payload }: any): Generator {
  try {
    let url = '/special-ranges/templates';
    const { name = '', page = 0 } = payload;
    const nameParam = name ? `&name=${name.replace(/ $/g, '%20&')}` : '';

    url = `${url}?page=${page}${nameParam}`;

    const selectOptions: any = yield select(getSelectOptions);
    let data: any = yield call(api.get, url);

    if (!!page) {
      data = [...selectOptions, ...data];
    }

    yield put(fetchPatternsSuccess(data));
  } catch (e: any) {
    yield put(fetchPatternsFailed(e));
    showErrorNotification(e);
  }
}

import { get, last } from 'lodash';

export const EXCEPTION_MANAGEMENT_STATE_KEY = 'EXCEPTION_MANAGEMENT_REDUCER';

export const getSearch = (state) => state[EXCEPTION_MANAGEMENT_STATE_KEY].search;
export const getSorting = (state) => state[EXCEPTION_MANAGEMENT_STATE_KEY].sorting;
export const getImportLoading = (state: any) => state[EXCEPTION_MANAGEMENT_STATE_KEY].importLoading;
export const getImportValidationData = (state: any) => state[EXCEPTION_MANAGEMENT_STATE_KEY].importValidationData;
export const getTotalCount = (state: any) => state[EXCEPTION_MANAGEMENT_STATE_KEY].totalCount;
export const getOffices = (state: any) => state[EXCEPTION_MANAGEMENT_STATE_KEY].offices;
export const getEmployees = (state: any) => state[EXCEPTION_MANAGEMENT_STATE_KEY].employees;
export const getSuccessErrorReport = (state: any) => state[EXCEPTION_MANAGEMENT_STATE_KEY].successErrorReport;
export const getExceptionById = (state: any, exceptionId: string) =>
  state[EXCEPTION_MANAGEMENT_STATE_KEY].exception.contents.find((exception) => exception.exceptionId === exceptionId);

export const getException = (state) =>
  state[EXCEPTION_MANAGEMENT_STATE_KEY].exception.contents.map(({ exceptionId, exceptionName, ...rest }, idx) => ({
    key: `${exceptionId}-${idx}`,
    exceptionId: exceptionId,
    ...rest,
  }));

export const getTotal = (state) => state[EXCEPTION_MANAGEMENT_STATE_KEY].exception.total;
export const getLoading = (state) => state[EXCEPTION_MANAGEMENT_STATE_KEY].loading;
export const getDetailsLoading = (state) => state[EXCEPTION_MANAGEMENT_STATE_KEY].detailsLoading;
export const getRemoveLoading = (state) => state[EXCEPTION_MANAGEMENT_STATE_KEY].removeLoading;
export const getExceptionDetails = (state) => state[EXCEPTION_MANAGEMENT_STATE_KEY].exceptionDetails;

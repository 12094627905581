export const OD_ASSIGNMENTS_CONFIG = {
  officeName: {
    label: 'Office Name',
    allowClear: true,
    placeholder: 'Enter text',
  },
  officeNumber: {
    label: 'Office Number',
    allowClear: true,
    placeholder: 'Enter text',
  },
  odName: {
    label: 'OD Name',
    allowClear: true,
    placeholder: 'Enter text',
  },
  eeid: {
    label: 'Employee Id',
    allowClear: true,
    placeholder: 'Enter text',
  },
  dateRange: {
    label: 'Date Range Start and End',
    allowClear: true,
    type: 'daterange',
  },
};

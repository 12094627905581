export const getWeekAverage = ({ averages, hours }) => {
  const withoutEmpty = averages?.filter((it, idx) => hours[idx] !== 0);
  const sum = withoutEmpty?.reduce((acc, it) => acc + it, 0);
  const count = withoutEmpty?.length;
  const round10 = (val) => Math.round(val * 10) / 10;

  if (count === 0) return 0;

  return round10(sum / count);
}

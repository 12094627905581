import { Button, Checkbox, Drawer, Empty, Select, Table, Tooltip, DatePicker } from 'antd';
import styled from 'styled-components';

const StyledWeekWrapper = styled.div`
  background-color: ${({ single = true, isFirst }) => single && isFirst && '#EAEAEA'};
  padding: ${({ single = true }) => single && '0 15px'};
`;

const StyledCalendar = styled.img`
  height: 28px;
`;

const StyledAssignButton = styled(Button)`
  &.ant-btn-primary {
    font-weight: 700;
    background-color: #003366;
    border-color: #003366;
  }
`;

const StyledResetButton = styled(Button)`
  margin-right: 10px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 530px;
`;

const StyledWholeWeekCheckbox = styled(Checkbox)`
  margin-left: 3px;
`;

const StyledMonthSelect = styled(Select)`
  width: 170px;
  display: flex;

  .ant-select-selection-item {
    color: #003366;
    text-transform: uppercase;
    font-weight: 700;
  }

  &&& .ant-select-selector {
    border: none;
  }

  .ant-select-arrow {
    top: 8px;
    right: 0;
    align-items: baseline;
  }
`;

const stylesMonthSelect = {
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '14px',
};

// Week Grid

const StyledTable = styled(Table)`
  margin-top: 20px;
  filter: none;

  .ant-table-container .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #d5edf8;
  }

  &&& th.ant-table-cell,
  &&& td.ant-table-cell {
    font-size: 14px;
    padding: 0;
    text-align: left;

    &.bordered-left {
      border-left: 10px solid #eaeaea;
      border-left-color: ${({ isFirst }) => (isFirst ? '#EAEAEA' : '#FFF')};
    }
  }

  &&& th.ant-table-cell:nth-child(-n + 4),
  &&& td.ant-table-cell:nth-child(-n + 4) {
    padding-left: 4px;
  }

  &&& th.ant-table-cell {
    font-weight: bold;
    text-transform: capitalize;
    &:nth-child(-n + 4) {
      background-color: #dfdfdf;
    }
  }

  &&& td.ant-table-cell {
    height: unset;
    background-color: #fff;

    .grayed-out {
      color: #a9a9a9;
    }

    .plain-text {
      padding-left: 8px;
    }

    &.empty-column {
      background-color: transparent;
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-title,
  .subheader {
    color: #003366;
    width: 100%;
  }

  .subheader__text {
    font-weight: 800;
    font-size: 16px;
  }

  .ant-drawer-content-wrapper {
    .ant-drawer-header {
      .ant-drawer-header-title {
        flex-direction: row;

        .ant-drawer-title {
          font-size: 16px;
          text-transform: none;
        }
      }
    }

    .ant-drawer-body {
      ${({ $isSlotDrawer }) => ($isSlotDrawer ? '' : 'margin-top: 75px;')}

      .week__assign-btn-wrap,
      .subheader {
        border-bottom: none;
      }

      .subheader {
        position: fixed;
        right: 0;
        top: 50px;
        z-index: 1;
        padding: 20px 35px 20px 20px;
        margin: 0;
        background-color: #fff;

        &.bulk {
          width: 1050px;
        }
      }

      .subheader__office {
        margin-left: 0;
        color: #003366;
        font-weight: 600;
        font-size: 16px;
        width: 325px;
      }

      .week__pattern-wrapper {
        justify-content: flex-start;

        .pattern-bar {
          margin-right: 65px;
        }
      }
    }
  }
`;

const SelectWrap = styled.div`
  display: flex;
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
  width: 15px;
  padding-left: 1px;
  padding-right: 1px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
  height: 24px;

  & .ant-select-selection-item {
    ${({ $isBold }) => ($isBold ? 'font-weight: 900' : 'color: #808080')};
    ${({ $isSuggested }) => ($isSuggested ? 'font-weight: 400' : '')};
  }
`;

const StyledEmpty = styled(Empty)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledWarningIcon = styled.img`
  align-self: center;
  height: 14px;
  margin-right: 10px;
  filter: none;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  margin-right: 10px;
`;

// styles

const tooltipInnerStyle = {
  color: 'black',
  fontSize: 10,
  whiteSpace: 'pre-wrap',
  lineHeight: 1.2,
  padding: 2,
  display: 'flex',
  alignItems: 'center',
};

const tooltipStyle = {
  padding: '0 10 0 0',
};

export {
  StyledWeekWrapper,
  StyledCalendar,
  StyledAssignButton,
  StyledCheckbox,
  StyledMonthSelect,
  stylesMonthSelect,
  StyledTable,
  SelectWrap,
  StyledTooltip,
  StyledSelect,
  StyledResetButton,
  StyledWholeWeekCheckbox,
  StyledEmpty,
  StyledWarningIcon,
  tooltipInnerStyle,
  tooltipStyle,
  StyledRangePicker,
};

import { call, put, select } from 'redux-saga/effects';
import { api, showSuccessNotification } from '@med-fe/util';
import {
  fetchTemplateDetailsFailed,
  fetchTemplateDetailsSuccess,
  searchTemplatesFailed,
  searchTemplatesSuccess,
  removeTemplateFailed,
  removeTemplateSuccess,
  searchTemplates,
  renameTemplateSuccess,
  renameTemplateFailed,
  importFileSuccess,
  importFileFailed,
  cleanImportLayout,
  updateValidatedSuccess,
  updateValidatedFailed,
} from './templates-actions';
import { showErrorNotification } from '@med-fe/util';
import { getImportValidationData, getSearch } from './templates-selector';

export function* searchTemplatesStartWorker({ payload }): Generator {
  try {
    const url = `/templates/list?page=${payload.page}&size=${payload.size}`;
    const data = yield call(api.post, url, payload);
    yield put(searchTemplatesSuccess(data));
  } catch (e) {
    yield put(searchTemplatesFailed(e));
    showErrorNotification();
  }
}

export function* fetchTemplateDetailsStartWorker({ payload }): Generator {
  try {
    const data = yield call(api.get, `/templates/${payload.id}`);
    yield put(fetchTemplateDetailsSuccess(data));
  } catch (e) {
    yield put(fetchTemplateDetailsFailed(e));
    showErrorNotification();
  }
}

export function* removeTemplateStartWorker({ payload }): Generator {
  let data;
  try {
    const search: any = yield select(getSearch);
    data = yield call(api.delete, `/templates/delete`, payload.ids);
    yield put(removeTemplateSuccess(data));
    yield put(searchTemplates(search));

    if (payload.successCallback) {
      yield call(payload.successCallback);
    }

    showSuccessNotification('Template(s) successfully deleted');
  } catch (e: any) {
    yield put(removeTemplateFailed(e));
    showErrorNotification(e.reason || null);
  }
}

export function* renameTemplateNameStartWorker({ payload }): Generator {
  try {
    yield call(api.patch, `/templates/rename?id=${payload.id}&newName=${payload.newName}`);
    yield put(renameTemplateSuccess(payload.newName));
    showSuccessNotification('The template was successfully renamed');
  } catch (e) {
    yield put(renameTemplateFailed(e));
    showErrorNotification();
  }
}

export function* importBulkUpdateFileStartWorker({ payload, actionType }: any): Generator {
  try {
    let url;
    if (actionType === 'Delete Ranges') {
      url = '/templates/delete-ranges/upload';
    } else if (actionType === 'Template Maintenance') {
      url = '/templates/maintenance/upload';
    } else {
      url = '/doctors/assign-template/upload';
    }
    const formdata = new FormData();
    formdata.append('file', payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(importFileSuccess(data.uploadDto, data.totalCount));
  } catch (e: any) {
    yield put(importFileFailed(e.reason));
    showErrorNotification(e.reason, null, 5);
  }
}

export function* updateValidatedImportStartWorker({ payload, actionType, flag }: any): Generator {
  try {
    let url;
    if (actionType === 'Delete Ranges') {
      url = '/templates/delete-ranges/submit';
    } else if (actionType === 'Template Maintenance') {
      url = '/templates/maintenance/submit';
    } else {
      url = '/doctors/assign-template/submit?' + flag;
    }
    const data: any = yield call(api.post, url, payload);
    if (actionType === 'Assign Templates') {
      yield put(updateValidatedSuccess(data));
    } else {
      yield put(cleanImportLayout());
      showSuccessNotification(data, 3);
    }
  } catch (e: any) {
    yield put(updateValidatedFailed(e.reason));
    showErrorNotification(e.reason);
  }
}

import { EXPORT_REPORT, EXPORT_REPORT_FAILED, EXPORT_REPORT_SUCCESS, SET_SELECTED_REPORT } from './reports-actions';

export const REPORTS_MANAGEMENT_STATE_KEY = 'REPORTS_MANAGEMENT_STATE_KEY';

type ReportState = {
  loadingReportNames: string[];
  selectedReport: string | null;
};

const initialState: ReportState = {
  loadingReportNames: [],
  selectedReport: null,
};

export const reportsManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_REPORT:
      return {
        ...state,
        selectedReport: payload,
      };
    case EXPORT_REPORT: {
      return {
        ...state,
        loadingReportNames: [...state.loadingReportNames, payload.fileName],
      };
    }
    case EXPORT_REPORT_SUCCESS:
    case EXPORT_REPORT_FAILED: {
      return {
        ...state,
        loadingReportNames: state.loadingReportNames.filter((fileName) => fileName !== payload),
      };
    }
    default:
      return state;
  }
};

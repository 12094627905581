import React from 'react';
import { StatusesEnum } from '../../../models/statuses-enum';
import { TagRenderer } from '@med-fe/ui';

export const new_offices_columns = [
  {
    title: 'Office Number',
    dataIndex: 'officeNumber',
    key: 'officeNumber',
    width: 140,
  },
  {
    title: 'Office name',
    dataIndex: 'officeName',
    key: 'officeName',
    width: 200,
  },
  {
    title: 'Launch date',
    dataIndex: 'scheduleStartDate',
    key: 'scheduleStartDate',
    width: 100,
  },
  {
    title: 'Acuity Office Status',
    dataIndex: 'acuityOfficeStatus',
    key: 'acuityOfficeStatus',
    width: 140,
    render: (value) =>
      value && <TagRenderer label={StatusesEnum[value]} value={value} valueAsLabel={true} closable={false} onClose />,
  },
  {
    ellipsis: true,
  }, // empty column, workaround to have column width fixed
];

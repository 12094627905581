import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Empty, Row, Spin } from 'antd';
import { MainInfo } from './details/MainInfo';
import { Timetable } from './details/Timetable';
import { Configuration } from './details/Configuration';
import { ExamsPanel } from './details/ExamsPanel';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplateDetails, fetchTemplateDetailsSuccess } from '../templates-actions';
import { getDetailsLoading, getEditedExam } from '../templates-selector';
import styles from '../../grid/BaseGrid.module.scss';

export const TemplatesDetails = () => {
  const { templateId } = useParams<any>();
  const dispatch = useDispatch();
  const loading = useSelector(getDetailsLoading);
  const editedExam = useSelector(getEditedExam);

  useEffect(() => {
    if (templateId) {
      dispatch(fetchTemplateDetails({ id: templateId }));
    }
  }, [templateId]);

  useEffect(() => {
    return () => {
      dispatch(fetchTemplateDetailsSuccess(null));
    };
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <StyledLeftCol span={12}>
            <MainInfo />
            <ExamsPanel />
            {editedExam ? (
              <Configuration />
            ) : (
              <EmptyConfiguration description={<span>NO CONFIGURATION SELECTED</span>}></EmptyConfiguration>
            )}
          </StyledLeftCol>
          <StyledRightCol span={12}>
            <Timetable />
          </StyledRightCol>
        </Row>
      </Spin>
      <StyledLogoWrapper>
        <img alt='logo' className={styles['logo']} src={'./images/logo.svg'} />
      </StyledLogoWrapper>
    </>
  );
};

const StyledLeftCol = styled(Col)`
  min-width: 300px;
  padding: 10px 20px 10px;
`;

const StyledRightCol = styled(Col)`
  min-width: 150px;
  padding: 10px 20px 10px;
`;

const StyledLogoWrapper = styled.div`
  width: 100%;
  padding: 40px 40px 20px;
  display: flex;
  justify-content: flex-end;
`;

const EmptyConfiguration = styled(Empty)`
  margin-top: 100px;

  span {
    opacity: 0.6;
  }
`;

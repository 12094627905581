import React from 'react';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { getTemplateEventColor } from '../../../../common/helpers/common';

export const ExamBar = ({ title, count }) => {
  let color = getTemplateEventColor(title);

  return (
    <>
      <StyledExamBlock color={color}>
        <div>{title}</div>
        <StyledCount color={color}>{count}</StyledCount>
      </StyledExamBlock>
    </>
  );
};

const shredStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: ${({ color }) => color};
`;

const StyledExamBlock = styled.div`
  ${shredStyles};
  position: relative;
  height: 60px;
  width: 90px;
  border-radius: 2px;
  margin: 0 15px 10px 0;
`;

const StyledCount = styled.div`
  ${shredStyles};
  position: absolute;
  top: -10px;
  right: -10px;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import { omit } from 'lodash';
import { setSearchException } from '../../ExceptiopnManagement/actions';
import { ExceptionCategoryEnum } from 'apps/pace/src/models/exception-catergory-enum';
import { getInvalidDates } from '@med-fe/util';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

function SearchException() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const data = useLocation();
  const exceptionDetails: any = data.state;
  const [isArchivedOnly, setIsArchivedOnly] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (exceptionDetails) {
      const details = {
        employeeName: exceptionDetails?.employeeName,
        employeeID: exceptionDetails?.employeeID,
        officeNumber: exceptionDetails?.officeNumber,
        exceptionCategories: exceptionDetails?.exceptionCategory,
        showArchivedData: isArchivedOnly,
      };
      form.setFieldsValue(details);
      dispatch(setSearchException({ ...details, page: 0, size: 15 }));
      history.replace('/exception-management');
    }
  }, [exceptionDetails]);

  const onSubmit = (formData) => {
    const { effectiveDate, ...data } = formData;
    const searchDetails = {
      fromDate: effectiveDate && effectiveDate[0] ? effectiveDate[0].format('YYYY-MM-DD') : null,
      toDate: effectiveDate && effectiveDate[1] ? effectiveDate[1].format('YYYY-MM-DD') : null,
      exceptionCategories: data?.exceptionCategories ? data?.exceptionCategories : [],
      employeeName: data?.employeeName ? data?.employeeName : null,
      employeeID: data?.employeeID ? data?.employeeID : null,
      officeNumber: data?.officeNumber ? data?.officeNumber : null,
      showArchivedData: isArchivedOnly,
      page: 0,
      size: 15,
    };
    dispatch(setSearchException(searchDetails));
  };
  useEffect(() => {
    const searchDetails = {
      showArchivedData: isArchivedOnly,
      page: 0,
      size: 15,
    };
    dispatch(setSearchException(searchDetails));
  }, [isArchivedOnly]);
  return (
    <div className='search-container'>
      <Form
        form={form}
        layout='vertical'
        //className='search-container'
        onFinish={(formData) => {
          onSubmit(formData);
        }}
      >
        <Form.Item key='employeeName' label={'Employee Name'} name='employeeName' className='label-text input-field'>
          <Input allowClear />
        </Form.Item>
        <Form.Item key='employeeID' label={'Employee ID'} name='employeeID' className='label-text input-field'>
          <Input allowClear />
        </Form.Item>
        <Form.Item key='officeNumber' label={'Office Number'} name='officeNumber' className='label-text input-field'>
          <Input allowClear />
        </Form.Item>
        <Form.Item
          key='exceptionCategories'
          label={'Exception Category'}
          name='exceptionCategories'
          className='label-text input-field'
        >
          <Select allowClear showArrow mode={'multiple'} maxTagCount={'responsive'}>
            {Object.entries(ExceptionCategoryEnum).map((item, index) => {
              return (
                <Option key={index} value={item[0]}>
                  {item[1]}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item key='effectiveDate' name='effectiveDate' label='Select Effective Date Range' className='label-text'>
          <RangePicker
            allowEmpty={[true, true]}
            format={['YYYY-MM-DD']}
            separator='-'
            disabledDate={getInvalidDates}
            //disabled={!isDateTypeSelected}
            inputReadOnly
          />
        </Form.Item>
        <Form.Item className='search-btn'>
          <Button type='primary' htmlType='submit'>
            SEARCH
          </Button>
        </Form.Item>
      </Form>
      <div className='archived-switch'>
        <label className='label-text'>{'Show Archived'}</label>
        <Switch
          size='small'
          checked={isArchivedOnly}
          onChange={(flag) => {
            setIsArchivedOnly(flag);
          }}
        />
        {/* </Label> */}
      </div>
    </div>
  );
}

export default SearchException;

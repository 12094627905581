import {
  FETCH_NEW_OFFICES,
  FETCH_NEW_OFFICES_FAILED,
  FETCH_NEW_OFFICES_SUCCESS,
  SET_SEARCH_NEW_OFFICES,
} from './new-offices-actions';

export const NEW_OFFICES_STATE_KEY = 'NEW_OFFICES_STATE_KEY';

export interface IOfficesState {
  loading: boolean;
  offices: any[];
  error: any;
  search: ISearchNewOffices;
}

export interface ISearchNewOffices {
  size: number;
  page: number;
}

export const defaultSearchNewOffices: ISearchNewOffices = {
  size: 15,
  page: 0,
};
const defaultOfficesState: IOfficesState = {
  loading: false,
  offices: [],
  error: null,
  search: defaultSearchNewOffices,
};

export const newOfficesReducer = (state = defaultOfficesState, { type, payload }: any) => {
  switch (type) {
    case SET_SEARCH_NEW_OFFICES:
      return {
        ...state,
        search: { ...state.search, ...payload },
      };
    case FETCH_NEW_OFFICES:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_NEW_OFFICES_SUCCESS:
      return {
        ...state,
        loading: false,
        offices: payload.contents,
        total: payload.total,
        pages: payload.pages,
      };
    case FETCH_NEW_OFFICES_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

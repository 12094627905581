/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchEmployees, fetchOffices, searchException, setSearchException, setSortCriterias } from './actions';

import { getLoading, getSearch, getSorting, getTotal } from './selectors';

import BaseGrid from '../grid/BaseGrid';
import SearchException from '../header/search/SearchException';
import { omit } from 'lodash';

function ExceptionManagement() {
  const dispatch = useDispatch();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const search = useSelector(getSearch);
  const sorting = useSelector(getSorting);
  const total = useSelector(getTotal);
  const loading = useSelector(getLoading);

  useEffect(() => {
    dispatch(searchException({ ...search, sorting }));
  }, [search]);
  useEffect(() => {
    dispatch(fetchOffices());
    dispatch(fetchEmployees());
  }, []);

  const onPageChange = (pageData) => {
    dispatch(setSearchException({ ...search, ...omit(pageData, sorting) }));
    if (pageData.sorting) {
      dispatch(setSortCriterias(pageData.sorting));
    }
  };

  return (
    <>
      <SearchException />
      <BaseGrid
        id='exceptionManagement'
        total={total}
        isAllSelected={isAllSelected}
        toggleIsAllSelected={(val) => setIsAllSelected(val)}
        search={search}
        setSearch={onPageChange}
        loading={loading}
      />
    </>
  );
}

export default ExceptionManagement;

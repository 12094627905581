import { call, put, select } from 'redux-saga/effects';
import { api } from '@med-fe/util';
import {
  fetchDoctorsSuccess,
  fetchSelectedDoctorSuccess,
  fetchTemplatesSuccess,
  assignTemplateSuccess,
  assignTemplateFailed,
  fetchAllTemplatesSuccess,
  fetchAllTemplatesFailed,
  fetchDoctorsSpecialSuccess,
  fetchDoctorsSpecialFailed,
  fetchOdDataIncompleteSuccess,
  fetchOdDataIncompleteFailed,
  fetchSelectedDoctorSpecialSuccess,
  assignTemplateSpecialFailed,
  assignTemplateSpecialSuccess,
  fetchDoctorAvailabilityFailed,
  fetchDoctorAvailabilitySuccess,
  fetchRegionsSuccess,
  fetchDistrictsSuccess,
  bulkAssignTemplateSpecialSuccess,
  bulkAssignTemplateSpecialFailed,
  bulkAssignTemplateSuccess,
  bulkAssignTemplateFailed,
  fetchSelectedDoctorsSpecialFailed,
  fetchSelectedDoctorsSpecialSuccess,
  fetchSelectedDoctorsSpecialAllSuccess,
  fetchSelectedDoctorsSpecialAllFailed,
  bulkAssignActivityFailed,
  bulkAssignActivitySuccess,
  fetchActivitiesSuccess,
} from './pace-actions';
import { getTemplates } from './pace-selectors';
import { showErrorNotification, showSuccessNotification } from '@med-fe/util';

export function* fetchDoctorsStartWorker({ payload }): Generator {
  try {
    const url = '/doctors/unmapped';
    const data = yield call(api.post, url, { ...payload });
    yield put(fetchDoctorsSuccess(data));
  } catch (e) {
    console.log(e);
  }
}
export function* fetchDoctorsSpecialStartWorker({ payload }): Generator {
  try {
    const url = `/special-ranges/${payload.path ? payload.path : 'unmapped'}?page=${payload.page}&size=${payload.size}`;
    const data = yield call(api.post, url, { ...payload });
    yield put(fetchDoctorsSpecialSuccess(data));
  } catch (e) {
    fetchDoctorsSpecialFailed(e);
    console.log(e);
  }
}

export function* fetchODDataIncompleteStartWorker({ payload }): Generator {
  try {
    const url = `/doctors/incomplete?page=${payload.page}&size=${payload.size}`;
    const data = yield call(api.post, url, { ...payload });
    yield put(fetchOdDataIncompleteSuccess(data));
  } catch (e) {
    fetchOdDataIncompleteFailed(e);
    console.log(e);
  }
}

export function* fetchSelectedDoctorStartWorker({ payload }): Generator {
  try {
    let url = '/doctors/unmapped';
    if (payload) {
      url = `${url}/${payload?.id}?office=${payload.office}&base=${payload.base}&startDate=${payload.startDate}`;
    }
    const data = yield call(api.get, url);
    yield put(fetchSelectedDoctorSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchSelectedDoctorSpecialStartWorker({ payload }): Generator {
  try {
    let url = '/special-ranges/od_schedule';
    if (payload) {
      url = `${url}/${payload?.id}?office=${payload.office}&base=${payload.base}&startDate=${payload.startDate}`;
      if (payload.reason) {
        url += `&specialRangesReasons=${payload.reason}`;
      }
    }
    const data = yield call(api.get, url);
    yield put(fetchSelectedDoctorSpecialSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchTemplatesStartWorker({ payload }): Generator {
  try {
    let url = '/doctors/templates';
    const { dayOfWeek, office, name = '', page = 0 } = payload;
    const nameParam = name ? `&name=${name.replace(/ $/g, '%20&')}` : '';

    url = `${url}?dayOfWeek=${dayOfWeek}&office=${office}${nameParam}&page=${page}`;

    const templatesData: any = yield select(getTemplates);
    let data: any = yield call(api.get, url);

    if (templatesData.hasOwnProperty(dayOfWeek) && !!page) {
      data = [...templatesData[dayOfWeek], ...data];
    }

    yield put(fetchTemplatesSuccess({ data, day: payload }));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchAllTemplatesStartWorker(): Generator {
  try {
    let url = '/doctors/templates/all';
    let data: any = yield call(api.get, url);
    yield put(fetchAllTemplatesSuccess(data));
  } catch (e) {
    yield put(fetchAllTemplatesFailed(e));
    showErrorNotification();
  }
}

export function* assignTemplateStartWorker({ payload }): Generator {
  try {
    const url = '/doctors/templates/assign';
    const data = yield call(api.post, url, payload);
    yield put(assignTemplateSuccess(data));
    showSuccessNotification('Template(s) successfully assigned.');
  } catch (e) {
    yield put(assignTemplateFailed(e));
    showErrorNotification();
  }
}

export function* bulkAssignTemplateStartWorker({ payload }): Generator {
  try {
    const url = '/doctors/templates/bulk/assign';
    const data = yield call(api.post, url, payload);
    yield put(bulkAssignTemplateSuccess(data));
    showSuccessNotification('Templates successfully assigned.');
  } catch (e) {
    yield put(bulkAssignTemplateFailed(e));
    showErrorNotification();
  }
}

export function* assignTemplateSpecialStartWorker({ payload }): Generator {
  try {
    const url = '/special-ranges/templates/assign';
    const data = yield call(api.post, url, payload);
    yield put(assignTemplateSpecialSuccess(data));
    showSuccessNotification('Template(s) successfully assigned.');
  } catch (e) {
    yield put(assignTemplateSpecialFailed(e));
    showErrorNotification();
  }
}

export function* bulkAssignTemplateSpecialWorker({ payload: { payload, currentMonthDate } }): Generator {
  try {
    const url = `/special-ranges/templates/bulk/assign?currentMonthDate=${currentMonthDate}`;
    yield call(api.post, url, payload);
    yield put(bulkAssignTemplateSpecialSuccess());
    showSuccessNotification('Template successfully assigned.');
  } catch (e) {
    yield put(bulkAssignTemplateSpecialFailed(e));
    showErrorNotification();
  }
}

export function* fetchActivitiesStartWorker(): Generator {
  try {
    const url = '/special-ranges/activities';
    const data = yield call(api.get, url);
    yield put(fetchActivitiesSuccess(data));
  } catch (e) {
    showErrorNotification();
  }
}

export function* bulkAssignActivityStartWorker({ payload }): Generator {
  try {
    const url = '/special-ranges/bulk/activity';
    yield call(api.patch, url, payload);
    yield put(bulkAssignActivitySuccess());
    showSuccessNotification('Slots successfully assigned');
  } catch (e) {
    yield put(bulkAssignActivityFailed(e));
    showErrorNotification();
  }
}

export function* fetchDoctorAvailabilityStartWorker({ payload: { employeeId, payload } }): Generator {
  try {
    const url = `/doctors/availability/${employeeId}`;
    const data = yield call(api.post, url, payload);
    yield put(fetchDoctorAvailabilitySuccess({ employeeId, office: payload.office, data }));
  } catch (e) {
    yield put(fetchDoctorAvailabilityFailed(e));
  }
}

export function* fetchRegionsStartWorker(): Generator {
  try {
    const url = '/special-ranges/regions';
    const data = yield call(api.get, url);
    yield put(fetchRegionsSuccess(data));
  } catch (e) {
    showErrorNotification();
  }
}

export function* fetchDistrictsStartWorker({ payload }: any): Generator {
  try {
    let url = '/special-ranges/districts';
    if (payload && payload.length) {
      url = `${url}/search?regionIds=${payload.join(',')}`;
    }
    const data = yield call(api.get, url);
    yield put(fetchDistrictsSuccess(data));
  } catch (e) {
    showErrorNotification();
  }
}

export function* fetchSelectedDoctorsSpecialStartWorker({
  payload: { startDate, endDate, showSuggestedTemplates, selectedItems },
}: any): Generator {
  try {
    let url = `/special-ranges/bulk/data?startDate=${startDate}&endDate=${endDate}&showSuggestedTemplates=${showSuggestedTemplates}`;
    const data = yield call(api.post, url, selectedItems);
    yield put(fetchSelectedDoctorsSpecialSuccess(data));
  } catch (e) {
    showErrorNotification();
    yield put(fetchSelectedDoctorsSpecialFailed(e));
  }
}

export function* fetchSelectedDoctorsSpecialAllStartWorker({
  payload: { startDate, endDate, showSuggestedTemplates, ...rest },
}: any): Generator {
  try {
    let url = `/special-ranges/bulk/data/all?startDate=${startDate}&endDate=${endDate}&showSuggestedTemplates=${showSuggestedTemplates}`;
    const data = yield call(api.post, url, rest);
    yield put(fetchSelectedDoctorsSpecialAllSuccess(data));
  } catch (e) {
    showErrorNotification();
    yield put(fetchSelectedDoctorsSpecialAllFailed(e));
  }
}

export function* fetchDoctorsHooChangedStartWorker({ payload }): Generator {
  try {
    const url = '/doctors/hoo_changed';
    const data = yield call(api.post, url, { ...payload });
    yield put(fetchDoctorsSuccess(data));
  } catch (e) {
    showErrorNotification();
  }
}

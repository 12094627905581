import React from 'react';
import { useDispatch } from 'react-redux';
import { EventPopoverContent } from '../../../../../common/styled';
import { Button, Popover } from 'antd';
import { setEditedExam } from '../../../../templates-actions';

export const CalendarEventWrapper = (eventWrapperProps) => {
  const dispatch = useDispatch();
  const { children, event, label } = eventWrapperProps;
  const content = (
    <EventPopoverContent>
      <div>
        <div>{event.title}</div>
        <div>{label}</div>
      </div>
      <Button type={'link'} onClick={() => dispatch(setEditedExam(event?.item || null))}>
        <img src={'./icons/edit-dark.svg'} alt='edit' />
      </Button>
    </EventPopoverContent>
  );

  return (
    <Popover content={content} trigger={'click'}>
      {React.cloneElement(React.Children.only(children), {
        ...children.props,
        className: 'rbc-event custom-event',
        key: event.id,
      })}
    </Popover>
  );
};

import React from 'react';
import { Dropdown, Menu } from 'antd';

export const ActionCell = ({ config }: any) => {
  const imageStyles = {
    marginRight: '10px',
    width: '14px',
  };
  const textStyles = { fontSize: '12px' };
  const menu = (
    <Menu>
      {config.map((menuItem: any) => (
        <Menu.Item key={menuItem.title}>
          <a>
            <img src={`./icons/${menuItem.title}.svg`} alt={menuItem.title} style={imageStyles} />
            <span style={textStyles}>{menuItem.title}</span>
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
        <img src={'./icons/action.svg'} />
      </a>
    </Dropdown>
  );
};

import { useState } from 'react';

const useSelectVirtualScroll = ({ selectLoadingId, fetchDataRequest, clearSelectOptions }: any) => {
  const defaultPayload = { page: 0, size: 15, name: null };

  const [searchPayload, setSearchPayload] = useState(defaultPayload);

  const onTemplatesScroll = (event, selectId) => {
    const target = event.target || event.currentTarget;
    const isLoading = !!selectLoadingId && selectLoadingId === selectId;

    if (
      Math.round(target.scrollTop) + Math.round(target.offsetHeight) >= Math.round(target.scrollHeight) &&
      !isLoading
    ) {
      setSearchPayload({ ...searchPayload, page: searchPayload.page + 1 });
      fetchDataRequest({ page: searchPayload.page + 1, name: searchPayload.name, selectLoadingId: selectId });
    }
  };

  const onSelectSearch = (name, selectId) => {
    if (name && name.length >= 3) {
      setSearchPayload({ ...searchPayload, name, page: 0 });
      fetchDataRequest({ ...searchPayload, name, page: 0, selectLoadingId: selectId });
    }

    if (!name) {
      setSearchPayload(defaultPayload);
      fetchDataRequest(defaultPayload);
    }
  };

  const onSelectBlur = () => {
    setSearchPayload(defaultPayload);
    clearSelectOptions && clearSelectOptions();
  };

  const onSelectFocus = (selectId) => {
    fetchDataRequest({ ...searchPayload, selectLoadingId: selectId });
  };

  return { onTemplatesScroll, onSelectSearch, onSelectBlur, onSelectFocus };
};

export default useSelectVirtualScroll;

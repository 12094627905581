export const templateRangesColumns = [
  {
    title: 'Ranges',
    dataIndex: 'rangeNo',
    key: 'rangeNo',
    align: 'center',
    sorter: (a, b) => a.rangeNo - b.rangeNo,
    sortDirections: ['ascend', 'descend'],
  },
];

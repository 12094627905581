import {
  SelectWrap,
  StyledCheckbox,
  StyledSelect,
  StyledTooltip,
  StyledWholeWeekCheckbox,
  tooltipInnerStyle,
  tooltipStyle,
} from '../styled/styled';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getDayIndex,
  getDontHaveTemplateForParticularHOO,
  getIsFitSchedule,
  getIsSameMonth,
  getIsSameOrLaterThanToday,
} from '../../../../common/helpers/date';
import { weekGridSingleOfficeColumns } from '../../grid-configs/week-grid-single-office-columns';
import { get, isEmpty, isNil } from 'lodash';
import { Button, Checkbox, Dropdown, Menu, Select, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedDoctorSpecial,
  getSelectedTemplatesById,
  getSelectedTemplatesByIdSingleWeek,
  getTemplates,
  getTemplatesLoading,
} from '../../../../pace/pace-selectors';
import useTemplateScroll from '../../hooks/use-template-scroll';
import {
  clearAllDoctorsTemplates,
  clearDoctorWeekTemplates,
  fetchTemplates,
  setSelectedTemplate,
  setSelectedTemplates,
} from '../../../../pace/pace-actions';
import styles from '../../BaseGrid.module.scss';
import DescriptionColumn from './DescriptionColumn';

interface IScheduleDay {
  category: string;
  date: string;
  dayOfWeek: string;
  office: { start: string | null; end: string | null; duration: number | null };
  personal: { start: string | null; end: string | null; duration: number | null };
  specialRangeDescription: string | null;
  specialRangeTemplateId: string | null;
  specialRangeTemplateName: string | null;
  suggestedTemplate: { templateName: string; cantFindTemplate: boolean; removedNL: boolean; id: number };
  templateHours: { start: string | null; end: string | null; duration: number | null };
  templateId: number;
  templateName: string;
}

// the component was created to display the grid for single week with single office
const WeekSingleOfficeGrid = ({ office, employeeId, week, weekIdx, isCellDisabled, rangeValue }) => {
  const dispatch = useDispatch();
  const templates = useSelector(getTemplates);
  const templatesLoading = useSelector(getTemplatesLoading);
  const id = `${employeeId}_${office}`;
  const doctor = useSelector((state) => getSelectedTemplatesById(state, id));
  const memoizedSelectedTemplatesSelector = useCallback(
    (state) => getSelectedTemplatesByIdSingleWeek(state, id, week, rangeValue),
    [id, week, rangeValue]
  );
  const selectedTemplates = useSelector(memoizedSelectedTemplatesSelector);
  const data = useSelector((state) => getSelectedDoctorSpecial(state, employeeId, office));

  const [dayOfWeek, setDayOfWeek]: any = useState(null);
  const [selectedDate, setSelectedDate]: any = useState(null);
  const [isWholeWeek, setIsWholeWeek] = useState(false);
  const [isODWholeWeek, setIsODWholeWeek] = useState(false);
  const [isAppliedToAllWeeks, setIsAppliedToAllWeeks] = useState(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);

  useEffect(() => {
    // applies to three checkboxes: 'Same template to whole week', 'Apply to all weeks' , 'Apply template by OD Schedule'
    setIsCheckboxDisabled(
      isEmpty(selectedTemplates) ||
        Object.values(selectedTemplates).every((template) => isEmpty(template)) ||
        !Object.values(selectedTemplates)
          .filter((e) => !isEmpty(e))
          .every((elem: any, i, arr: any) => {
            const children = get(arr, '[0].children', '');
            const templateName = children ? get(children.split(' '), '[1]') : '';

            if (!templateName) return false;

            return elem.children?.split(' ')[1] === templateName;
          })
    );
  }, [selectedTemplates]);

  useEffect(() => {
    if (isCheckboxDisabled) {
      setIsWholeWeek(false);
      setIsODWholeWeek(false);
      setIsAppliedToAllWeeks(false);
    }
  }, [isCheckboxDisabled]);

  const { onTemplatesScroll, onSelectSearch, onSelectBlur } = useTemplateScroll({
    templatesLoading,
    office,
    dayOfWeek,
    setDayOfWeek,
  });
  const onSelectFocus = (date, day) => {
    setSelectedDate(date);
    setDayOfWeek(day);
  };

  const range0TextOverlay = (text, date) => {
    return (
      <Menu selectable={false}>
        <Menu.Item key={date} className={styles['description-input-menu']}>
          {text}
        </Menu.Item>
      </Menu>
    );
  };

  const range0Column = (text, date, time) => {
    return (
      <Dropdown overlay={range0TextOverlay(text, date)} placement='bottomLeft'>
        <div
          className={isEmpty(time) || isCellDisabled(date) ? 'grayed-out range0-template-text' : 'range0-template-text'}
        >
          {text}
        </div>
      </Dropdown>
    );
  };

  const onSelectTemplate = (_, option, date) => {
    if (isNil(option)) {
      if (isAppliedToAllWeeks) {
        dispatch(clearAllDoctorsTemplates());
      } else if (isWholeWeek || isODWholeWeek) {
        dispatch(clearDoctorWeekTemplates({ employeeId, office, dates: week.map(({ date }) => date) }));
      } else if (!isWholeWeek || !isODWholeWeek) {
        dispatch(setSelectedTemplate({ employeeId, office, date, option, isWholeWeek }));
      }
      setIsWholeWeek(false);
      setIsODWholeWeek(false);
      setIsAppliedToAllWeeks(false);
    } else {
      dispatch(setSelectedTemplate({ employeeId, office, date, option, isWholeWeek }));
    }
  };

  const columns = weekGridSingleOfficeColumns(isCellDisabled).map((col: any) => {
    if (col.key === 'template') {
      col.render = (text, { day, date, ooh, time }) => {
        const fitSchedule = () => {
          Object.entries(selectedTemplates).forEach(([key, value]: any) => {
            if (isNil(value?.schedule) && value?.children) {
              value.schedule = {
                start: value.children.split('-')[0] + ':00 AM',
                end: value.children.split('-')[1].split(' ')[0] + ':00 PM',
              };
            }
          });
          return getIsFitSchedule({
            template: selectedTemplates?.[date]?.schedule,
            office: ooh,
          });
        };

        const dontHaveTemplateForParticularHOO = () => {
          return getDontHaveTemplateForParticularHOO({
            template: selectedTemplates?.[date],
            office: isWholeWeek ? ooh : time,
          });
        };

        const computeTitle = () => {
          if (
            (selectedTemplates?.[date]?.cantFindTemplate && !isWholeWeek && !isODWholeWeek) ||
            ((isWholeWeek || isODWholeWeek) && dontHaveTemplateForParticularHOO().needShowWarning)
          ) {
            return 'Template for\n this HOO not found';
          } else if (selectedTemplates?.[date]?.removedNL && !isWholeWeek && !isODWholeWeek) {
            return 'NL was suggested to remove';
          } else if (fitSchedule().needShowWarning) {
            return 'Template outside\n of HOO';
          }
        };

        return (
          <SelectWrap>
            {!isCellDisabled(date) &&
            (fitSchedule().needShowWarning ||
              ((isWholeWeek || isODWholeWeek) && dontHaveTemplateForParticularHOO().needShowWarning) ||
              doctor?.[date]?.cantFindTemplate ||
              doctor?.[date]?.removedNL) ? (
              <StyledTooltip
                title={computeTitle()}
                color='white'
                overlayInnerStyle={tooltipInnerStyle}
                overlayStyle={tooltipStyle}
                placement='left'
                autoAdjustOverflow={false}
              >
                <img alt='info' src={'./icons/info-gray.svg'} />
              </StyledTooltip>
            ) : (
              <div style={{ width: 15 }} />
            )}
            <StyledSelect
              showSearch
              allowClear={getDayIndex(day) === dayOfWeek}
              placeholder='Select a template'
              size='small'
              onFocus={() => onSelectFocus(date, getDayIndex(day))}
              onBlur={() => onSelectBlur()}
              loading={!!templatesLoading && templatesLoading.dayOfWeek === getDayIndex(day) && selectedDate === date}
              onChange={(value, option) => onSelectTemplate(value, option, date)}
              onSearch={onSelectSearch}
              onPopupScroll={(event) => onTemplatesScroll(event, day)}
              filterOption={() => true}
              value={!isEmpty(doctor) ? doctor?.[date]?.children : undefined}
              $isBold={!isEmpty(doctor) ? !doctor?.[date]?.suggested : undefined}
              disabled={isCellDisabled(date)}
            >
              {templates[getDayIndex(day)] &&
                templates[getDayIndex(day)].map((it) => (
                  <Select.Option value={it.id} key={it.id} schedule={{ start: it?.start, end: it?.end }}>
                    {it.templateName}
                  </Select.Option>
                ))}
            </StyledSelect>
          </SelectWrap>
        );
      };
    }

    if (col.key === 'templateName') {
      col.render = (text, { time, date }) => {
        return range0Column(text, date, time);
      };
    }

    if (col.key === 'templateDescription') {
      col.render = (text, data) => {
        return <DescriptionColumn id={id} data={data} isWholeWeek={isWholeWeek} />;
      };
    }

    return col;
  });

  const dataSource = week.map((it) => ({
    key: `${it?.dayOfWeek}`,
    template: 'select',
    day: it?.dayOfWeek,
    date: it?.date,
    time: { start: it?.personal?.start, end: it?.personal?.end },
    ooh: { start: it?.office?.start, end: it?.office?.end },
    templateName: it?.templateName,
  }));

  useEffect(() => {
    if (!isNil(dayOfWeek)) {
      dispatch(fetchTemplates({ dayOfWeek, office }));
    }
  }, [dayOfWeek]);

  useEffect(() => {
    setIsWholeWeek(false);
    setIsODWholeWeek(false);
    setIsAppliedToAllWeeks(false);
  }, [rangeValue]);

  const allWeakWithoutHOO = () => week.every((day) => !day.office);

  const roundCoefficient = (minutes) => {
    return minutes?.split(' ')[0] > 15 ? 1 : 0;
  };
  const getTemplateNameByOptions = (idx, day, odScheduleSelected) => {
    const { office, description, personal } = day;
    let templateName = Object.values(selectedTemplates as object).find((option) => !isEmpty(option))?.children;
    let templateEnd = templateName?.substring(templateName?.indexOf(' ') + 1);

    if ((office && !odScheduleSelected) || (personal && odScheduleSelected)) {
      let start = odScheduleSelected ? personal?.start?.split(':')[0] : office?.start.split(':')[0];
      let end = odScheduleSelected
        ? parseInt(personal?.end?.split(':')[0]) + roundCoefficient(personal?.end?.split(':')[1])
        : parseInt(office?.end.split(':')[0]) + roundCoefficient(office?.end.split(':')[1]);
      let templateComputed = start + '-' + end + ' ' + templateEnd;
      let foundedTemplate = templates?.allTemplates?.find((template) => template?.templateName === templateComputed);
      return foundedTemplate
        ? {
            children: foundedTemplate.templateName,
            key: foundedTemplate.id,
            value: foundedTemplate.id,
            description: description || foundedTemplate.templateName,
          }
        : {};
    }
    return {};
  };

  const onOfficeCheckboxChange = (checked, applyToAllWeeks) => {
    setIsWholeWeek(checked);
    setIsODWholeWeek(false);
    onCheckboxChange(checked, applyToAllWeeks, false);
  };

  const onODCheckboxChange = (checked, applyToAllWeeks) => {
    setIsODWholeWeek(checked);
    setIsWholeWeek(false);
    onCheckboxChange(checked, applyToAllWeeks, true);
  };
  const onCheckboxChange = (checked, applyToAllWeeks, odScheduleSelected) => {
    setIsAppliedToAllWeeks(applyToAllWeeks);

    if (checked) {
      if (!isEmpty(selectedTemplates)) {
        const defaultData = Object.values(!isNil(selectedTemplates) ? selectedTemplates : {})[0];
        const isAllWeekWithoutOOH = allWeakWithoutHOO();
        // when applyToAllWeeks is true then apply changes to the schedule
        // else apply changes to the current week only
        const templates = Object.values((applyToAllWeeks ? data.schedule : week) as IScheduleDay).reduce(
          (acc, day, i) => {
            const { date } = day;
            return {
              ...acc,
              // apply changes only to the dates that are today, later than today, the same month as selected in month picker
              ...(getIsSameOrLaterThanToday(date) &&
                getIsSameMonth(rangeValue?.value, date) && {
                  [date]: isAllWeekWithoutOOH
                    ? defaultData
                    : !isEmpty(selectedTemplates)
                    ? getTemplateNameByOptions(i, day, odScheduleSelected)
                    : {},
                }),
            };
          },
          {}
        );

        dispatch(setSelectedTemplates({ id, templates }));
      }
    }
  };

  const onClear = () => {
    setIsWholeWeek(false);
    setIsAppliedToAllWeeks(false);
    setIsODWholeWeek(false);

    dispatch(
      clearDoctorWeekTemplates({
        employeeId,
        office,
        dates: week.map(({ date }) => date),
      })
    );
  };

  return (
    <>
      <Table scroll={{ x: 450 }} className='week-grid' dataSource={dataSource} columns={columns} pagination={false} />

      <div className='week__assign-btn-wrap'>
        <StyledWholeWeekCheckbox
          className='week__assign-checkbox'
          onChange={(e) => onOfficeCheckboxChange(e.target.checked, isAppliedToAllWeeks)}
          checked={isWholeWeek}
          disabled={isCheckboxDisabled}
        >
          Same template to whole week
        </StyledWholeWeekCheckbox>
        <Checkbox
          className='week__assign-checkbox'
          onChange={(e) => onODCheckboxChange(e.target.checked, isAppliedToAllWeeks)}
          checked={isODWholeWeek}
          disabled={isCheckboxDisabled}
        >
          Apply template by OD Schedule
        </Checkbox>
        {weekIdx === 0 && (
          <StyledCheckbox
            className='week__assign-checkbox'
            onChange={(e) => onCheckboxChange(e.target.checked, !isAppliedToAllWeeks, isODWholeWeek)}
            checked={isAppliedToAllWeeks}
            disabled={isCheckboxDisabled}
          >
            Apply to all weeks
          </StyledCheckbox>
        )}
        <Button type={'ghost'} onClick={onClear}>
          Clear
        </Button>
      </div>
    </>
  );
};

export default WeekSingleOfficeGrid;

export const FETCH_RAMP_SETTINGS = `FETCH_RAMP_SETTINGS`;
export const FETCH_RAMP_SETTINGS_SUCCESS = `FETCH_RAMP_SETTINGS_SUCCESS`;

export const UPDATE_RAMP_SETTINGS = `UPDATE_RAMP_SETTINGS`;
export const UPDATE_RAMP_SETTINGS_SUCCESS = `UPDATE_RAMP_SETTINGS_SUCCESS`;
export const UPDATE_RAMP_SETTINGS_FAILED = `UPDATE_RAMP_SETTINGS_FAILED`;

export const SET_SELECTED_WEEK_DAYS_PATTERNS = `SET_SELECTED_WEEK_DAYS_PATTERNS`;

export const FETCH_PATTERNS = `FETCH_PATTERNS`;
export const FETCH_PATTERNS_SUCCESS = `FETCH_PATTERNS_SUCCESS`;
export const FETCH_PATTERNS_FAILED = `FETCH_PATTERNS_FAILED`;

export const CLEAR_SELECT_OPTIONS = `CLEAR_SELECT_OPTIONS`;

export const fetchRampsSettings = (payload: any) => ({
  type: FETCH_RAMP_SETTINGS,
  payload,
});

export const fetchRampSettingsSuccess = (payload: any) => ({
  type: FETCH_RAMP_SETTINGS_SUCCESS,
  payload,
});

export const updateRampsSettings = () => ({
  type: UPDATE_RAMP_SETTINGS,
});

export const updateRampSettingsSuccess = (payload: any) => ({
  type: UPDATE_RAMP_SETTINGS_SUCCESS,
  payload,
});

export const updateRampSettingsFailed = (payload: any) => ({
  type: UPDATE_RAMP_SETTINGS_FAILED,
  payload,
});

export const setSelectedWeekDaysPatterns = (payload: any) => ({
  type: SET_SELECTED_WEEK_DAYS_PATTERNS,
  payload,
});

export const fetchPatterns = (payload: any) => ({
  type: FETCH_PATTERNS,
  payload,
});

export const fetchPatternsSuccess = (payload: any) => ({
  type: FETCH_PATTERNS_SUCCESS,
  payload,
});

export const fetchPatternsFailed = (payload: any) => ({
  type: FETCH_PATTERNS_FAILED,
  payload,
});

export const clearSelectOptions = () => ({
  type: CLEAR_SELECT_OPTIONS,
});

import React from 'react';

export const paceTabsConfig = (locId) => ({
  unmappedOds: {
    title: 'W2W OD’s Pattern Management',
    ...(locId && {
      details: {},
    }),
    disabled: false,
    readRoles: ['mod_read'],
  },
  odDataIncomplete: {
    title: 'od data incomplete',
    ...(locId && {
      details: {},
    }),
    disabled: false,
    readRoles: ['mod_read'],
  },
  newBaseTemplate: {
    title: 'new base template',
    ...(locId && {
      details: {},
    }),
    disabled: true,
    readRoles: ['mod_read'],
  },
  hoursOfOperation: {
    title: 'hours of operation',
    ...(locId && {
      details: {},
    }),
    disabled: true,
    readRoles: ['mod_read', 'mod_marketing'],
  },
  newOffices: {
    title: 'new offices',
    ...(locId && {
      details: {},
    }),
    readRoles: ['mod_read'],
  },
  specialRanges: {
    title: 'Special Ranges Management',
    ...(locId && {
      details: {},
    }),
    disabled: false,
    readRoles: ['mod_read'],
  },
});

import { useState } from 'react';

const useToggleDrawerVisibility = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const openDrawer = () => setIsDrawerVisible(true);
  const closeDrawer = () => setIsDrawerVisible(false);

  return { isDrawerVisible, openDrawer, closeDrawer };
};

export default useToggleDrawerVisibility;

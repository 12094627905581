export const AVERAGE_PACE_BY_OD_CONFIG = {
  templateName: {
    label: 'Template Name',
    allowClear: true,
    placeholder: 'Enter text',
  },
  officeNumber: {
    label: 'Office Number',
    allowClear: true,
    placeholder: 'Enter text',
  },
  officeName: {
    label: 'Office Name',
    allowClear: true,
    placeholder: 'Enter text',
  },
  odName: {
    label: 'OD Name',
    allowClear: true,
    placeholder: 'Enter text',
  },
  eeid: {
    label: 'Employee ID',
    allowClear: true,
    placeholder: 'Enter text',
  },
};

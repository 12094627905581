export const assignTemplatesColumns = [
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    align: 'center',
    width: 100,
  },
  {
    title: 'Resource',
    dataIndex: 'resource',
    key: 'resource',
    align: 'center',
    width: 100,
  },
  {
    title: 'Sunday',
    dataIndex: 'sunday',
    key: 'sunday',
    align: 'center',
    width: 120,
  },
  {
    title: 'Monday',
    dataIndex: 'monday',
    key: 'monday',
    align: 'center',
    width: 120,
  },
  {
    title: 'Tuesday',
    dataIndex: 'tuesday',
    key: 'tuesday',
    align: 'center',
    width: 120,
  },
  {
    title: 'Wednesday',
    dataIndex: 'wednesday',
    key: 'wednesday',
    align: 'center',
    width: 120,
  },
  {
    title: 'Thursday',
    dataIndex: 'thursday',
    key: 'thursday',
    align: 'center',
    width: 120,
  },
  {
    title: 'Friday',
    dataIndex: 'friday',
    key: 'friday',
    align: 'center',
    width: 120,
  },
  {
    title: 'Saturday',
    dataIndex: 'saturday',
    key: 'saturday',
    align: 'center',
    width: 120,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: 120,
  },
  {
    title: 'Day Of Week',
    dataIndex: 'dayOfWeek',
    key: 'dayOfWeek',
    align: 'center',
    width: 100,
  },
  {
    title: 'Shift Description',
    dataIndex: 'shiftDescription',
    key: 'shiftDescription',
    align: 'center',
    width: 100,
  },
  {
    title: 'Template Id',
    dataIndex: 'templateId',
    key: 'templateId',
    align: 'center',
    width: 100,
  },
  {
    title: 'Employee Number',
    dataIndex: 'employeeNumber',
    key: 'templateId',
    align: 'employeeNumber',
    width: 100,
  },
];

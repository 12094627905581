import { preventCharsInNumberInput } from '@med-fe/util';

export const SLOT_COUNT_CONFIG = {
  templateName: {
    label: 'Pattern Name',
    allowClear: true,
    placeholder: 'Enter text',
  },
  examSlotsFrom: {
    label: 'Exam Slots From',
    allowClear: true,
    inputType: 'number',
    min: 0,
    placeholder: 'Enter number',
    onKeyPress: (e) => preventCharsInNumberInput(e),
  },
  examSlotsTo: {
    label: 'Exam Slots To',
    allowClear: true,
    inputType: 'number',
    min: 0,
    placeholder: 'Enter number',
    onKeyPress: (e) => preventCharsInNumberInput(e),
  },
  totalSlotsFrom: {
    label: 'Total Slots From',
    allowClear: true,
    inputType: 'number',
    min: 0,
    placeholder: 'Enter number',
    onKeyPress: (e) => preventCharsInNumberInput(e),
  },
  totalSlotsTo: {
    label: 'Total Slots To',
    allowClear: true,
    inputType: 'number',
    min: 0,
    placeholder: 'Enter number',
    onKeyPress: (e) => preventCharsInNumberInput(e),
  },
  durationFrom: {
    label: 'Duration From',
    allowClear: true,
    inputType: 'number',
    min: 0,
    placeholder: 'Enter number',
    onKeyPress: (e) => preventCharsInNumberInput(e),
  },
  durationTo: {
    label: 'Duration To',
    allowClear: true,
    inputType: 'number',
    min: 0,
    placeholder: 'Enter number',
    onKeyPress: (e) => preventCharsInNumberInput(e),
  },
};

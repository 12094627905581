import React from 'react';
import { Empty } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getSelectedReport } from '../reports-selector';
import { FiltersForm } from './FiltersForm';

export const ReportFilters = () => {
  const selectedReport = useSelector(getSelectedReport);

  return (
    <ReportFiltersWrapper>
      <FiltersHeader>Parameters</FiltersHeader>
      <FilterBody isEmpty={!selectedReport}>
        {!selectedReport ? <Empty description={<span>NO REPORT SELECTED</span>} /> :
          <FiltersForm reportName={selectedReport} />}
      </FilterBody>
    </ReportFiltersWrapper>
  );
};

const ReportFiltersWrapper = styled.div`
  min-width: 500px;
  width: 100%;
  border-left: none;
  box-shadow: 0 2px 8px #f0f1f2;
`;

const FiltersHeader = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 12px 24px;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 2px 8px #f0f1f2;
`;

const FilterBody = styled.div`
  height: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: ${({ isEmpty }) => isEmpty ? 'center' : 'flex-start'};
  justify-content: ${({ isEmpty }) => isEmpty ? 'center' : 'flex-start'};
`;

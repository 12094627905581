import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportFile } from '../header-actions';
import { useLocation } from 'react-router-dom';
import { getExportLoading } from '../header-reducer';
import { getActiveTab } from '../../../pace/pace-selectors';
import { ExportImportButton, ExportImportButtonText } from '@med-fe/ui';

function Export() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLoading = useSelector(getExportLoading);
  const activeTab = useSelector(getActiveTab);

  return (
    <ExportImportButton
      type='link'
      disabled={
        isLoading ||
        !(pathname === '/templates-assignment' || pathname === '/audit' || pathname === '/exception-management')
      }
      onClick={(e) => {
        e.preventDefault();
        dispatch(exportFile({ tab: activeTab, dispatch, pathname }));
      }}
      loading={isLoading}
      icon={<img alt='export' src={'./images/export.svg'} />}
    >
      <ExportImportButtonText>{isLoading ? 'Exporting' : 'Export'}</ExportImportButtonText>
    </ExportImportButton>
  );
}

export default Export;

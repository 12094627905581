import { EXPORT_FILE, EXPORT_FILE_FAILED, EXPORT_FILE_SUCCESS } from './header-actions';

export const HEADER_STATE_KEY = 'HEADER_STATE_KEY';

export const getExportLoading = (state: any) => state[HEADER_STATE_KEY].loading;

export interface ExportState {
  loading: boolean;
}

const defaultState: ExportState = {
  loading: false,
};

export const headerReducer = (state = defaultState, { type }: any) => {
  switch (type) {
    case EXPORT_FILE: {
      return {
        ...state,
        loading: true,
      };
    }
    case EXPORT_FILE_FAILED:
    case EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

import { NEW_OFFICES_STATE_KEY } from './new-offices-reducer';

export const getIsNewOfficesLoading = (state: any) => state[NEW_OFFICES_STATE_KEY].loading;
export const getNewOffices = (state: any) => state[NEW_OFFICES_STATE_KEY];
export const getNewOfficesForGrid = (state: any) =>
  state[NEW_OFFICES_STATE_KEY].offices.map((office, idx) => ({
    key: `${office?.officeNumber}-${idx}`,
    officeNumber: office?.officeNumber,
    officeName: office?.officeName,
    acuityOfficeStatus: office?.acuityOfficeStatus,
    scheduleStartDate: office?.scheduleStartDate,
  }));
export const getNewOfficesTotal = (state: any) => state[NEW_OFFICES_STATE_KEY].total;
export const getSearchNewOffices = (state: any) => state[NEW_OFFICES_STATE_KEY].search;

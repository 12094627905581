export const templatesMaintenanceColumns = [
  {
    title: 'temptID',
    dataIndex: 'temptID',
    key: 'temptID',
    align: 'center',
    width: 100,
  },
  {
    title: 'range_no',
    dataIndex: 'rangeNo',
    key: 'rangeNo',
    align: 'center',
    width: 100,
  },
  {
    title: 'schedulertempt',
    dataIndex: 'schedulerTemplateID',
    key: 'schedulerTemplateID',
    align: 'center',
    width: 120,
  },
  {
    title: 'service_GroupID',
    dataIndex: 'serviceGroupId',
    key: 'serviceGroupId',
    align: 'center',
    width: 120,
  },
  {
    title: 'tempt_Name',
    dataIndex: 'temptName',
    key: 'temptName',
    align: 'center',
    width: 120,
  },
  {
    title: 'tempt_DisplayName',
    dataIndex: 'temptDisplayName',
    key: 'temptDisplayName',
    align: 'center',
    width: 120,
  },
  {
    title: 'restrict_Service',
    dataIndex: 'restrictService',
    key: 'restrictService',
    align: 'center',
    width: 120,
  },
  {
    title: 'recorded_by_computer',
    dataIndex: 'recordedByComputer',
    key: 'recordedByComputer',
    align: 'center',
    width: 120,
  },
  {
    title: 'recorded_date',
    dataIndex: 'recordedDate',
    key: 'recordedDate',
    align: 'center',
    width: 120,
  },
  {
    title: 'rotation_week',
    dataIndex: 'rotationWeek',
    key: 'rotationWeek',
    align: 'center',
    width: 120,
  },
  {
    title: 'templateName',
    dataIndex: 'templateName',
    key: 'templateName',
    align: 'center',
    width: 100,
  },
  {
    title: 'TemptItemID',
    dataIndex: 'temptItemID',
    key: 'temptItemID',
    align: 'center',
    width: 100,
  },
  //   {
  //     title: 'temptID',
  //     dataIndex: 'temptID',
  //     key: 'temptID',
  //     align: 'center',
  //     width: 100,
  //   },
  {
    title: 'tempt_Start_Time',
    dataIndex: 'temptStartTime',
    key: 'temptStartTime',
    align: 'center',
    width: 100,
  },
  {
    title: 'tempt_End_Time',
    dataIndex: 'temptEndTime',
    key: 'temptEndTime',
    align: 'center',
    width: 100,
  },
  {
    title: 'tempt_DayofWeek',
    dataIndex: 'temptDayOfWeek',
    key: 'temptDayOfWeek',
    align: 'center',
    width: 100,
  },
  {
    title: 'tempt_Duration',
    dataIndex: 'temptDuration',
    key: 'temptDuration',
    align: 'center',
    width: 100,
  },
  {
    title: 'tempt_Interval',
    dataIndex: 'temptInterval',
    key: 'temptInterval',
    align: 'center',
    width: 100,
  },
  {
    title: 'tempt_Selected',
    dataIndex: 'temptSelected',
    key: 'temptSelected',
    align: 'center',
    width: 100,
  },
];

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { setIsHooChangedAction } from '../../../pace/pace-actions';

export const UnmappedGridActions = ({ setSearch }) => {
  const dispatch = useDispatch();
  const [chosenAction, setChosenAction] = useState('NO PATTERN');

  return (
    <>
      <Button
        type={chosenAction === 'NO PATTERN' ? 'primary' : 'default'}
        htmlType='submit'
        className={`button ${chosenAction === 'NO PATTERN' ? '' : 'ant-btn-default'}`}
        style={{ marginLeft: 20 }}
        onClick={() => {
          setSearch({ base: true, page: 0 });
          setChosenAction('NO PATTERN');
          dispatch(setIsHooChangedAction(false));
        }}
      >
        NO PATTERN
      </Button>
      <Button
        type={chosenAction === 'ALL' ? 'primary' : 'default'}
        htmlType='submit'
        className={`button1 ${chosenAction === 'ALL' ? '' : 'ant-btn-default'}`}
        style={{}}
        onClick={() => {
          setSearch({ base: false, page: 0 });
          setChosenAction('ALL');
          dispatch(setIsHooChangedAction(false));
        }}
      >
        ALL
      </Button>
      <Button
        type={chosenAction === 'HOO CHANGED' ? 'primary' : 'default'}
        htmlType='submit'
        disabled={true}
        className={`button ${chosenAction === 'HOO CHANGED' ? '' : 'ant-btn-default'}`}
        onClick={() => {
          setSearch({ base: true, page: 0 });
          setChosenAction('HOO CHANGED');
          dispatch(setIsHooChangedAction(true));
        }}
      >
        HOO CHANGED
      </Button>
    </>
  );
};

import {
  CLEAN_IMPORT_LAYOUT,
  SET_SEARCH_EXCEPTION,
  SEARCH_EXCEPTION,
  SEARCH_EXCEPTION_SUCCESS,
  FETCH_EXCEPTION_DETAILS_FAILED,
  SEARCH_EXCEPTION_FAILED,
  SET_SORT_CRITERIAS,
  FETCH_EXCEPTION_DETAILS,
  FETCH_EXCEPTION_DETAILS_SUCCESS,
  CREATE_EXCEPTION,
  CREATE_EXCEPTION_FAILED,
  CREATE_EXCEPTION_SUCCESS,
  FETCH_OFFICES_SUCCESS,
  FETCH_EMPLOYEES_SUCCESS,
} from './actions';

type TState = {
  exception: {
    contents: [];
    pages: number;
    total: number;
  };
  search: {
    page: number;
    size: number;
    exceptionName?: string;
    pace?: {
      from: number;
      to: number;
    };
  };
  sorting: {};
  loading: boolean;
  error: any;
  offices: any[];
  employees: any[];
  exceptionDetails: any;
  detailsLoading: boolean;
  removeLoading: boolean;
  importLoading: boolean;
  importFailed: boolean;
  importValidationData: any[];
  totalCount: any;
  successErrorReport: any;
};

const initialState: TState = {
  exception: {
    contents: [],
    pages: 0,
    total: 0,
  },
  search: {
    page: 0,
    size: 15,
  },
  sorting: {},
  loading: false,
  error: null,
  offices: [],
  employees: [],
  exceptionDetails: null,
  detailsLoading: false,
  removeLoading: false,
  importLoading: false,
  importFailed: false,
  importValidationData: [],
  totalCount: null,
  successErrorReport: {},
};

export const exceptionManagementReducer = (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case SET_SEARCH_EXCEPTION:
      return {
        ...state,
        search: { ...state.search, ...payload },
      };
    case SEARCH_EXCEPTION:
      return {
        ...state,
        loading: payload,
      };
    case SEARCH_EXCEPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        exception: payload,
      };
    case FETCH_EXCEPTION_DETAILS_FAILED:
    case SEARCH_EXCEPTION_FAILED:
    case SET_SORT_CRITERIAS:
      return {
        ...state,
        sorting: payload,
      };
    case FETCH_EXCEPTION_DETAILS:
      return {
        ...state,
        detailsLoading: true,
      };
    case FETCH_EXCEPTION_DETAILS_SUCCESS:
      return {
        ...state,
        detailsLoading: false,
        exceptionDetails: payload,
      };
    case CREATE_EXCEPTION:
    case CREATE_EXCEPTION_SUCCESS:
      return {
        ...state,
        detailsLoading: false,
        exceptionDetails: payload,
      };
    case CREATE_EXCEPTION_FAILED:
      return {
        ...state,
        detailsLoading: false,
        error: payload,
      };
    case FETCH_OFFICES_SUCCESS:
      return {
        ...state,
        offices: payload,
      };
    case FETCH_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: payload,
      };
    case CLEAN_IMPORT_LAYOUT: {
      return {
        ...state,
        successErrorReport: {},
      };
    }
    default:
      return state;
  }
};

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ExamBar } from './ExamBar';
import { useSelector } from 'react-redux';
import { getTemplateDetails } from '../../templates-selector';
import { Button } from 'antd';

export const ExamsPanel = () => {
  const details = useSelector(getTemplateDetails);

  return (
    <StyledPanel>
      <StiledExamsLine>
        {details?.examsNameCount.map(({ examName, count }) => (
          <ExamBar key={examName} title={examName} count={count} />
        ))}
      </StiledExamsLine>
      <StyledAddButton disabled>+</StyledAddButton>
    </StyledPanel>
  );
};

const StyledPanel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 40px 20px 0;
  border-top: 1px solid rgb(186, 210, 227);
`;

const StiledExamsLine = styled.div`
  display: flex;
  margin-bottom: 35px;
  flex-wrap: wrap;
`;

const StyledAddButton = styled(Button)`
  height: 60px;
  width: 70px;
  font-size: 35px;
  line-height: 1;
  font-weight: bold;
  color: #757575;
`;

import { AuthService, loginRequest } from '@med-fe/data';

export const useSignIn = ({ instance }) => {
  const onSignIn = () => {
    instance
      .loginPopup(loginRequest)
      .then(() => AuthService.refreshAccessToken())
      .catch((e) => console.error(e));
  };

  return { onSignIn };
};

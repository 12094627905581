import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import { get, isNil, omit, uniq } from 'lodash';

import {
  getDontHaveTemplateForParticularHOO,
  formatDate,
  getDayIndex,
  getIsFitSchedule,
  getIsSameOrBeforeMonth,
} from '../../../../common/helpers/date';
import { getAssignInProgress, getTemplates, getTemplatesLoading } from '../../../../pace/pace-selectors';
import { employeePatternColumns } from '../../grid-configs/employee-pattern-columns';

import { OfficeHeadCell } from '../../cell-components/OfficeHeadCell';
import useTemplateScroll from '../../hooks/use-template-scroll';
import {
  tooltipInnerStyle,
  tooltipStyle,
  SelectWrap,
  StyledTooltip,
  StyledSelect,
  StyledTable,
} from '../styled/styled';

const WeekGrid = (props) => {
  const {
    doctor,
    week,
    idx,
    startDate,
    selectedOptions,
    setSelectedOptions,
    isWholeWeek,
    isSuggestedOptions,
    setIsAssignDisabled,
    getTemplateNameByOptions,
    locationInEditMode,
    onEditLocation,
    setIsOfficesTouched,
    setIsOfficesDaysTouched,
    isOfficesDaysTouched,
  } = props;
  const templatesLoading = useSelector(getTemplatesLoading);
  const templates = useSelector(getTemplates);
  const assignInProgress = useSelector(getAssignInProgress);

  const [dayOfWeek, setDayOfWeek]: any = useState(null);

  const { onTemplatesScroll, onSelectSearch, onSelectBlur } = useTemplateScroll({
    templatesLoading,
    office: locationInEditMode,
    dayOfWeek,
    setDayOfWeek,
  });

  useEffect(() => {
    if (!isNil(dayOfWeek)) onSelectSearch('');
  }, [dayOfWeek]);

  const allWeakWithoutHOO = () => {
    return doctor?.weeks?.[0].every((e) => !e.office);
  };

  const onSelectChoice = (templateId, option, dayOfWeek, office) => {
    if (!isNil(dayOfWeek)) {
      setSelectedOptions((prev) => {
        let data;
        if (isNil(option)) {
          data = isWholeWeek ? {} : { ...omit(prev[office], dayOfWeek) };
        } else {
          data = isWholeWeek
            ? {
                ...[0, 1, 2, 3, 4, 5, 6].reduce((acc, i) => {
                  const template = allWeakWithoutHOO() ? option : getTemplateNameByOptions({ 0: option }, i);
                  return {
                    ...acc,
                    ...(!!template && { [i]: template }),
                  };
                }, {}),
              }
            : !!option && { ...prev[office], [dayOfWeek]: option };
        }

        return { ...prev, [office]: data };
      });
      setIsAssignDisabled(false);
      setIsOfficesTouched((prev) => uniq([...prev, office]));
      setIsOfficesDaysTouched((prev) => ({
        ...prev,
        ...{
          [locationInEditMode]: prev[locationInEditMode] ? uniq([...prev[locationInEditMode], dayOfWeek]) : [dayOfWeek],
        },
      }));
    }
  };

  const isCellDisabled = (date) => idx !== 0 && !getIsSameOrBeforeMonth(date, startDate);

  const columns = employeePatternColumns(doctor, isCellDisabled).map((col: any) => {
    if (col.dataIndex?.includes('template')) {
      const { office, officeName } = doctor.offices.find((office) => office.office === col.key);

      col.render = (text, { day, ooh, date }) => {
        const getValue = (prop) => get(selectedOptions, `[${office}][${getDayIndex(day)}].${prop}`);
        const value = getValue('children');
        const isTouched = isOfficesDaysTouched[office] && isOfficesDaysTouched[office].includes(getDayIndex(day));
        const isSuggested = selectedOptions?.[office]?.[getDayIndex(day)]?.suggested;

        let isBold = false;

        if (locationInEditMode === office) {
          if (locationInEditMode === doctor.office) {
            isBold = !isSuggestedOptions || isTouched;
          } else {
            isBold = true;
          }
        }

        const fitSchedule = () => {
          return getIsFitSchedule({
            template: selectedOptions?.[locationInEditMode][getDayIndex(day)]?.schedule,
            office: ooh,
          });
        };

        const dontHaveTemplateForParticularHOO = () => {
          return getDontHaveTemplateForParticularHOO({
            template: selectedOptions?.[locationInEditMode][getDayIndex(day)],
            office: ooh,
          });
        };

        return idx === 0 ? (
          <SelectWrap>
            {locationInEditMode && locationInEditMode === office ? (
              ((isWholeWeek || isSuggestedOptions) && dontHaveTemplateForParticularHOO().needShowWarning) ||
              fitSchedule().needShowWarning ? (
                <StyledTooltip
                  title={
                    (isWholeWeek || isSuggestedOptions) && dontHaveTemplateForParticularHOO().needShowWarning
                      ? dontHaveTemplateForParticularHOO().warningMessage
                      : fitSchedule().warningMessage
                  }
                  color='white'
                  overlayInnerStyle={tooltipInnerStyle}
                  overlayStyle={tooltipStyle}
                  placement='left'
                  autoAdjustOverflow={false}
                >
                  <img alt='info' src={'./icons/info-gray.svg'} />
                </StyledTooltip>
              ) : null
            ) : null}

            <StyledSelect
              showSearch
              allowClear={getDayIndex(day) === dayOfWeek}
              placeholder='Select a template'
              size='small'
              bordered={false}
              disabled={locationInEditMode !== office || isCellDisabled(date)}
              onFocus={() => setDayOfWeek(getDayIndex(day))}
              onBlur={() => onSelectBlur()}
              loading={
                locationInEditMode === office && !!templatesLoading && templatesLoading.dayOfWeek === getDayIndex(day)
              }
              onChange={(value, option) => onSelectChoice(value, option, dayOfWeek, office)}
              onSearch={onSelectSearch}
              onPopupScroll={(event) => onTemplatesScroll(event, day)}
              filterOption={() => true}
              value={value}
              $isBold={isBold}
              $isSuggested={isSuggested}
              getPopupContainer={() => document.querySelector('.ant-drawer-body .week')}
            >
              {templates[getDayIndex(day)] &&
                templates[getDayIndex(day)].map((it) => (
                  <Select.Option value={it.id} key={it.id} schedule={{ start: it?.start, end: it?.end }}>
                    {it.templateName}
                  </Select.Option>
                ))}
            </StyledSelect>
          </SelectWrap>
        ) : (
          <span className={`${isCellDisabled(date) ? 'grayed-out' : ''} plain-text`}>{value}</span>
        );
      };

      col.title = (
        <OfficeHeadCell
          isFirst={idx === 0}
          office={office}
          officeName={officeName}
          onEditLocation={onEditLocation}
          isEditing={locationInEditMode === office}
        />
      );
    }

    return col;
  });

  const dataSource = week?.map((it) => ({
    key: `${it?.dayOfWeek}`,
    template: 'select',
    day: it?.dayOfWeek,
    date: formatDate(it?.date),
    time: Object.entries(it?.offices ?? {})?.map(([office, { personalSchedule }]) => ({
      office,
      start: personalSchedule?.start,
      end: personalSchedule?.end,
      main: office === locationInEditMode,
    })),
    ooh: {
      start: it?.offices?.[locationInEditMode]?.officeSchedule?.start,
      end: it?.offices?.[locationInEditMode]?.officeSchedule?.end,
    },
    otherOfficeTemplates: it?.otherOfficeTemplates,
  }));

  return (
    <StyledTable
      scroll={{ x: 450 }}
      loading={assignInProgress}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      isFirst={idx === 0}
    />
  );
};

export default WeekGrid;

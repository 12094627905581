import { get } from 'lodash';

const APP_PORT_LOCAL = {
  '3000': 'mod',
  '3001': 'pace',
};

export const DEFAULT_GRID_SIZE = 15;

export const reactApp = () => {
  const href = window.location.href;
  const params = {
    apiUrl: '',
    clientId:
      href.includes('mod.myeyedr.com') || href.includes('pace.myeyedr.com')
        ? 'b785e81b-4788-48dc-881c-c5feda775be1'
        : '6c9fa750-e9c6-4311-9255-58b46446ff51',
    tenantId: 'e3ddc75a-a893-4372-a9ae-006db73001df',
    redirectUri: '',
    routerBasename: '',
  };

  if (href.includes('moddev.myeyedr.com')) {
    params.apiUrl = `https://app-moddevapi.azurewebsites.net`;
    params.redirectUri = 'https://moddev.myeyedr.com';
    params.routerBasename = '';
  } else if (href.includes('modtest.myeyedr.com')) {
    params.apiUrl = `https://app-modtestapi.azurewebsites.net`;
    params.redirectUri = 'https://modtest.myeyedr.com';
    params.routerBasename = '';
  } else if (href.includes('modqa1.myeyedr.com')) {
    params.apiUrl = `https://app-modqa1api-adczgwcsatfsaucx.eastus2-01.azurewebsites.net`;
    params.redirectUri = 'https://modqa1.myeyedr.com';
    params.routerBasename = '';
  } else if (href.includes('mod.myeyedr.com')) {
    params.apiUrl = `https://app-modprodapi.azurewebsites.net`;
    params.redirectUri = 'https://mod.myeyedr.com';
    params.routerBasename = '';
  } else if (href.includes('lemon-hill-0373ec00f') || href.includes('pacedev.myeyedr.com')) {
    params.apiUrl = `https://app-pacedevapi.azurewebsites.net`;
    params.redirectUri = 'https://pacedev.myeyedr.com';
    params.routerBasename = '';
  } else if (href.includes('blue-ground-04278810f') || href.includes('pacetest.myeyedr.com')) {
    params.apiUrl = `https://app-pacetestapi.azurewebsites.net`;
    params.redirectUri = 'https://pacetest.myeyedr.com';
    params.routerBasename = '';
  } else if (href.includes('paceqa1.myeyedr.com')) {
    params.apiUrl = `https://paceqa1.azurewebsites.net`;
    params.redirectUri = 'https://paceqa1.myeyedr.com';
    params.routerBasename = '';
  } else if (href.includes('pace.myeyedr.com')) {
    params.apiUrl = `https://app-paceprodapi.azurewebsites.net`;
    params.redirectUri = 'https://pace.myeyedr.com';
    params.routerBasename = '';
  } else if (href.includes('localhost')) {
    const port = get(href.match(/:([0-9]+)\//), '[1]');
    params.apiUrl = 'https://app-pacetestapi.azurewebsites.net';
    params.redirectUri = `http://localhost:${port}`;
  }

  return params;
};

export const ZIP_VALIDATION_RULE = {
  pattern: /^[0-9]*$/,
  message: 'Only Numbers allowed',
};

export const PHONE_VALIDATION_RULE = {
  pattern: /^([0-9]{3})-([0-9]{3})-([0-9]{4})$/,
  message: "Write number in format: '111-111-1111",
};

export const EMAIL_VALIDATION_RULE = {
  pattern: /^[A-Za-z0-9._%+-]+@myeyedr\.com$/,
  message: 'Write email in format: xyz@myeyedr.com',
};

export const EMAIL_REQUIRED_RULE = {
  required: true,
  message: 'Please enter email id',
};

export const NPI_VALIDATION_RULE = {
  pattern: /^[0-9]{10}$/,
  message: 'NPI Number should be 10 digits only',
};

export const NAME_REQUIRED_RULE = {
  required: true,
  message: 'Please enter doctor name',
};

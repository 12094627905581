import React from 'react';
import { List } from 'antd';
import { StyledDayTitle } from '../styled';
import { RampScheduleDto } from '../../../../models/ramp-settings-dto';
import { DayPatternSelect } from './DayPatternSelect';

export const DaysList = ({ rampScheduleDtos, weekNumber }) => {
  return (
    <List
      size='small'
      bordered={false}
      dataSource={rampScheduleDtos}
      renderItem={(rampSchedule: RampScheduleDto) => (
        <List.Item>
          <DayPatternSelect weekNumber={weekNumber} rampSchedule={rampSchedule} />
          <StyledDayTitle>{rampSchedule.dayOfWeek}</StyledDayTitle>
        </List.Item>
      )}
    />
  );
};

import React, { useEffect, useState } from 'react';
import { Button, Form, Input, DatePicker, Select, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchSpecialRanges } from '../../../pace/pace-actions';
import { getSpecialRangesSearch } from '../../../pace/pace-selectors';
import { useSpecialRanges } from '../../../common/hooks/specialRanges';
import { SuffixIcon, TagRenderer } from '@med-fe/ui';
import { omit, get } from 'lodash';
import AdvancedSearchButton from '../../../common/AdvancedSearchButton';

const { RangePicker } = DatePicker;

function SearchSpecialRanges({ activeTab, search, isAllSelected, setIsSearch }: any) {
  const { options, handleChange, currentValue, regions, setRegionIds, districts, setDistrictIds, filterDistricts } =
    useSpecialRanges(activeTab, isAllSelected);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const specialRangesSearch = useSelector(getSpecialRangesSearch);
  const { base } = search;
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      dateRange: [],
      regionIds: [],
      districtIds: [],
    });
    setRegionIds([]);
    setDistrictIds([]);
  }, [activeTab]);

  const onSubmit = (formData) => {
    const { dateRange, ...data } = formData;
    const dates = {
      dateFrom: null,
      dateTo: null,
    };

    if (dateRange) {
      dates['dateFrom'] = dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null;
      dates['dateTo'] = dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null;
    }
    dispatch(
      setSearchSpecialRanges({ ...specialRangesSearch, ...omit(data, 'searchReason'), ...dates, page: 0, search: true })
    );
    setIsSearch(true);
  };

  return (
    <div className='search-container'>
      <Form
        form={form}
        layout='vertical'
        fields={[
          {
            name: ['searchReason'],
            value: currentValue,
          },
        ]}
        initialValues={{ searchReason: ['No Lunch Shifts,isNoLunchShifts'] }}
        // className='search-container'
        onFinish={onSubmit}
      >
        <Row wrap={false}>
          <Col>
            <Row wrap={false}>
              <Form.Item
                key='name'
                label={'Employee Name'}
                name='employeeName'
                className='label-text input-field input-field_name'
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                key='id'
                label={'Employee ID'}
                name='employeeId'
                className='label-text input-field input-field_employee-id'
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                key='office'
                label={'Office Number'}
                name='office'
                className='label-text input-field input-field_office-number'
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                key='searchReason'
                name='searchReason'
                label='Search by Reason'
                className='label-text search-action'
              >
                <Select
                  mode='multiple'
                  className='select'
                  allowClear
                  onChange={handleChange}
                  size='small'
                  maxTagCount='responsive'
                >
                  {options}
                </Select>
              </Form.Item>
              <Form.Item
                key='regionIds'
                label={'Search by Region'}
                name='regionIds'
                className='label-text region-field'
              >
                <Select
                  allowClear
                  mode={'multiple'}
                  maxTagCount={'responsive'}
                  tagRender={(props) => <TagRenderer {...props} />}
                  placeholder='...'
                  suffixIcon={<SuffixIcon />}
                  filterOption={(inputValue, option: any) => {
                    const region = option.value.split('(');
                    return get(region, '[0]').toUpperCase().includes(inputValue.toUpperCase());
                  }}
                  options={regions.map(({ id, name }) => ({ id, value: name }))}
                  onChange={(value, option: [] | any) => {
                    if (!option.length) {
                      setRegionIds([]);
                      setDistrictIds([]);
                      form.resetFields(['districtIds', 'regionIds']);
                    } else {
                      setRegionIds(option);
                      form.setFieldsValue({ districtIds: filterDistricts(option).map(({ value }) => value) });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                key='districtIds'
                label={'Search by District'}
                name='districtIds'
                className='label-text district-field'
              >
                <Select
                  allowClear
                  mode={'multiple'}
                  maxTagCount={'responsive'}
                  tagRender={(props) => <TagRenderer {...props} />}
                  placeholder='...'
                  suffixIcon={<SuffixIcon />}
                  options={districts.map(({ id, name, regionName }) => ({
                    id,
                    value: `${name} (${regionName})`,
                    key: id,
                  }))}
                  filterOption={(inputValue, option: any) => {
                    const district = option.value.split('(');
                    return get(district, '[0]').toUpperCase().includes(inputValue.toUpperCase());
                  }}
                  onChange={(value, option: [] | any) => {
                    if (!option.length) {
                      setDistrictIds([]);
                      form.setFieldsValue({ districtIds: [] });
                    } else {
                      setDistrictIds(option);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                key='dateRange'
                name='dateRange'
                label='Select Date Range'
                className='label-text input-field input-field_date-range'
              >
                <RangePicker allowEmpty={[false, false]} format={['YYYY-MM-DD']} separator='-' />
              </Form.Item>
              <Col style={{ display: 'flex', marginTop: '10px' }}>
                <Form.Item className='search-btn'>
                  <Button type='primary' htmlType='submit'>
                    SEARCH
                  </Button>
                  <AdvancedSearchButton active={!advancedSearch} onClick={() => setAdvancedSearch(!advancedSearch)} />
                </Form.Item>
              </Col>
            </Row>
            {advancedSearch && (
              <Row wrap={false}>
                <Form.Item
                  key='odShiftLengthFrom'
                  label={'OD’s shift From'}
                  name='odShiftLengthFrom'
                  className='label-text input-field-number'
                >
                  <Input allowClear type='number' min={0} max={24} />
                </Form.Item>
                <Form.Item
                  key='odShiftLengthTo'
                  label={'OD’s shift To'}
                  name='odShiftLengthTo'
                  className='label-text input-field-number'
                >
                  <Input allowClear type='number' min={0} max={24} />
                </Form.Item>
                <Form.Item
                  key='officeLengthHOOFrom'
                  label={'Office HOO From'}
                  name='officeLengthHOOFrom'
                  className='label-text input-field-number'
                >
                  <Input allowClear type='number' min={0} max={24} />
                </Form.Item>
                <Form.Item
                  key='officeLengthHOOTo'
                  label={'Office HOO To'}
                  name='officeLengthHOOTo'
                  className='label-text input-field-number'
                >
                  <Input allowClear type='number' min={0} max={24} />
                </Form.Item>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default SearchSpecialRanges;

import { combineReducers } from 'redux';
import { AUDIT_STATE_KEY, auditReducer } from '../components/audit/audit-reducer';
import { USER_STATE_KEY, userReducer } from './user/user-reducer';
import { DOCTORS_STATE_KEY, doctorsReducer, TEMPLATES_STATE_KEY, templatesReducer } from '../pace/pace-reducer';
import { TEMPLATES_MANAGEMENT_STATE_KEY } from '../components/templates/templates-selector';
import { templatesManagementReducer } from '../components/templates/templates-reducer';
import { NEW_OFFICES_STATE_KEY, newOfficesReducer } from '../pace/new-offices/new-offices-reducer';
import { REPORTS_MANAGEMENT_STATE_KEY, reportsManagementReducer } from '../components/reports/reports-reducer';
import { COMMON_STATE_KEY, commonReducer } from './common/common-reducer';
import { HEADER_STATE_KEY, headerReducer } from '../components/header/header-reducer';
import { EXCEPTION_MANAGEMENT_STATE_KEY } from '../components/ExceptiopnManagement/selectors';
import { exceptionManagementReducer } from '../components/ExceptiopnManagement/reducer';

const rootReducer = combineReducers({
  [AUDIT_STATE_KEY]: auditReducer,
  [USER_STATE_KEY]: userReducer,
  [DOCTORS_STATE_KEY]: doctorsReducer,
  [TEMPLATES_STATE_KEY]: templatesReducer,
  [TEMPLATES_MANAGEMENT_STATE_KEY]: templatesManagementReducer,
  [NEW_OFFICES_STATE_KEY]: newOfficesReducer,
  [REPORTS_MANAGEMENT_STATE_KEY]: reportsManagementReducer,
  [COMMON_STATE_KEY]: commonReducer,
  [HEADER_STATE_KEY]: headerReducer,
  [EXCEPTION_MANAGEMENT_STATE_KEY]: exceptionManagementReducer,
});

export default rootReducer;

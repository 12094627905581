import React from 'react';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { Title, StyledBackButton } from './styled';
import Export from './export/Export';
import { StyledHeader, StyledProfile } from '@med-fe/ui';
import { useSelector } from 'react-redux';
import { getIsAccessDenied } from '../../store/user/user-reducer';
import { getCurrentWeekRange } from '../../common/helpers/date';
import NavbarMenu from '../navbar/NavbarMenu';
import { routes } from '../../App';
import { useHistory, useLocation } from 'react-router-dom';
import { isEqual, upperCase } from 'lodash';
import { Adjustment } from './adjustment/Adjustment';

export default function Header() {
  const isAuthenticated = useIsAuthenticated();
  const isAccessDenied = useSelector(getIsAccessDenied);
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <StyledHeader isAuthenticated={isAuthenticated}>
      <AuthenticatedTemplate>
        {!isAccessDenied && (
          <>
            <NavbarMenu routes={routes} />
            <Title>
              {isEqual(pathname, '/templates-assignment') &&
                upperCase(`Open slots for the month of ${getCurrentWeekRange()}`)}
              {isEqual(pathname, '/templates-management') && upperCase('Template Management')}
              {isEqual(pathname, '/exception-management') && upperCase('Exception Management')}
              {pathname.includes('/templates-management/details') && (
                <StyledBackButton type={'link'} onClick={() => history.push(`/templates-management`)}>
                  <img alt='back' src={'./icons/back.svg'} />
                  <span>{upperCase('Template Management')}</span>
                </StyledBackButton>
              )}
            </Title>
            <Export />
            <Adjustment />
          </>
        )}
      </AuthenticatedTemplate>
      {isAuthenticated && <StyledProfile />}
    </StyledHeader>
  );
}

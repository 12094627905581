import {
  CLEAR_SELECT_OPTIONS,
  FETCH_PATTERNS,
  FETCH_PATTERNS_FAILED,
  FETCH_PATTERNS_SUCCESS,
  FETCH_RAMP_SETTINGS,
  FETCH_RAMP_SETTINGS_SUCCESS,
  SET_SELECTED_WEEK_DAYS_PATTERNS,
  UPDATE_RAMP_SETTINGS,
  UPDATE_RAMP_SETTINGS_FAILED,
  UPDATE_RAMP_SETTINGS_SUCCESS,
} from './common-actions';
import { RampSettingsDto, ReasonRampSettingsDto } from '../../models/ramp-settings-dto';
import { DayOfWeekMonday } from '../../models/day-of-week-monday-enum';
import { merge } from 'lodash';
import { eightWeeksNumbers } from '@med-fe/util';

export const COMMON_STATE_KEY = 'COMMON_REDUCER';

export interface ICommonState {
  reasonsRampSettings: ReasonRampSettingsDto;
  selectedWeekDaysPatterns: any;
  selectOptions: any[];
  settingsLoading: boolean;
  selectLoading: boolean;
  selectLoadingId: string | null;
}

const setRampsSetting = (ramps: RampSettingsDto[] = []) => {
  let selectedWeekDaysPatterns = {};
  const days = Object.keys(DayOfWeekMonday);

  const rampSettingsDtoList: RampSettingsDto[] = eightWeeksNumbers.map((weekNumber) => {
    const rampsWeek = ramps.find((ramp) => +ramp.weekNumber === weekNumber);
    const rampScheduleDtos = days.map((dayOfWeek) => {
      const rampSchedule = { dayOfWeek };

      if (rampsWeek && rampsWeek.rampScheduleDtos.length) {
        const dayWithPattern = rampsWeek.rampScheduleDtos.find((scheduleDay) => dayOfWeek === scheduleDay.dayOfWeek);

        if (dayWithPattern) {
          merge(rampSchedule, dayWithPattern);
          selectedWeekDaysPatterns = {
            ...selectedWeekDaysPatterns,
            [weekNumber]: {
              ...selectedWeekDaysPatterns[weekNumber],
              [dayWithPattern.dayOfWeek]: dayWithPattern.patternName,
            },
          };
        }
      }

      return rampSchedule;
    });

    return { weekNumber, rampScheduleDtos } as RampSettingsDto;
  });

  return { rampSettingsDtoList, selectedWeekDaysPatterns };
};

const defaultState: ICommonState = {
  reasonsRampSettings: {
    rampSettingsDtoList: setRampsSetting().rampSettingsDtoList,
    specialRangesReasons: 'NEW_HIRES',
  },
  selectedWeekDaysPatterns: {},
  selectOptions: [],
  settingsLoading: false,
  selectLoading: false,
  selectLoadingId: null,
};

export const commonReducer = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case FETCH_RAMP_SETTINGS:
    case UPDATE_RAMP_SETTINGS:
      return {
        ...state,
        settingsLoading: true,
      };
    case FETCH_RAMP_SETTINGS_SUCCESS:
      const { rampSettingsDtoList, selectedWeekDaysPatterns } = setRampsSetting(
        payload ? payload.rampSettingsDtoList : []
      );

      return {
        ...state,
        reasonsRampSettings: {
          rampSettingsDtoList,
          specialRangesReasons: payload ? payload.specialRangesReasons : '',
        },
        selectedWeekDaysPatterns,
        settingsLoading: false,
      };
    case UPDATE_RAMP_SETTINGS_SUCCESS:
    case UPDATE_RAMP_SETTINGS_FAILED:
      return {
        ...state,
        settingsLoading: false,
      };
    case SET_SELECTED_WEEK_DAYS_PATTERNS:
      return {
        ...state,
        selectedWeekDaysPatterns: payload ? { ...merge(state.selectedWeekDaysPatterns, payload) } : {},
      };
    case FETCH_PATTERNS:
      return {
        ...state,
        selectLoadingId: payload.selectLoadingId,
      };
    case FETCH_PATTERNS_SUCCESS:
      return {
        ...state,
        selectOptions: payload,
        selectLoadingId: null,
      };
    case FETCH_PATTERNS_FAILED:
      return {
        ...state,
        selectLoadingId: null,
      };
    case CLEAR_SELECT_OPTIONS:
      return {
        ...state,
        selectLoadingId: null,
        selectOptions: [],
      };
    default:
      return state;
  }
};

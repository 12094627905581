/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from './components/header/Header';
import Pace from './pace/Pace';

import styles from './App.module.scss';
import { setNotificationsConfig } from '@med-fe/util';
import { get } from 'lodash';
import { setUserRoles } from './store/user/user-actions';
import { getIsAccessDenied } from './store/user/user-reducer';
import { reactApp } from '@med-fe/util';
import { Templates } from './components/templates/Templates';
import Audit from './components/audit/Audit';
import { TemplatesDetails } from './components/templates/template-details/TemplateDetails';
import { Login } from '@med-fe/ui';
import { ReportsManagement } from './components/reports/ReportsManagement';
import { InteractionType } from '@azure/msal-browser';
import SIGN_IN_LOGO from './assets/images/sign-in-logo.svg';
import ExceptionManagement from './components/ExceptiopnManagement/ExceptionManagement';

export const routes = [
  {
    path: '/templates-assignment',
    title: 'Templates Assignment',
    content: () => <Pace />,
  },
  {
    path: '/templates-management',
    title: 'Templates Management',
    content: () => <Templates />,
  },
  {
    path: '/exception-management',
    title: 'Exception Management',
    content: () => <ExceptionManagement />,
  },
  {
    path: '/reports',
    title: 'Reports',
    content: () => <ReportsManagement />,
  },
  {
    path: '/audit',
    title: 'Audit',
    content: () => <Audit />,
  },
];

export default function App() {
  setNotificationsConfig();
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const isAccessDenied = useSelector(getIsAccessDenied);
  const routerBasename = reactApp().routerBasename;

  useEffect(() => {
    dispatch(setUserRoles(get(instance.getActiveAccount(), 'idTokenClaims.roles', [])));
  }, [accounts]);

  return (
    <Router basename={routerBasename}>
      <div className={styles.App}>
        <div className={styles.container}>
          <Header />
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={(result) => (
              <section className='login-screen'>
                <section className='login-card'>
                  <img src={SIGN_IN_LOGO} width={120} height={120} />
                  <div>{result.error?.message}</div>
                </section>
              </section>
            )}
            loadingComponent={() => (
              <section className='login-screen'>
                <section className='login-card'>
                  <img src={SIGN_IN_LOGO} width={120} height={120} />
                  <div>Validating Login</div>
                </section>
              </section>
            )}
          >
            {isAccessDenied ? (
              <p className={styles['unauthenticated-text']}>
                You have no permission. Please contact your administrator.
              </p>
            ) : (
              <main className={styles.main}>
                <Switch>
                  <Route exact path={['/', '/pace']}>
                    <Redirect to='/templates-assignment' />
                  </Route>
                  <Route component={Pace} path={'/templates-assignment'} />
                  <Route component={Audit} path={'/audit'} />
                  <Route component={ReportsManagement} path={'/reports'} />
                  <Route
                    path='/templates-management'
                    render={({ match: { url } }) => (
                      <>
                        <Route path={`${url}/`} component={Templates} exact />
                        <Route path={`${url}/details/:templateId`} component={TemplatesDetails} />
                      </>
                    )}
                  />
                  <Route component={ExceptionManagement} path={'/exception-management'} />
                </Switch>
              </main>
            )}
          </MsalAuthenticationTemplate>
          {/* <AuthenticatedTemplate>
            {isAccessDenied ? (
              <p className={styles['unauthenticated-text']}>
                You have no permission. Please contact your administrator.
              </p>
            ) : (
              <main className={styles.main}>
                <Switch>
                  <Route exact path={['/', '/pace']}>
                    <Redirect to='/templates-assignment' />
                  </Route>
                  <Route component={Pace} path={'/templates-assignment'} />
                  <Route component={Audit} path={'/audit'} />
                  <Route component={ReportsManagement} path={'/reports'} />
                  <Route
                    path='/templates-management'
                    render={({ match: { url } }) => (
                      <>
                        <Route path={`${url}/`} component={Templates} exact />
                        <Route path={`${url}/details/:templateId`} component={TemplatesDetails} />
                      </>
                    )}
                  />
                </Switch>
              </main>
            )}
          </AuthenticatedTemplate> */}

          <UnauthenticatedTemplate>
            <Login instance={instance} />
          </UnauthenticatedTemplate>
        </div>
      </div>
    </Router>
  );
}

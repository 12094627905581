import React, { useEffect } from 'react';
import { Form, Input, Select, Typography } from 'antd';
import {
  StyledExamConfigForm,
  StyledFormItem,
  StyledConfCornerForm,
  StyledPrimaryButton,
  StyledFormItemSummary,
} from '../../../../common/styled';
import { SuffixIcon } from '@med-fe/ui';
import { useSelector } from 'react-redux';
import { getExamEndTimeByName, getExamsNameCount } from '../../../templates-selector';

const { Title } = Typography;

export const Examination = ({ data }) => {
  const [form] = Form.useForm();
  const count = useSelector((state) => getExamsNameCount(state, data.examName));
  const endTime = useSelector((state) => getExamEndTimeByName(state, data.examName));

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data, examNumber: count });
    }
  }, [data]);

  return (
    <>
      <StyledExamConfigForm form={form} layout='vertical' className='search-container'>
        <StyledFormItem key='from' name='from' label='Start time'>
          <Select allowClear placeholder={'Select'} suffixIcon={<SuffixIcon />} disabled />
        </StyledFormItem>
        <StyledFormItem key='minutesBetweenExams' name='minutesBetweenExams' label='Minutes between exams'>
          <Select allowClear placeholder={'Select'} suffixIcon={<SuffixIcon />} disabled />
        </StyledFormItem>
        <StyledFormItem key='examNumber' name='examNumber' label='# of exams'>
          <Input allowClear placeholder='0' disabled />
        </StyledFormItem>
        <StyledFormItem key='durationOfExam' name='durationOfExam' label='Duration of exam'>
          <Select allowClear placeholder={'Select'} suffixIcon={<SuffixIcon />} disabled />
        </StyledFormItem>
        <StyledFormItem key='establishPace' name='establishPace' label='Establish pace'>
          <Input allowClear disabled />
        </StyledFormItem>
        <StyledConfCornerForm>
          <StyledFormItemSummary key='exams' name='exams' label='Exams'>
            <Title level={5}>{count} </Title>
          </StyledFormItemSummary>
          <StyledFormItemSummary key='to' name='to' label='End time:'>
            <Title level={5}>{endTime} </Title>
          </StyledFormItemSummary>
          <StyledPrimaryButton type={'primary'} disabled>
            Insert
          </StyledPrimaryButton>
        </StyledConfCornerForm>
      </StyledExamConfigForm>
    </>
  );
};

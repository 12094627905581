import { TagRenderer, TextCell } from '@med-fe/ui';
import { ExceptionCategoryEnum } from 'apps/pace/src/models/exception-catergory-enum';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const exceptionManagementColumns = [
  {
    title: 'Employee Name',
    dataIndex: 'employeeName',
    key: 'exceptionEmployeeName',
    width: 250,
    sorter: (a: { employeeName: string }, b: { employeeName: any }) => a.employeeName?.localeCompare(b.employeeName),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Employee ID',
    dataIndex: 'employeeID',
    key: 'employeeID',
    width: 120,
    align: 'center',
    sorter: (a: { employeeID: any }, b: { employeeID: any }) => a.employeeID - b.employeeID,
    sortDirections: ['ascend', 'descend'],
    render: (value) => <TextCell value={value} strong />,
  },
  {
    title: 'Office Number',
    dataIndex: 'officeNumber',
    key: 'officeNumber',
    width: 120,
    sorter: (a: { officeNumber: any }, b: { officeNumber: any }) => a.officeNumber?.localeCompare(b.officeNumber),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Office Name',
    dataIndex: 'officeName',
    key: 'officeName',
    width: 120,
    sorter: (a: { officeName: string }, b: { officeName: any }) => a.officeName?.localeCompare(b.officeName),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Exception Category',
    dataIndex: 'exceptionCategory',
    key: 'exceptionCategory',
    width: 180,
    align: 'center',
    sorter: (a: { exceptionCategory: string }, b: { exceptionCategory: any }) =>
      a.exceptionCategory?.localeCompare(b.exceptionCategory),
    sortDirections: ['ascend', 'descend'],
    render: (value) => <TagRenderer label={ExceptionCategoryEnum[value]} value={value} closable={false} onClose />,
  },
  {
    title: 'Effective Date',
    dataIndex: 'effectiveDate',
    key: 'effectiveDate',
    width: 140,
    align: 'center',
    sorter: (a, b) => moment(a.effectiveDate || 0).unix() - moment(b.effectiveDate || 0).unix(),
    sortDirections: ['ascend', 'descend'],
    ellipsis: true,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    width: 140,
    align: 'center',
    sorter: (a, b) => moment(a.endDate || 0).unix() - moment(b.endDate || 0).unix(),
    sortDirections: ['ascend', 'descend'],
    ellipsis: true,
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 250,
    align: 'center',
    render: (value) => <TextCell value={value} strong />,
  },
  {}, // workaround to align columns
];

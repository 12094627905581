import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedReport } from '../reports-selector';
import { setSelectedReport } from '../reports-actions';
import { ReportName } from '../models/report-name.enum';

export const ReportsList = () => {
  const selectedReport = useSelector(getSelectedReport);
  const dispatch = useDispatch();

  return (
    <StyledList
      header={<ReportListHeader>Report</ReportListHeader>}
      bordered
      dataSource={Object.values(ReportName)}
      renderItem={(name) => (
        <ReportItem className={selectedReport === name ? 'selected' : ''} onClick={() => {
          dispatch(setSelectedReport(name));
        }}>
          {name}
        </ReportItem>
      )}
    />
  );
};

const StyledList = styled(List)`
  min-width: 300px;
  box-shadow: -2px 2px 8px #f0f1f2;
  border-left: none;
  border-bottom: none;
  border-top: none;
  border-right-color: #f0f1f2;

  .ant-list-header {
    padding: 0;
  }
`;

const ReportListHeader = styled.div`
  padding: 12px 24px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 2px 8px #f0f1f2;
`;

const ReportItem = styled(List.Item)`
  cursor: pointer;

  &.selected {
    background-color: rgba(77, 101, 136, 0.5);
    background-color: #40668C;
    color: #fff;
  }
`;

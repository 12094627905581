import { useEffect, useMemo, useCallback, useState } from 'react';
import { Select } from 'antd';
import { searchElements } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDistricts, fetchRegions, setReasonSearchSpecialRanges } from '../../pace/pace-actions';
import { isEmpty, last, reduce, split, values } from 'lodash';
import { getDistricts, getRegions } from '../../pace/pace-selectors';

const { Option }: any = Select;

export const useSpecialRanges = (activeTab, isAllSelected) => {
  const initialValue = ['No Lunch Shifts,isNoLunchShifts'];
  const dispatch = useDispatch();
  const [regionIds, setRegionIds] = useState<any[]>([]);
  const regions = useSelector(getRegions);
  const [districtIds, setDistrictIds] = useState<any[]>([]);
  const districts = useSelector(getDistricts);
  const [currentValue, setCurrentValue] = useState<string[]>(initialValue);
  const getValueToDispatch = useCallback(
    (value) => reduce(value, (acc, o) => ({ ...acc, [split(o, ',')[1]]: true }), {}),
    []
  );
  const resetedValuesToDispatch = useMemo(
    () => reduce(values(searchElements), (acc, it) => ({ ...acc, [it]: false }), {}),
    []
  );

  useEffect(() => {
    if (activeTab !== 'specialRanges') {
      setCurrentValue(initialValue);
    } else {
      if (!isAllSelected && activeTab === 'specialRanges') {
        setCurrentValue(initialValue);
      } else {
        setCurrentValue([]);
      }
      dispatch(fetchRegions());
    }
    // eslint-disable-next-line
  }, [activeTab, isAllSelected]);

  const options = useMemo(
    () => Object.entries(searchElements).map(([key, value]) => <Option key={[key, value]}>{key}</Option>),
    []
  );

  useEffect(() => {
    const val =
      Object.keys(getValueToDispatch(currentValue))?.length === 0
        ? { isNoLunchShifts: false }
        : getValueToDispatch(currentValue);
    const obj = { ...resetedValuesToDispatch, ...val };
    dispatch(
      setReasonSearchSpecialRanges({
        ...obj,
        regionIds: regionIds.map((value) => value.id),
        districtIds: districtIds.map((value) => value.id),
      })
    );
    // eslint-disable-next-line
  }, [currentValue, regionIds, districtIds]);

  const handleChange = useCallback((value: Array<string>) => {
    if (isEmpty(value)) setCurrentValue(initialValue);
    else setCurrentValue(value);
    // eslint-disable-next-line
  }, []);

  const filterDistricts = (regionIds) => {
    let districtIdsFiltered = districtIds.filter((district) =>
      regionIds
        .map((region) => region.value.split('(')[0].trim())
        .includes(district.value.substring(district.value.indexOf('(') + 1, district.value.lastIndexOf(')')))
    );
    setDistrictIds(districtIdsFiltered);
    return districtIdsFiltered;
  };

  useEffect(() => {
    if (regionIds.length) {
      dispatch(fetchDistricts(regionIds.map((value) => value.id)));
    } else {
      dispatch(fetchDistricts());
    }
  }, [regionIds]);

  return {
    options,
    handleChange,
    currentValue,
    regionIds,
    setRegionIds,
    regions,
    districts,
    setDistrictIds,
    filterDistricts,
    isAllSelected,
  };
};

import React from 'react';
import styled from 'styled-components';
import { ReportsList } from './details/ReportsList';
import { ReportFilters } from './details/ReportFilters';

export const ReportsManagement = () => {
  return (
    <>
      <ReportsWrapper>
        <ReportsList />
        <ReportFilters />
      </ReportsWrapper>
      <StyledLogoWrapper>
        <img alt='logo' src={'./images/logo.svg'} />
      </StyledLogoWrapper>
    </>
  );
}


const ReportsWrapper = styled.div`
  display: flex;
  min-height: 680px;
  margin-right: 15px;
  background-color: #fff;
  border-radius: 2px;
`;


const StyledLogoWrapper = styled.div`
  padding: 40px 40px 20px;
  display: flex;
  justify-content: flex-end;
`;

export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT';
export const EXPORT_REPORT = 'EXPORT_REPORT';
export const EXPORT_REPORT_SUCCESS = 'EXPORT_REPORT_SUCCESS';
export const EXPORT_REPORT_FAILED = 'EXPORT_REPORT_FAILED';

export const setSelectedReport = (payload) => ({
  type: SET_SELECTED_REPORT,
  payload,
});

export const exportReportFile = (payload) => ({
  type: EXPORT_REPORT,
  payload,
});

export const exportReportFileSuccess = (payload) => ({
  type: EXPORT_REPORT_SUCCESS,
  payload,
});

export const exportReportFileFailed = (payload) => ({
  type: EXPORT_REPORT_FAILED,
  payload,
});

import styles from '../../BaseGrid.module.scss';
import { Input, Menu, Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTemplateByIdSingleDay } from '../../../../pace/pace-selectors';
import { getIsSameOrLaterThanToday } from '../../../../common/helpers/date';
import { setSelectedTemplate } from '../../../../pace/pace-actions';

const DescriptionColumn = ({ id, data, isWholeWeek }) => {
  const dispatch = useDispatch();
  const selectedTemplate = useSelector((state) => getSelectedTemplateByIdSingleDay(state, id, data.date));

  const [employeeId, office] = id.split('_');

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectedTemplate?.description) {
      setInputValue(selectedTemplate.description || selectedTemplate.children);
    } else {
      setInputValue('');
    }
  }, [selectedTemplate]);

  const descriptionOverlay = () => {
    return (
      <Menu>
        <Menu.Item key={`${id}-${data.date}`} className={styles['description-input-menu']}>
          {inputValue}
        </Menu.Item>
      </Menu>
    );
  };

  const onBlur = () => {
    dispatch(
      setSelectedTemplate({
        employeeId,
        office,
        date: data.date,
        option: { ...selectedTemplate, description: inputValue },
        isWholeWeek,
      })
    );
  };

  return (
    <Dropdown
      disabled={!selectedTemplate?.children || !getIsSameOrLaterThanToday(data.date)}
      overlay={descriptionOverlay()}
      placement='bottomLeft'
    >
      <StyledInput
        size='small'
        maxLength={255}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        className={styles['description-input']}
        onBlur={onBlur}
        $isEmptyValue={!!selectedTemplate && !inputValue}
      />
    </Dropdown>
  );
};

export default DescriptionColumn;

const StyledInput = styled(Input)`
  border: ${({ $isEmptyValue }) => $isEmptyValue && '1px solid red'};
`;

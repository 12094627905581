import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSearch } from '../../templates/templates-selector';
import { isEmpty, omitBy } from 'lodash';
import { setSearchTemplates } from '../../templates/templates-actions';

export const SearchTemplates = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const search = useSelector(getSearch);

  const onSubmit = ({ pace, examShiftLength, shiftLength, ...rest }) =>
    dispatch(
      setSearchTemplates({
        page: 0,
        size: search?.size,
        ...rest,
        ...(!isEmpty(omitBy(pace, isEmpty)) ? { pace: omitBy(pace, isEmpty) } : null),
        ...(!isEmpty(omitBy(shiftLength, isEmpty)) ? { shiftLength: omitBy(shiftLength, isEmpty) } : null),
        ...(!isEmpty(omitBy(examShiftLength, isEmpty)) ? { examShiftLength: omitBy(examShiftLength, isEmpty) } : null),
      })
    );

  useEffect(() => {
    form.setFieldsValue(search);
  }, [search]);

  return (
    <div className='search-container'>
      <Form
        //className='search-container'
        layout='vertical'
        form={form}
        initialValues={{ templateName: '', assigned: false }}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.Item label='Template Search' name='templateName' className='label-text input-field'>
          <Input maxLength={32} allowClear placeholder='Pattern Name' />
        </Form.Item>
        <Form.Item label='Search by Pace'>
          <Form.Item name={['pace', 'from']} className='label-text input-field-short input-field-short_margin'>
            <Input placeholder='From' allowClear type='number' step={0.1} min={0} max={30} />
          </Form.Item>
          <Form.Item name={['pace', 'to']} className='label-text input-field-short'>
            <Input placeholder='To' allowClear type='number' step={0.1} min={0} max={30} />
          </Form.Item>
        </Form.Item>
        <Form.Item label='Search by Total Exam Duration'>
          <Form.Item
            name={['examShiftLength', 'from']}
            className='label-text input-field-short input-field-short_margin'
          >
            <Input placeholder='From' allowClear type='number' min={0} max={24} />
          </Form.Item>
          <Form.Item name={['examShiftLength', 'to']} className='label-text input-field-short'>
            <Input placeholder='To' allowClear type='number' min={0} max={24} />
          </Form.Item>
        </Form.Item>
        <Form.Item label='Search by Template Duration'>
          <Form.Item name={['shiftLength', 'from']} className='label-text input-field-short input-field-short_margin'>
            <Input placeholder='From' allowClear type='number' min={0} max={24} />
          </Form.Item>
          <Form.Item name={['shiftLength', 'to']} className='label-text input-field-short'>
            <Input placeholder='To' allowClear type='number' min={0} max={24} />
          </Form.Item>
        </Form.Item>
        <Form.Item name='assigned' valuePropName='checked'>
          <Checkbox style={{ color: 'white' }}>Template assigned</Checkbox>
        </Form.Item>
        <Form.Item className='search-btn'>
          <Button type='primary' htmlType='submit'>
            SEARCH
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

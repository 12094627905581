import React from 'react';
import { unmappedOds_columns } from './unmappedOds';

export const unmapped_ods_hoo_changed_columns = [
  ...unmappedOds_columns
    .filter(({ key }) => !!key)
    .map((item) => ({
      ...item,
      ...(item.key === 'officeHours' && {
        title: 'Changed Office HOO',
      }),
    })),
  {
    title: 'Effective Date',
    dataIndex: 'officeFutureStartDate',
    key: 'officeFutureStartDate',
    width: 140,
  },
  {
    ellipsis: true,
  }, // empty column, workaround to have column width fixed
];

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEditedExam } from '../../templates-selector';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { setEditedExam } from '../../templates-actions';
import { getTemplateEventColor } from '../../../../common/helpers/common';
import { ConfigTypes } from '../../models/config-types.enum';
import { Examination } from './configurations/Examination';
import { Lunch } from './configurations/Lunch';

const { Title } = Typography;

export const Configuration = () => {
  const dispatch = useDispatch();
  const editedExam = useSelector(getEditedExam);
  const [examName, setExamName] = useState('');

  const getConfiguration = (name) => {
    switch (name) {
      case ConfigTypes.ExamCl:
      case ConfigTypes.TExam:
      case ConfigTypes.TExamCL:
      case ConfigTypes.Flex:
      case ConfigTypes.RcOv:
        return <Examination data={editedExam} />;
      case ConfigTypes.Lunch:
        return <Lunch data={editedExam} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (editedExam) {
      setExamName(editedExam.examName.toLowerCase());
    }
  }, [editedExam]);

  return (
    <StyledConfigWrapper title={editedExam?.examName}>
      <StyledHeader>
        <Title level={5}>{`${editedExam.examName} configuration`.toUpperCase()} </Title>
        <CloseButton type={'link'} onClick={() => dispatch(setEditedExam(null))}>
          <img src={'./icons/close-thin.svg'} alt='close' />
        </CloseButton>
      </StyledHeader>
      {getConfiguration(examName)}
    </StyledConfigWrapper>
  );
};

const StyledConfigWrapper = styled.div`
  padding: 15px 20px;
  border: 3px solid ${({ title }) => getTemplateEventColor(title)};
  border-radius: 3px;
  min-height: 420px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const CloseButton = styled(Button)`
  padding: 0;
  line-height: 0;
  height: 20px;
`;

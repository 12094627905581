import React from 'react';
import './WeekPatternCell.scss'

export const WeekPatternCell = ({ pattern }) => {
  return (
    <div className='week-pattern-cell'>
      {pattern.map((isDayActive, index) => (
        <div className={`week-pattern-cell__day week-pattern-cell__day_${isDayActive ? 'active' : 'inactive'}`} key={String(index)}>
          {index === 0 && "M"}
          {index === 1 && "T"}
          {index === 2 && "W"}
          {index === 3 && "T"}
          {index === 4 && "F"}
          {index === 5 && "S"}
          {index === 6 && "S"}
        </div>
      ))}
    </div>
  )
}
import { Tooltip } from 'antd';
import { Button } from 'antd';
import React from 'react';

export const EditActionCell = ({ onClickHandler, text, status }: any) => {
  const statusClassName = () => {
    switch (status) {
      case 'ACTIVE':
        return 'green-status';
      case 'INACTIVE':
      case 'CLOSED':
        return 'red-status';
      case 'HOLD':
        return 'orange-status';
      default:
        return;
    }
  };

  return (
    <Tooltip placement='top' title={text}>
      <Button type={'link'} className='edit-cell' onClick={onClickHandler}>
        <span className={`link-cell-text ${statusClassName()}`}>{text}</span>
      </Button>
    </Tooltip>
  );
};

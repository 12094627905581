export const CHANGE_PATTERN_CONFIG = {
  eeid: {
    label: 'EEID',
    allowClear: true,
    placeholder: 'Enter text',
  },
  odName: {
    label: 'OD Name',
    allowClear: true,
    placeholder: 'Enter text',
  },
  officeNumber: {
    label: 'Office Number',
    allowClear: true,
    placeholder: 'Enter text',
  },
  previousPattern: {
    label: 'Previous Pattern',
    allowClear: true,
    placeholder: 'Enter text',
  },
  currentPattern: {
    label: 'Current Pattern',
    allowClear: true,
    placeholder: 'Enter text',
  },
  paceFrom: {
    label: 'Pace From',
    allowClear: true,
    placeholder: 'Enter number',
    inputType: 'number',
    min: 0,
    max: 30,
    step: 0.1,
  },
  paceTo: {
    label: 'Pace To',
    allowClear: true,
    placeholder: 'Enter number',
    inputType: 'number',
    min: 0,
    max: 30,
    step: 0.1,
  },
  dateRange: {
    label: 'Date Updated',
    allowClear: true,
    type: 'daterange',
  },
};

import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { ReactComponent as EditIcon } from './../../../assets/icons/edit.svg';
import { ReactComponent as CloseIcon } from './../../../assets/icons/close.svg';

type TProps = {
  isFirst?: boolean;
  office: any;
  officeName: any;
  isEditing?: boolean;
  onEditLocation?: any;
  isSpecialRanges?: boolean;
  isFirstOffice?: boolean;
};

export const OfficeHeadCell: React.FC<TProps> = ({
  isFirst = false,
  office,
  officeName,
  isSpecialRanges = false,
  isEditing = false,
  onEditLocation,
}) => {
  return (
    <Wrap isEditing={isEditing} isSpecialRanges={isSpecialRanges}>
      <StyledDiv>
        {
          <>
            <StyledSpan isSpecialRanges={isSpecialRanges}>{office}</StyledSpan>
            {isFirst && (
              <StyledButton onClick={() => onEditLocation(isEditing ? null : office)}>
                {isEditing ? <CloseIcon /> : <EditIcon />}
              </StyledButton>
            )}
          </>
        }
      </StyledDiv>
      <StyledSpan isSpecialRanges={isSpecialRanges}>{officeName}</StyledSpan>
    </Wrap>
  );
};

const Wrap = styled.div`
  background-color: ${(p) => !p.isSpecialRanges && (p.isEditing ? '#ADADAD80' : '#F6F6F6')};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
`;

const StyledSpan = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: ${(p) => (p.isSpecialRanges ? '15px' : '8px')};
`;

const StyledButton = styled(Button)`
  & svg {
    & path {
      fill: ${(p) => (p.disabled ? '#ADADAD' : '#40668C')};
    }
  }
  border: none;
  background: transparent !important;
  padding: 0;
  height: 20px;
  display: flex;
  align-items: center;
`;

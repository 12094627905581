import React from 'react';
import { createRoot } from 'react-dom/client';
import './antd.less';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import axios, { AxiosRequestConfig } from 'axios';
import { AuthService, msalInstance } from '@med-fe/data';
import { api } from '@med-fe/util';
import store from './store/store';
import { Provider } from 'react-redux';
import { get } from 'lodash';

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    api.post('/audit/login', {}).finally();
  }
});

axios.interceptors.request.use(
  async (config: any) => {
    const token = await AuthService.refreshAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject('Network Error');
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const token = await AuthService.refreshAccessToken();

      if (token) {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
      }

      return axios(originalRequest);
    } else {
      return Promise.reject(get(error, 'response.data', error));
    }
  }
);

createRoot(document.getElementById('root') as Element).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { Button } from 'antd';
import styles from '../BaseGrid.module.scss';

export const SpecialRangesEditBtn = ({ onClick }) => {
  return (
    <Button
      className={styles['add-btn']}
      onClick={onClick}
    >
      <img src={'./icons/action-edit.svg'} alt='edit' />
    </Button>
  )
}

import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { StyledGearButton } from './styled';
import { RampsDrawer } from './ramps/RampsDrawer';

export const Adjustment = () => {
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);

  return (
    <>
      <Tooltip
        title='Ramps Settings'
        color={'white'}
        overlayInnerStyle={{ color: '#003366' }}
        overlayStyle={{ fontSize: '12px' }}
      >
        <StyledGearButton
          size={'small'}
          type='primary'
          shape='circle'
          icon={<SettingOutlined />}
          onClick={() => setIsVisibleDrawer(true)}
        />
      </Tooltip>
      {isVisibleDrawer && <RampsDrawer visible={isVisibleDrawer} onClose={() => setIsVisibleDrawer(false)} />}
    </>
  );
};

import { getDayIndex } from '../../../common/helpers/date';
import { isNil } from 'lodash';
import { fetchTemplates } from '../../../pace/pace-actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const useTemplateScroll = ({ templatesLoading, office, dayOfWeek, setDayOfWeek }) => {
  const dispatch = useDispatch();

  const [templateSearchPayload, setTemplateSearchPayload] = useState({ page: 0, name: '' });

  const onTemplatesScroll = (event, day) => {
    const target = event.target || event.currentTarget;
    const isLoading = !!templatesLoading && templatesLoading.dayOfWeek === getDayIndex(day);

    if (
      Math.round(target.scrollTop) + Math.round(target.offsetHeight) >= Math.round(target.scrollHeight - 10) &&
      !isNil(dayOfWeek) &&
      !isLoading
    ) {
      setTemplateSearchPayload({ ...templateSearchPayload, page: templateSearchPayload.page + 1 });
      dispatch(
        fetchTemplates({ dayOfWeek, office, page: templateSearchPayload.page + 1, name: templateSearchPayload.name })
      );
    }
  };

  const onSelectSearch = (name) => {
    if (name && name.length >= 3) {
      if (!isNil(dayOfWeek)) {
        setTemplateSearchPayload({ ...templateSearchPayload, name });
        dispatch(fetchTemplates({ dayOfWeek, name, office }));
      }
    }
    if (name === '') {
      if (!isNil(dayOfWeek)) {
        dispatch(fetchTemplates({ dayOfWeek, office }));
        setTemplateSearchPayload({ ...templateSearchPayload, name });
      }
    }
  };

  const onSelectBlur = () => {
    setDayOfWeek(null);
    setTemplateSearchPayload({ name: '', page: 0 });
  };

  return { onTemplatesScroll, onSelectSearch, onSelectBlur };
};

export default useTemplateScroll;

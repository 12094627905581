import { AuditAreaCell, AuditTypeCell, AuditValueCell } from '@med-fe/ui';

export const audit_columns = [
  {
    title: 'action type',
    dataIndex: 'action',
    key: 'actionType',
    width: 170,
    ellipsis: true,
    render: (value: string) => <AuditTypeCell value={value} />,
  },
  {
    title: 'user',
    dataIndex: 'user',
    key: 'user',
    width: 124,
    ellipsis: true,
  },
  {
    title: 'date and time',
    dataIndex: 'date',
    key: 'date',
    width: 160,
    ellipsis: true,
  },
  {
    title: 'Doctor Name',
    dataIndex: 'doctorName',
    key: 'doctorName',
    width: 130,
    ellipsis: true,
  },
  {
    title: 'location',
    dataIndex: 'location',
    key: 'location',
    ellipsis: true,
    width: 100,
  },
  {
    title: 'Template Type',
    dataIndex: 'area',
    key: 'area',
    width: 130,
    ellipsis: true,
    render: (value) => <AuditAreaCell value={value} />,
  },
  {
    title: 'Assign Day',
    dataIndex: 'assignDay',
    key: 'assignDay',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'from',
    dataIndex: 'from',
    key: 'from',
    ellipsis: true,
    width: 190,
    render: (value: string) => <AuditValueCell value={value} />,
  },
  {
    title: 'to',
    dataIndex: 'to',
    key: 'to',
    ellipsis: true,
    width: 190,
    render: (value: string) => <AuditValueCell value={value} />,
  },
  {
    title: 'Special Ranges Reason',
    dataIndex: 'reasons',
    key: 'reasons',
    ellipsis: true,
    width: 190,
  },
  {}, // empty column, workaround to have column width fixed
];

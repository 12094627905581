export const FETCH_LOCATIONS = `FETCH_LOCATIONS`;
export const FETCH_LOCATIONS_SUCCESS = `FETCH_LOCATIONS_SUCCESS`;
export const FETCH_LOCATIONS_FAILED = `FETCH_LOCATIONS_FAILED`;
export const CREATE_LOCATION = `CREATE_LOCATION`;
export const CREATE_LOCATION_SUCCESS = `CREATE_LOCATION_SUCCESS`;
export const CREATE_LOCATION_FAILED = `CREATE_LOCATION_FAILED`;
export const DELETE_LOCATION = `DELETE_LOCATION`;
export const DELETE_LOCATION_SUCCESS = `DELETE_LOCATION_SUCCESS`;
export const DELETE_LOCATION_FAILED = `DELETE_LOCATION_FAILED`;

export const FETCH_REGIONS = `FETCH_REGIONS`;
export const FETCH_REGIONS_SUCCESS = `FETCH_REGIONS_SUCCESS`;
export const FETCH_DISTRICTS = `FETCH_DISTRICTS`;
export const FETCH_DISTRICTS_SUCCESS = `FETCH_DISTRICTS_SUCCESS`;

export const SET_SEARCH_LOCATIONS = 'SET_SEARCH_LOCATIONS';
export const SET_SEARCH_DOCTORS = 'SET_SEARCH_DOCTORS';
export const SET_REASON_SEARCH_SPECIAL_RANGES = 'SET_REASON_SEARCH_SPECIAL_RANGES';
export const SET_SEARCH_SPECIAL_RANGES = 'SET_SEARCH_SPECIAL_RANGES';

export const FETCH_LOCATION_ASSIGNMENT_COUNT = 'FETCH_LOCATION_ASSIGNMENT_COUNT';
export const FETCH_LOCATION_ASSIGNMENT_COUNT_SUCCESS = 'FETCH_LOCATION_ASSIGNMENT_COUNT_SUCCESS';
export const FETCH_LOCATION_ASSIGNMENT_COUNT_FAIL = 'FETCH_LOCATION_ASSIGNMENT_COUNT_FAIL';

export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAIL = 'UPDATE_CONTACT_FAIL';

export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAIL = 'FETCH_CONTACTS_FAIL';

export const SET_SEARCH_REGION_ID = 'SET_SEARCH_REGION_ID';

export const CREATE_REGION = `CREATE_REGION`;
export const CREATE_REGION_SUCCESS = `CREATE_REGION_SUCCESS`;
export const CREATE_REGION_FAILED = `CREATE_REGION_FAILED`;

export const CREATE_DISTRICT = `CREATE_DISTRICT`;
export const CREATE_DISTRICT_SUCCESS = `CREATE_DISTRICT_SUCCESS`;
export const CREATE_DISTRICT_FAILED = `CREATE_DISTRICT_FAILED`;

export const CREATE_BILLING = 'CREATE_BILLING';
export const CREATE_BILLING_SUCCESS = 'CREATE_BILLING_SUCCESS';
export const CREATE_BILLING_FAILED = 'CREATE_BILLING_FAILED';

export const SET_SELECTED_STAFF = 'SET_SELECTED_STAFF';

export const FETCH_DOCTORS = 'FETCH_DOCTORS';
export const FETCH_DOCTORS_SUCCESS = 'FETCH_DOCTORS_SUCCESS';
export const FETCH_DOCTORS_FAILED = 'FETCH_DOCTORS_FAILED';
export const FETCH_DOCTORS_SPECIAL = 'FETCH_DOCTORS_SPECIAL';
export const FETCH_DOCTORS_SPECIAL_SUCCESS = 'FETCH_DOCTORS_SPECIAL_SUCCESS';
export const FETCH_DOCTORS_SPECIAL_FAILED = 'FETCH_DOCTORS_SPECIAL_FAILED';
export const FETCH_OD_DATA_INCOMPLETE = 'FETCH_OD_DATA_INCOMPLETE';
export const FETCH_OD_DATA_INCOMPLETE_SUCCESS = 'FETCH_OD_DATA_INCOMPLETE_SUCCESS';
export const FETCH_OD_DATA_INCOMPLETE_FAILED = 'FETCH_OD_DATA_INCOMPLETE_FAILED';
export const SET_SELECTED_ROW = 'SET_SELECTED_ROW';
export const SET_SELECTED_DOCTOR = 'SET_SELECTED_DOCTOR';
export const FETCH_SELECTED_DOCTOR = 'FETCH_SELECTED_DOCTOR';
export const FETCH_SELECTED_DOCTOR_SUCCESS = 'FETCH_SELECTED_DOCTOR_SUCCESS';
export const FETCH_SELECTED_DOCTOR_FAILED = 'FETCH_SELECTED_DOCTOR_FAILED';
export const FETCH_DOCTOR_AVAILABILITY = 'FETCH_DOCTOR_AVAILABILITY';
export const FETCH_DOCTOR_AVAILABILITY_SUCCESS = 'FETCH_DOCTOR_AVAILABILITY_SUCCESS';
export const FETCH_DOCTOR_AVAILABILITY_FAILED = 'FETCH_DOCTOR_AVAILABILITY_FAILED';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILED = 'FETCH_TEMPLATES_FAILED';
export const FETCH_ALL_TEMPLATES = 'FETCH_ALL_TEMPLATES';
export const FETCH_ALL_TEMPLATES_SUCCESS = 'FETCH_ALL_TEMPLATES_SUCCESS';
export const FETCH_ALL_TEMPLATES_FAILED = 'FETCH_ALL_TEMPLATES_FAILED';
export const ASSIGN_TEMPLATE = 'ASSIGN_TEMPLATE';
export const ASSIGN_TEMPLATE_SUCCESS = 'ASSIGN_TEMPLATE_SUCCESS';
export const ASSIGN_TEMPLATE_FAILED = 'ASSIGN_TEMPLATE_FAILED';
export const BULK_ASSIGN_TEMPLATE = 'BULK_ASSIGN_TEMPLATE';
export const BULK_ASSIGN_TEMPLATE_SUCCESS = 'BULK_ASSIGN_TEMPLATE_SUCCESS';
export const BULK_ASSIGN_TEMPLATE_FAILED = 'BULK_ASSIGN_TEMPLATE_FAILED';
export const SET_SELECTED_WEEK_RANGE = 'SET_SELECTED_WEEK_RANGE';
export const FETCH_SELECTED_DOCTOR_SPECIAL = 'FETCH_SELECTED_DOCTOR_SPECIAL';
export const FETCH_SELECTED_DOCTOR_SPECIAL_SUCCESS = 'FETCH_SELECTED_DOCTOR_SPECIAL_SUCCESS';
export const FETCH_SELECTED_DOCTOR_SPECIAL_FAILED = 'FETCH_SELECTED_DOCTOR_SPECIAL_FAILED';
export const FETCH_SELECTED_DOCTORS_SPECIAL = 'FETCH_SELECTED_DOCTORS_SPECIAL';
export const FETCH_SELECTED_DOCTORS_SPECIAL_SUCCESS = 'FETCH_SELECTED_DOCTORS_SPECIAL_SUCCESS';
export const FETCH_SELECTED_DOCTORS_SPECIAL_FAILED = 'FETCH_SELECTED_DOCTORS_SPECIAL_FAILED';
export const FETCH_SELECTED_DOCTORS_SPECIAL_ALL = 'FETCH_SELECTED_DOCTORS_SPECIAL_ALL';
export const FETCH_SELECTED_DOCTORS_SPECIAL_ALL_SUCCESS = 'FETCH_SELECTED_DOCTORS_SPECIAL_ALL_SUCCESS';
export const FETCH_SELECTED_DOCTORS_SPECIAL_ALL_FAILED = 'FETCH_SELECTED_DOCTORS_SPECIAL_ALL_FAILED';
export const ASSIGN_TEMPLATE_SPECIAL = 'ASSIGN_TEMPLATE_SPECIAL';
export const ASSIGN_TEMPLATE_SPECIAL_SUCCESS = 'ASSIGN_TEMPLATE_SPECIAL_SUCCESS';
export const ASSIGN_TEMPLATE_SPECIAL_FAILED = 'ASSIGN_TEMPLATE_SPECIAL_FAILED';
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const BULK_ASSIGN_TEMPLATE_SPECIAL = 'BULK_ASSIGN_TEMPLATE_SPECIAL';
export const BULK_ASSIGN_TEMPLATE_SPECIAL_SUCCESS = 'BULK_ASSIGN_TEMPLATE_SPECIAL_SUCCESS';
export const BULK_ASSIGN_TEMPLATE_SPECIAL_FAILED = 'BULK_ASSIGN_TEMPLATE_SPECIAL_FAILED';
export const BULK_ASSIGN_ACTIVITY = 'BULK_ASSIGN_ACTIVITY';
export const BULK_ASSIGN_ACTIVITY_SUCCESS = 'BULK_ASSIGN_ACTIVITY_SUCCESS';
export const BULK_ASSIGN_ACTIVITY_FAILED = 'BULK_ASSIGN_ACTIVITY_FAILED';

export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_SELECTED_TEMPLATES = 'SET_SELECTED_TEMPLATES';
export const SET_INITIAL_SELECTED_TEMPLATES = 'SET_INITIAL_SELECTED_TEMPLATES';
export const CLEAR_ALL_DOCTORS_TEMPLATES = 'CLEAR_ALL_DOCTORS_TEMPLATES';
export const CLEAR_DOCTOR_WEEK_TEMPLATES = 'CLEAR_DOCTOR_WEEK_TEMPLATES';
export const REMOVE_ALL_DOCTORS_TEMPLATES = 'REMOVE_ALL_DOCTORS_TEMPLATES';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const FETCH_DOCTORS_HOO_CHANGED = 'FETCH_DOCTORS_HOO_CHANGED';
export const FETCH_DOCTORS_HOO_CHANGED_SUCCESS = 'FETCH_DOCTORS_HOO_CHANGED_SUCCESS';
export const FETCH_DOCTORS_HOO_CHANGED_FAILED = 'FETCH_DOCTORS_HOO_CHANGED_FAILED';

export const SET_IS_HOO_CHANGED_ACTION = 'SET_IS_HOO_CHANGED_ACTION';

export const setSelectedTemplate = (payload) => ({ type: SET_SELECTED_TEMPLATE, payload });

export const setSelectedTemplates = (payload) => ({ type: SET_SELECTED_TEMPLATES, payload });

export const setInitialSelectedTemplates = (payload) => ({ type: SET_INITIAL_SELECTED_TEMPLATES, payload });

export const clearAllDoctorsTemplates = () => ({ type: CLEAR_ALL_DOCTORS_TEMPLATES });

export const clearDoctorWeekTemplates = (payload) => ({ type: CLEAR_DOCTOR_WEEK_TEMPLATES, payload });

export const removeAllDoctorsTemplates = () => ({ type: REMOVE_ALL_DOCTORS_TEMPLATES });

export const fetchDoctors = (payload) => ({ type: FETCH_DOCTORS, payload });
export const fetchDoctorsSuccess = (payload) => ({ type: FETCH_DOCTORS_SUCCESS, payload });

export const fetchDoctorsSpecial = (payload) => ({ type: FETCH_DOCTORS_SPECIAL, payload });
export const fetchDoctorsSpecialSuccess = (payload) => ({ type: FETCH_DOCTORS_SPECIAL_SUCCESS, payload });
export const fetchDoctorsSpecialFailed = (payload) => ({ type: FETCH_DOCTORS_SPECIAL_FAILED, payload });

export const fetchSelectedDoctorSpecial = (payload) => ({ type: FETCH_SELECTED_DOCTOR_SPECIAL, payload });
export const fetchSelectedDoctorSpecialSuccess = (payload) => ({
  type: FETCH_SELECTED_DOCTOR_SPECIAL_SUCCESS,
  payload,
});

export const fetchSelectedDoctorsSpecial = (payload) => ({ type: FETCH_SELECTED_DOCTORS_SPECIAL, payload });
export const fetchSelectedDoctorsSpecialSuccess = (payload) => ({
  type: FETCH_SELECTED_DOCTORS_SPECIAL_SUCCESS,
  payload,
});
export const fetchSelectedDoctorsSpecialFailed = (payload) => ({
  type: FETCH_SELECTED_DOCTORS_SPECIAL_FAILED,
  payload,
});

export const fetchSelectedDoctorsSpecialAll = (payload) => ({ type: FETCH_SELECTED_DOCTORS_SPECIAL_ALL, payload });
export const fetchSelectedDoctorsSpecialAllSuccess = (payload) => ({
  type: FETCH_SELECTED_DOCTORS_SPECIAL_ALL_SUCCESS,
  payload,
});
export const fetchSelectedDoctorsSpecialAllFailed = (payload) => ({
  type: FETCH_SELECTED_DOCTORS_SPECIAL_ALL_FAILED,
  payload,
});

export const fetchOdDataIncomplete = (payload) => ({ type: FETCH_OD_DATA_INCOMPLETE, payload });
export const fetchOdDataIncompleteSuccess = (payload) => ({ type: FETCH_OD_DATA_INCOMPLETE_SUCCESS, payload });
export const fetchOdDataIncompleteFailed = (payload) => ({ type: FETCH_OD_DATA_INCOMPLETE_FAILED, payload });

export const setSearchDoctors = (payload) => ({ type: SET_SEARCH_DOCTORS, payload });

export const setSelectedRow = (payload) => ({ type: SET_SELECTED_ROW, payload });

export const setReasonSearchSpecialRanges = (payload) => ({ type: SET_REASON_SEARCH_SPECIAL_RANGES, payload });

export const setSearchSpecialRanges = (payload) => ({ type: SET_SEARCH_SPECIAL_RANGES, payload });

export const setSelectedDoctor = (payload) => ({ type: SET_SELECTED_DOCTOR, payload });

export const fetchSelectedDoctor = (payload) => ({ type: FETCH_SELECTED_DOCTOR, payload });
export const fetchSelectedDoctorSuccess = (payload) => ({ type: FETCH_SELECTED_DOCTOR_SUCCESS, payload });

export const fetchDoctorAvailability = (payload) => ({ type: FETCH_DOCTOR_AVAILABILITY, payload });
export const fetchDoctorAvailabilitySuccess = (payload) => ({ type: FETCH_DOCTOR_AVAILABILITY_SUCCESS, payload });
export const fetchDoctorAvailabilityFailed = (payload) => ({ type: FETCH_DOCTOR_AVAILABILITY_FAILED, payload });

export const fetchTemplates = (payload) => ({ type: FETCH_TEMPLATES, payload });
export const fetchTemplatesSuccess = (payload) => ({ type: FETCH_TEMPLATES_SUCCESS, payload });

export const fetchAllTemplates = () => ({ type: FETCH_ALL_TEMPLATES });
export const fetchAllTemplatesSuccess = (payload) => ({ type: FETCH_ALL_TEMPLATES_SUCCESS, payload });
export const fetchAllTemplatesFailed = (payload) => ({ type: FETCH_ALL_TEMPLATES_FAILED, payload });

export const assignTemplateSuccess = (payload) => ({ type: ASSIGN_TEMPLATE_SUCCESS, payload });
export const assignTemplateFailed = (payload) => ({ type: ASSIGN_TEMPLATE_FAILED, payload });

export const bulkAssignTemplate = (payload) => ({ type: BULK_ASSIGN_TEMPLATE, payload });
export const bulkAssignTemplateSuccess = (payload) => ({ type: BULK_ASSIGN_TEMPLATE_SUCCESS, payload });
export const bulkAssignTemplateFailed = (payload) => ({ type: BULK_ASSIGN_TEMPLATE_FAILED, payload });

export const assignTemplateSpecial = (payload) => ({ type: ASSIGN_TEMPLATE_SPECIAL, payload });
export const assignTemplateSpecialSuccess = (payload) => ({ type: ASSIGN_TEMPLATE_SPECIAL_SUCCESS, payload });
export const assignTemplateSpecialFailed = (payload) => ({ type: ASSIGN_TEMPLATE_SPECIAL_FAILED, payload });

export const fetchRegions = () => ({ type: FETCH_REGIONS });
export const fetchRegionsSuccess = (payload: any) => ({ type: FETCH_REGIONS_SUCCESS, payload });

export const fetchDistricts = (payload?: any) => ({ type: FETCH_DISTRICTS, ...(payload && { payload }) });

export const fetchDistrictsSuccess = (payload: any) => ({ type: FETCH_DISTRICTS_SUCCESS, payload });
export const createLocation = (payload: any) => ({ type: CREATE_LOCATION, payload });
export const setActiveTab = (payload) => ({ type: SET_ACTIVE_TAB, payload });

export const fetchActivities = () => ({ type: FETCH_ACTIVITIES });
export const fetchActivitiesSuccess = (payload) => ({ type: FETCH_ACTIVITIES_SUCCESS, payload });

export const bulkAssignTemplateSpecial = (payload) => ({ type: BULK_ASSIGN_TEMPLATE_SPECIAL, payload });
export const bulkAssignTemplateSpecialSuccess = () => ({ type: BULK_ASSIGN_TEMPLATE_SPECIAL_SUCCESS });
export const bulkAssignTemplateSpecialFailed = (payload) => ({ type: BULK_ASSIGN_TEMPLATE_SPECIAL_FAILED, payload });

export const bulkAssignActivity = (payload) => ({ type: BULK_ASSIGN_ACTIVITY, payload });
export const bulkAssignActivitySuccess = () => ({ type: BULK_ASSIGN_ACTIVITY_SUCCESS });
export const bulkAssignActivityFailed = (payload) => ({ type: BULK_ASSIGN_ACTIVITY_FAILED, payload });

export const fetchDoctorsHooChanged = (payload) => ({ type: FETCH_DOCTORS_HOO_CHANGED, payload });
export const setIsHooChangedAction = (payload) => ({ type: SET_IS_HOO_CHANGED_ACTION, payload });

import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchDoctors } from '../../../pace/pace-actions';
import { getSpecialRangesSearch } from '../../../pace/pace-selectors';
import { omit } from 'lodash';

function SearchLocations({ activeTab }: any) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useSelector(getSpecialRangesSearch);
  useEffect(() => {
    form.setFieldsValue({ dateRange: [null, null] });
  }, [activeTab]);

  const onSubmit = (formData) => {
    const { dateRange, ...data } = formData;
    const dates = {
      dateFrom: null,
      dateTo: null,
    };
    if (dateRange) {
      dates['dateFrom'] = dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null;
      dates['dateTo'] = dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null;
    }
    dispatch(setSearchDoctors({ ...omit(data, 'searchReason'), ...dates, page: 0 }));
  };

  return (
    <div className='search-container'>
      <Form
        form={form}
        layout='vertical'
        // className='search-container'
        onFinish={(formData) => {
          onSubmit(formData);
        }}
      >
        <Form.Item key='name' label={'Employee Name'} name='employeeName' className='label-text input-field'>
          <Input allowClear />
        </Form.Item>
        <Form.Item key='id' label={'Employee ID'} name='employeeId' className='label-text input-field'>
          <Input allowClear />
        </Form.Item>
        <Form.Item key='office' label={'Office Number'} name='office' className='label-text input-field'>
          <Input allowClear />
        </Form.Item>
        <Form.Item key='errors' label={'Errors'} name='errors' className='label-text input-field'>
          <Input disabled allowClear />
        </Form.Item>
        <Form.Item className='search-btn'>
          <Button type='primary' htmlType='submit'>
            SEARCH
          </Button>
        </Form.Item>
        <Form.Item className='search-btn'>
          <Button type='primary' htmlType='button' disabled>
            UPLOAD
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SearchLocations;

import { call, put } from 'redux-saga/effects';
import { api } from '@med-fe/util';
import { fetchAuditFailed, fetchAuditSuccess } from './audit-actions';

export function* fetchAuditStartWorker({ payload }): Generator {
  try {
    const url = `/audit/search?page=${payload.page}&size=${payload.size}`;
    const data: any = yield call(api.post, url, payload);
    yield put(fetchAuditSuccess(data));
  } catch (e) {
    yield put(fetchAuditFailed(e));
  }
}

import styled from 'styled-components';
import { Button } from 'antd';

export const Title = styled.div`
  margin-right: auto;
  margin-left: 50px;
  font-size: 14px;
  font-weight: bold;
`;

export const StyledBackButton = styled(Button)`
  border: none;
  background: none;
  font-weight: bold;
  box-shadow: none;

  &:focus,
  &:hover {
    border: none;
    background: none;
  }

  img {
    margin-right: 10px;
  }
`;

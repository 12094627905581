export const FETCH_NEW_OFFICES = 'FETCH_NEW_OFFICES';
export const SET_SEARCH_NEW_OFFICES = 'SET_SEARCH_NEW_OFFICES';
export const FETCH_NEW_OFFICES_SUCCESS = 'FETCH_NEW_OFFICES_SUCCESS';
export const FETCH_NEW_OFFICES_FAILED = 'FETCH_NEW_OFFICES_FAILED';

export const setSearchNewOffices = (payload) => ({
  type: SET_SEARCH_NEW_OFFICES,
  payload,
});

export const fetchNewOffices = (payload) => ({
  type: FETCH_NEW_OFFICES,
  payload,
});

export const fetchNewOfficesSuccess = (payload) => ({
  type: FETCH_NEW_OFFICES_SUCCESS,
  payload,
});

export const fetchNewOfficesFailed = (payload) => ({
  type: FETCH_NEW_OFFICES_FAILED,
  payload,
});

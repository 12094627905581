export enum ReportName {
  SpecialAssignments = 'Special Ranges Assignments',
  ChangePattern = 'Change Pattern',
  ODAssignments = 'OD Template Assignments',
  SlotCount = 'Slot Count by Template',
  HourlySlotCount = 'Hourly Slot Count by Template',
  AveragePaceByOD = 'Average Pace by OD',
  HOOToScheduleAudit = 'HOO to Schedule Audit',
  HOOToRangeZeroAudit = 'HOO to Range 0 Audit',
  ODToNLTemplatesAudit = 'OD Schedule to NL Templates Audit',
}

import { unmappedOds_columns } from './grid-configs/unmappedOds';
import { special_ranges_columns } from './grid-configs/specialRanges';
import { od_data_incomplete_columns } from './grid-configs/odDataIncomplete';
import { no_special_ranges_columns } from './grid-configs/noSpecialRanges';
import { templatesManagementColumns } from './grid-configs/templates-management-columns';
import { audit_columns } from './grid-configs/audit';
import { new_offices_columns } from './grid-configs/newOffices';
import { unmapped_ods_hoo_changed_columns } from './grid-configs/unmappedOdsHooChanged';
import { templateRangesColumns } from './grid-configs/template-ranges-column';
import { assignTemplatesColumns } from './grid-configs/assign-templates-columns';
import { templatesMaintenanceColumns } from './grid-configs/templates-maintenance-columns';
import { exceptionManagementColumns } from './grid-configs/exception-management-coulmns';

export const grid_columns_map: any = {
  unmappedOds: unmappedOds_columns,
  specialRangesAll: special_ranges_columns,
  specialRangesBase: no_special_ranges_columns,
  odDataIncomplete: od_data_incomplete_columns,
  newBaseTemplate: unmappedOds_columns,
  hoursOfOperation: unmappedOds_columns,
  newOffices: new_offices_columns,
  templatesManagement: templatesManagementColumns,
  audit: audit_columns,
  unmappedOdsHooChanged: unmapped_ods_hoo_changed_columns,
  templateRanges: templateRangesColumns,
  assignTemplates: assignTemplatesColumns,
  templateMaintenance: templatesMaintenanceColumns,
  exceptionManagement: exceptionManagementColumns,
};

import { get, last } from 'lodash';

export const TEMPLATES_MANAGEMENT_STATE_KEY = 'TEMPLATES_MANAGEMENT_REDUCER';

export const getSearch = (state) => state[TEMPLATES_MANAGEMENT_STATE_KEY].search;
export const getSorting = (state) => state[TEMPLATES_MANAGEMENT_STATE_KEY].sorting;
export const getImportLoading = (state: any) => state[TEMPLATES_MANAGEMENT_STATE_KEY].importLoading;
export const getImportValidationData = (state: any) => state[TEMPLATES_MANAGEMENT_STATE_KEY].importValidationData;
export const getTotalCount = (state: any) => state[TEMPLATES_MANAGEMENT_STATE_KEY].totalCount;
export const getSuccessErrorReport = (state: any) => state[TEMPLATES_MANAGEMENT_STATE_KEY].successErrorReport;

export const getTemplates = (state) =>
  state[TEMPLATES_MANAGEMENT_STATE_KEY].templates.contents.map(({ id, assigned, templateName, ...rest }, idx) => ({
    key: `${id}-${idx}`,
    template: {
      assigned,
      templateName,
      id,
    },
    ...rest,
  }));

export const getTotal = (state) => state[TEMPLATES_MANAGEMENT_STATE_KEY].templates.total;
export const getLoading = (state) => state[TEMPLATES_MANAGEMENT_STATE_KEY].loading;
export const getDetailsLoading = (state) => state[TEMPLATES_MANAGEMENT_STATE_KEY].detailsLoading;
export const getRemoveLoading = (state) => state[TEMPLATES_MANAGEMENT_STATE_KEY].removeLoading;
export const getTemplateDetails = (state) => state[TEMPLATES_MANAGEMENT_STATE_KEY].templateDetails;
export const getEditedExam = (state) => state[TEMPLATES_MANAGEMENT_STATE_KEY].editedExam;
export const getExamsNameCount = (state, examTitle) =>
  getTemplateDetails(state)?.examsNameCount.find(({ examName }) => examName.toLowerCase() === examTitle.toLowerCase())
    ?.count;
export const getExamEndTimeByName = (state, examTitle) => {
  const lastExam = last(
    getTemplateDetails(state)?.examsConfiguration?.filter(
      ({ examName }) => examName.toLowerCase() === examTitle.toLowerCase()
    )
  );
  return get(lastExam, 'to', '');
};

import React from 'react';
import styled from 'styled-components';
import { joinTime } from '../../../common/helpers/date';
import { isEqual, isNil } from 'lodash';

type TProps = {
  schedules: Array<{ office: String; start: String; end: String; main?: Boolean }>;
  isDisabled: boolean;
};

export const ODScheduleCell: React.FC<TProps> = ({ schedules = [], isDisabled = false }) => (
  <Wrap>
    {schedules
      .filter(({ start }) => !isNil(start))
      .map(({ office, start, end, main }, idx, arr) => (
        <div key={idx.toString()}>
          {!isNil(start) && (
            <Item main={main} isDisabled={isDisabled}>
              {`${office}: ${joinTime(start, end)}${isEqual(idx, arr?.length - 1) ? '' : ','}`}
            </Item>
          )}
        </div>
      ))}
  </Wrap>
);

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  color: ${({ isDisabled, main }) => (main ? '#231F20' : isDisabled && '#DFDFDF')};
`;

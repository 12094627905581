import moment from 'moment';
import { concat, isEqual, isEmpty, isNil, head, last, find, sortBy, filter } from 'lodash';
import { IMonthRange } from '../../components/grid/components/employee-pattern/EmployeePattern';

export const formatDate = (str) => moment(str).format('MM/DD/YY');
export const formatDateShort = (str) => moment(str).format('MM/DD');
export const joinTime = (start, end) => {
  if (isNil(start) || isNil(end)) return '';
  const removeSpace = (str) => str.replace(' ', '');
  return `${removeSpace(start)} - ${removeSpace(end)}`.toLowerCase();
};
export const getMonth = (str) => moment(str).format('MMMM').toUpperCase();
export const getYear = (str) => moment(str).format('YYYY');
export const getSidePanelDateRange = ({ startDate, endDate }) =>
  `${getMonth(startDate)}
  ${
    getMonth(startDate) === getMonth(endDate)
      ? ''
      : ` /
  ${getMonth(endDate)}`
  } (${formatDateShort(startDate)} - ${formatDateShort(endDate)})`;

export const getWeekRangeValues = () => {
  const weeksAmount = 17;
  const weekDaysAmount = 7;
  const weekRanges: any[] = [];
  const currentDate = new Date();
  const startDate = moment(currentDate).isoWeekday('Monday');
  const maxDate = moment(currentDate).isoWeekday('Sunday').add(weeksAmount, 'week');

  while (startDate.isBefore(maxDate)) {
    const startDateLabel = startDate.isoWeekday('Monday').format('MM/DD/YY');
    const endDateLabel = startDate.isoWeekday('Sunday').format('MM/DD/YY');
    const label = `Week ${startDate.isoWeek()} ${startDateLabel} - ${endDateLabel}`;
    const value = startDate.isoWeekday('Monday').format('YYYY-MM-DD');

    weekRanges.push({ label, value });
    startDate.add(weekDaysAmount, 'days');
  }

  return weekRanges;
};

export const getMonthRangeValues = (): IMonthRange[] => {
  const monthsAmount = 12;
  const monthRanges = new Array(monthsAmount).fill(0);
  const currentDate = () => moment(new Date()).format('YYYY-MM-DD');
  const firstDateOfMonth = (i) => moment(new Date()).add(i, 'month').date(1).format('YYYY-MM-DD');
  const monthName = (i) => moment(new Date()).add(i, 'month').format('MMMM');
  const year = (i) => moment(new Date()).add(i, 'month').format('YYYY');

  return monthRanges.map((it, idx) => ({
    label: `${monthName(idx)} (${year(idx)})`,
    value: isEqual(idx, 0) ? currentDate() : firstDateOfMonth(idx),
  }));
};

export const getWeekStart = (date: string) => {
  return moment(date ? new Date(date) : new Date())
    .startOf('week')
    .add(1, 'days')
    .format('YYYY-MM-DD');
};

const getWeekNumberInMonth = (date) => {
  return (
    moment(date).isoWeek() -
    moment(date)
      .subtract(moment(date).date() - 1, 'days')
      .isoWeek() +
    1
  );
};

export const getRemainingWeeksInMonth = (date: string) => {
  const dateLast = moment(date).endOf('month').format('YYYY-MM-DD');
  return getWeekNumberInMonth(dateLast) - getWeekNumberInMonth(date) + 1;
};

export const getFirstDayOfCurrentMonth = () => {
  let currentDate = new Date();
  return moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)).format('YYYY-MM-DD');
};
export const getCurrentWeekRange = () => {
  const currentDate = new Date();
  const startDate = moment(currentDate).isoWeekday('Monday');
  const startMonth = startDate.isoWeekday('Monday').format('MMMM');
  const endMonth = startDate.isoWeekday('Sunday').format('MMMM');
  const monthLabel =
    startMonth === endMonth ? startMonth.toUpperCase() : `${startMonth.toUpperCase()} / ${endMonth.toUpperCase()}`;
  const startDateLabel = startDate.isoWeekday('Monday').format('MM/DD/YY');
  const endDateLabel = startDate.isoWeekday('Sunday').format('MM/DD/YY');

  return `${monthLabel} (${startDateLabel} - ${endDateLabel})`;
};

export const getDayIndex = (name) => Number(moment().day(name).format('d'));
export const getDayName = (idx) => moment().day(idx).format('dddd');

type TFitSchedule = {
  template: { start: String; end: String };
  office: { start: String; end: String };
};

export const getIsFitSchedule = ({ template, office }: TFitSchedule): any => {
  if (isNil(template)) return true;
  if (isNil(template?.start) || isNil(template?.end)) return true;
  if (isNil(office)) return false;
  const fakeDate = '1900-01-01, ';
  const isStartFit = moment(`${fakeDate}${template?.start}`).isSameOrAfter(`${fakeDate}${office?.start}`);
  const isEndFit = moment(`${fakeDate}${template?.end}`).isSameOrBefore(`${fakeDate}${office?.end}`);
  return { needShowWarning: !(isStartFit && isEndFit), warningMessage: 'Template outside\n HOO' };
};

export const getDontHaveTemplateForParticularHOO = ({ template, office }: TFitSchedule): any => {
  return {
    needShowWarning: !isEmpty(office?.start) && isEmpty(template),
    warningMessage: 'Template for\n this HOO not found',
  };
};

export const getIsSameMonth = (month, current) => moment(month).isSame(current, 'month');

export const getIsSameOrLaterThanToday = (date) => moment(date).isSameOrAfter(moment(), 'date');
export const getIsBeforeThanToday = (date) => moment(date).isBefore(moment(), 'date');
export const getIsSameOrBeforeMonth = (date1, date2) => moment(date1).isSameOrBefore(moment(date2), 'month');

export const getIsMonday = (date) => isEqual(moment(date).format('dddd'), 'Monday');

export const getIsFirstDateOfMonth = (date) => isEqual(moment(date).format('DD'), '01');

export const getIsToday = (date) => moment().isSame(date, 'days');

export const substractOneDay = (date) => moment(date).add(-1, 'days').format('yyyy-MM-DD');
export const getIsBetweenDates = (start, end, current) => moment(current).isBetween(start, end, undefined, '[]');

export const getDateFromMoment = (date) => moment(date).format('yyyy-MM-DD');
export const getMomentDate = (date) => moment(date);

export const get12MonthEndDate = (start) =>
  moment(start).add(12, 'M').set('date', 1).subtract(1, 'd').format('yyyy-MM-DD');

export const getDateRangeValues = ({ startMoment, endMoment }) => {
  let values: any = [];
  let currentValue = startMoment.clone();

  while (getIsBetweenDates(startMoment, endMoment, currentValue)) {
    values = concat(values, getDateFromMoment(currentValue));
    currentValue = currentValue.add(1, 'd');
  }

  return values;
};

export const getCurrentWeekStartEndDates = (week, rangeValue) => {
  const firstDateOfWeek = head<any>(week)?.date;
  const lastDateOfWeek = last<any>(week)?.date;
  const todayDate = find(week, (o) => getIsToday(o?.date))?.date;
  const firstDateOfMonth = find(week, (o) => getIsFirstDateOfMonth(o?.date))?.date;
  const firstDateOfCurrentMonth = getIsSameMonth(firstDateOfMonth, rangeValue) ? firstDateOfMonth : undefined;
  const lastDateOfCurrentMonth =
    !isNil(firstDateOfMonth) && !getIsSameMonth(firstDateOfMonth, rangeValue)
      ? substractOneDay(firstDateOfMonth)
      : undefined;
  const startWeekDate = last(sortBy(filter([firstDateOfWeek, todayDate, firstDateOfCurrentMonth], Boolean)));
  const endWeekDate = head(sortBy(filter([lastDateOfWeek, lastDateOfCurrentMonth], Boolean)));
  return { startWeekDate, endWeekDate };
};

export const filterOptionsByWeek = (options, week, rangeValue, invert) => {
  const todayDate = find(week, (o) => getIsToday(o?.date))?.date;
  const startWeekDate = todayDate || week[0].date;
  const endWeekDate = week[6].date;

  return Object.entries(options ?? {})
    ?.filter(([date, _]) =>
      invert
        ? !getIsBetweenDates(startWeekDate, endWeekDate, date)
        : getIsBetweenDates(startWeekDate, endWeekDate, date)
    )
    ?.reduce((acc, [date, option]) => ({ ...acc, [date]: option }), {});
};

export const filterEmptyOptions = (options) =>
  Object.entries(options ?? {})
    ?.filter(([_, option]) => !isEmpty(option))
    ?.reduce((acc, [date, option]) => ({ ...acc, [date]: option }), {});

export const filterNoSameMonthOptions = (options, rangeValue) =>
  Object.entries(options ?? {})
    ?.filter(([date, _]) => getIsSameMonth(date, rangeValue))
    ?.reduce((acc, [date, option]) => ({ ...acc, [date]: option }), {});

import { useEffect, useState } from 'react';
import { concat, filter, includes, indexOf, isEmpty, isEqual, isNil, isNull, map, omit, sortBy } from 'lodash';
import { bulkAssignTemplateSpecial, fetchAllTemplates } from '../../../pace/pace-actions';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTemplates } from '../../../pace/pace-selectors';
import { getDateRangeValues } from '../../../common/helpers/date';

export const useGridSelectionBulk = ({ data, search, gridId }) => {
  const dispatch = useDispatch();
  const allTemplates = useSelector(getAllTemplates);
  const [startSelection, setStartSelection] = useState<number | null>(null);
  const [endSelection, setEndSelection] = useState<number | null>(null);
  const [selected, setSelected] = useState<any[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const selectedKeys = selected.map(({ key }) => key);
  const selectedItems = selected.map(({ reason, ...rest }) => ({
    ...rest,
    specialRangesReasons: reason?.split(', '),
  }));

  useEffect(() => {
    if (gridId === 'specialRanges' || gridId === 'templatesManagement') {
      if (!isAllSelected) clearSelectedRows();
    }
  }, [search]);

  useEffect(() => {
    if (!isNull(startSelection) && !isNull(endSelection)) {
      const [start, end] = sortBy([startSelection, endSelection]);

      if (isEqual(start, end)) {
        clearSelectedRows();
      } else {
        setSelected(
          map(
            filter(data, (doctor, idx) => Number(idx) >= start && Number(idx) <= end),
            ({ key, employeeId, office, startDate, reason }) => ({ key, employeeId, office, startDate, reason })
          )
        );
      }
    }
  }, [startSelection, endSelection]);

  const clearSelectedRows = () => {
    setSelected([]);
    setStartSelection(null);
    setEndSelection(null);
  };

  const onRow = (record) => ({
    onClick: (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        event.stopPropagation();

        if (!isEqual(event.currentTarget, event.target)) {
          const { key } = record;
          const isAlreadySelected = includes(selectedKeys, key);

          setSelected((prev) => {
            if (isAlreadySelected) {
              return filter(prev, (it) => !isEqual(it.key, key));
            } else {
              return concat(prev, record);
            }
          });
        }
      }

      if (event.shiftKey) {
        event.stopPropagation();
        // @ts-ignore
        document.getSelection().removeAllRanges();

        if (!isEqual(event.currentTarget, event.target)) {
          const { key } = record;
          const keys = map(data, ({ key }) => key);
          const idx = indexOf(keys, key);

          if (isNull(startSelection)) {
            setStartSelection(idx);
          } else {
            setEndSelection(idx);
          }
        }
      }
    },
  });

  const onBulkSelectFocus = () => {
    if (isEmpty(allTemplates)) dispatch(fetchAllTemplates());
  };

  const onBulkAssign = ({ dateRange: [startMoment, endMoment], template: templateId, description }) => {
    dispatch(
      bulkAssignTemplateSpecial({
        userOfficeDtoList: map(selected, (user) => omit(user, 'key')),
        specialRangesTemplateDto: map(getDateRangeValues({ startMoment, endMoment }), (date) => ({
          date,
          templateId,
          description,
        })),
      })
    );
  };

  const toggleIsAllSelected = (value?) => {
    if (!isNil(value)) setIsAllSelected(value);
    else setIsAllSelected((prev) => !prev);
  };

  return {
    onRow,
    selectedKeys,
    selectedItems,
    onBulkSelectFocus,
    onBulkAssign,
    setSelected,
    clearSelectedRows,
    isAllSelected,
    toggleIsAllSelected,
  };
};

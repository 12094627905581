export const SEARCH_EXCEPTION = 'SEARCH_EXCEPTION';
export const SEARCH_EXCEPTION_SUCCESS = 'SEARCH_EXCEPTION_SUCCESS';
export const SEARCH_EXCEPTION_FAILED = 'SEARCH_EXCEPTION_FAILED';
export const SET_SEARCH_EXCEPTION = 'SET_SEARCH_EXCEPTION';
export const SET_SORT_CRITERIAS = 'SET_SORT_CRITERIAS';

export const FETCH_EXCEPTION_DETAILS = 'FETCH_EXCEPTION_DETAILS';
export const FETCH_EXCEPTION_DETAILS_SUCCESS = 'FETCH_EXCEPTION_DETAILS_SUCCESS';
export const FETCH_EXCEPTION_DETAILS_FAILED = 'FETCH_EXCEPTION_DETAILS_FAILED';

export const CLEAN_IMPORT_LAYOUT = 'CLEAN_IMPORT_LAYOUT';

export const FETCH_OFFICES = `FETCH_OFFICES`;
export const FETCH_OFFICES_SUCCESS = `FETCH_OFFICES_SUCCESS`;
export const FETCH_EMPLOYEES = `FETCH_EMPLOYEES`;
export const FETCH_EMPLOYEES_SUCCESS = `FETCH_EMPLOYEES_SUCCES`;

export const CREATE_EXCEPTION = `CREATE_EXCEPTION`;
export const CREATE_EXCEPTION_SUCCESS = `CREATE_EXCEPTION_SUCCESS`;
export const CREATE_EXCEPTION_FAILED = `CREATE_EXCEPTION_FAILED`;

export const setSearchException = (payload) => ({
  type: SET_SEARCH_EXCEPTION,
  payload,
});

export const searchException = (payload) => ({
  type: SEARCH_EXCEPTION,
  payload,
});

export const setSortCriterias = (payload) => ({
  type: SET_SORT_CRITERIAS,
  payload,
});

export const searchExceptionSuccess = (payload) => ({
  type: SEARCH_EXCEPTION_SUCCESS,
  payload,
});

export const searchExceptionFailed = (payload) => ({
  type: SEARCH_EXCEPTION_FAILED,
  payload,
});

export const fetchExceptionDetails = (payload) => ({
  type: FETCH_EXCEPTION_DETAILS,
  payload,
});

export const fetchExceptionDetailsSuccess = (payload) => ({
  type: FETCH_EXCEPTION_DETAILS_SUCCESS,
  payload,
});

export const fetchExceptionDetailsFailed = (payload) => ({
  type: FETCH_EXCEPTION_DETAILS_FAILED,
  payload,
});

export const cleanImportLayout = () => ({
  type: CLEAN_IMPORT_LAYOUT,
});

export const createException = (payload: any) => ({
  type: CREATE_EXCEPTION,
  payload,
});

export const createExceptionSuccess = (payload: any) => ({
  type: CREATE_EXCEPTION_SUCCESS,
  payload,
});

export const createExceptionFailed = (payload: any) => ({
  type: CREATE_EXCEPTION_FAILED,
  payload,
});
export const fetchOffices = (payload?: any) => ({ type: FETCH_OFFICES });
export const fetchOfficesSuccess = (payload: any) => ({ type: FETCH_OFFICES_SUCCESS, payload });

export const fetchEmployees = (payload?: any) => ({ type: FETCH_EMPLOYEES });
export const fetchEmployeesSuccess = (payload: any) => ({ type: FETCH_EMPLOYEES_SUCCESS, payload });

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateDetails } from '../../templates-selector';
import { getTemplateEventColor } from '../../../../common/helpers/common';
import { setEditedExam } from '../../templates-actions';
import { StyledCalendarContainer, StyledCalendar } from '../../../common/styled';
import { ColoredDateCellWrapper } from './configurations/calendar/ColoredDateCellWrapper';
import { CalendarEventWrapper } from './configurations/calendar/CalendarEventWrapper';

const localizer = momentLocalizer(moment);
const today = new Date();

export const Timetable = () => {
  const dispatch = useDispatch();
  const details = useSelector(getTemplateDetails);
  const [events, setEvents] = useState([]);

  const { components, defaultDate } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
        eventWrapper: CalendarEventWrapper,
      },
      defaultDate: new Date(),
    }),
    []
  );

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      style: {
        backgroundColor: getTemplateEventColor(event.title),
        ...(isSelected && {
          border: `1px solid #5A809A7F`,
          filter: 'drop-shadow(1px 2px 2px #5A809A7F) contrast(120%)',
        }),
      },
    }),
    []
  );

  useEffect(() => {
    return () => {
      dispatch(setEditedExam(null));
    };
  }, []);

  useEffect(() => {
    if (details) {
      setEvents(
        details.examsConfiguration.map((item) => ({
          id: item.examId,
          title: item.examName,
          start: new Date(`${moment().format('YYYY-MM-DD')} ${item.from}`),
          end: new Date(`${moment().format('YYYY-MM-DD')} ${item.to}`),
          item,
        }))
      );
    }
  }, [details]);

  return (
    <>
      <StyledCalendarContainer>
        <StyledCalendar
          components={components}
          defaultDate={defaultDate}
          events={events}
          localizer={localizer}
          step={30}
          defaultView={'day'}
          toolbar={false}
          eventPropGetter={eventPropGetter}
          min={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8)}
          max={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 22)}
        />
      </StyledCalendarContainer>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Select, Typography } from 'antd';
import { SuffixIcon } from '@med-fe/ui';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateDetails } from '../../templates-selector';
import { StyledParamsForm, StyledFormItemName, StyledFormItem, StyledPrimaryButton } from '../../../common/styled';
import { renameTemplate } from '../../templates-actions';
import { get } from 'lodash';

const { Text } = Typography;

export const MainInfo = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const details = useSelector(getTemplateDetails);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  const onSubmit = ({ templateName }) => {
    const { id } = details;

    dispatch(renameTemplate({ id, newName: templateName }));
    setIsSubmitDisabled(true);
  };

  useEffect(() => {
    if (details) {
      form.setFieldsValue(details);
    }
  }, [details]);

  return (
    <StyledInfoContainer>
      <StyledParamsForm
        form={form}
        layout='vertical'
        className='search-container'
        onValuesChange={() =>
          setIsSubmitDisabled(
            !form.getFieldValue('templateName') ||
              form.getFieldValue('templateName') === get(details, 'templateName') ||
              false
          )
        }
        onFinish={onSubmit}
      >
        <StyledFormItemName
          key='templateName'
          name='templateName'
          label='Template name'
          rules={[{ required: true, message: 'Please enter Location Name' }]}
        >
          <Input allowClear placeholder={'Text here'} />
        </StyledFormItemName>
        <StyledFormItem key='startAt' name='startAt' label='Day starts at'>
          <Input allowClear placeholder='08:00 AM' disabled />
        </StyledFormItem>
        <StyledFormItem key='templateDuration' name='templateDuration' label='Duration of the template'>
          <Select allowClear placeholder={'Select'} suffixIcon={<SuffixIcon />} disabled></Select>
        </StyledFormItem>
        <CornerFormItemsGroup>
          <StyledOverall>
            <span>Overall pace:</span>
            <Text strong>{details?.pace} / h</Text>
          </StyledOverall>
          <StyledBtnGroup>
            <StyledPrimaryButton type={'primary'} disabled>
              SAVE DRAFT
            </StyledPrimaryButton>
            <Form.Item>
              <StyledPrimaryButton type={'primary'} htmlType='submit' disabled={isSubmitDisabled}>
                UPDATE
              </StyledPrimaryButton>
            </Form.Item>
          </StyledBtnGroup>
        </CornerFormItemsGroup>
      </StyledParamsForm>
    </StyledInfoContainer>
  );
};

const StyledInfoContainer = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
  margin-right: 35px;
  padding: 15px 20px 20px;
  display: flex;
  flex-direction: column;
`;

const CornerFormItemsGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledOverall = styled.div`
  width: 140px;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin-bottom: 10px;
`;

const StyledBtnGroup = styled.div`
  align-self: flex-end;
  display: flex;

  > :first-child {
    margin-right: 10px;
  }

  &&& .ant-form-item {
    margin: 0;
  }
`;

import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getImportLoading,
  getImportValidationData,
  getSearch,
  getSuccessErrorReport,
  getTotalCount,
} from './templates-selector';
import { cleanImportLayout, updateValidatedImport } from './templates-actions';
import BaseGrid from '../grid/BaseGrid';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { StyledFormItemCheckbox } from '../common/styled';

const { Text } = Typography;

export const ValidationModal = ({ visible, data, type, tableId }) => {
  const dispatch = useDispatch();
  const isImportLoading = useSelector(getImportLoading);
  const importData = useSelector(getImportValidationData);
  const totalCount = useSelector(getTotalCount);
  const successErrorReport = useSelector(getSuccessErrorReport);
  const search = useSelector(getSearch);
  const [errorReport, setErrorReport] = useState(false);
  const [nonZeroRanges, setNonZeroRanges] = useState(false);
  useEffect(() => {
    if (Object.keys(successErrorReport)?.length > 0) {
      setErrorReport(true);
    }
  }, [successErrorReport]);
  const handleSave = () => {
    let payload;
    let flag;
    if (type === 'Delete Ranges') {
      payload = importData.map((row: any) => row.rangeNo);
    } else if (type === 'Template Maintenance') {
      payload = importData;
    } else {
      payload = importData;
      flag = 'nonZeroRanges=' + nonZeroRanges;
    }
    dispatch(updateValidatedImport(payload, type, flag));
  };
  const handleOk = () => {
    setErrorReport(false);
    setTimeout(() => {
      dispatch(cleanImportLayout());
    }, 5);
  };
  return (
    <StyledValidationModal
      visible={visible}
      style={{ display: 'flex', justifyContent: 'center' }}
      className='modal-wrap'
      onCancel={() => dispatch(cleanImportLayout())}
      footer={
        <>
          {type === 'Assign Templates' && (
            <Checkbox value={nonZeroRanges} onChange={(e) => setNonZeroRanges(e.target.checked)}>
              <b>Non Zero Ranges</b>
            </Checkbox>
          )}
          <Button type={'ghost'} onClick={() => dispatch(cleanImportLayout())}>
            Cancel
          </Button>
          <Button type={'primary'} loading={isImportLoading} onClick={handleSave}>
            {type === 'Delete Ranges' ? 'Delete Ranges' : 'Process Records'}
          </Button>
        </>
      }
    >
      {type === 'Assign Templates' && (
        <>
          <StyledValidationModal
            visible={errorReport}
            style={{ display: 'flex', justifyContent: 'center' }}
            title='Assign Template Error Report'
            className='modal-wrap'
            onCancel={handleOk}
            footer={
              <>
                <Button type={'primary'} onClick={handleOk}>
                  Ok
                </Button>
              </>
            }
          >
            {' '}
            <Descriptions column={1}>
              <Descriptions.Item label='Total Number Rows Successfully Processed'>
                {successErrorReport.processedRows}
              </Descriptions.Item>
              <Descriptions.Item label='Total Number Rows Skipped'>{successErrorReport.skippedRows}</Descriptions.Item>
              <Descriptions.Item label='Total Number Rows'>{successErrorReport.totalRows}</Descriptions.Item>
              <Descriptions.Item label='If Skipped Row, what row was skipped'>
                {successErrorReport.skippedRowsDetails}
              </Descriptions.Item>
            </Descriptions>
          </StyledValidationModal>
        </>
      )}
      <BaseGrid
        id={tableId}
        total={totalCount}
        search={search}
        loading={isImportLoading}
        isPagination={true}
        onlyTotalCount={true}
      />
    </StyledValidationModal>
  );
};

const StyledValidationModal = styled(Modal)`
  .ant-modal-content {
    width: 250%;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  text-align: center;
`;

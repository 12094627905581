import React from 'react';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

const AdvancedSearchButton = ({ onClick, active }) => {
  return (
    <Tooltip
      placement="bottom"
      color={'white'}
      overlayInnerStyle={{ color: '#003366' }}
      overlayStyle={{ fontSize: '10px' }}
      title={active ? 'Go to advanced search' : 'Return to ordinary search'}>
      <StyledButton type='primary' onClick={onClick} className='btn-advanced-search'>
        <img src='./icons/advanced-search.svg' alt='adv-search' />
      </StyledButton>
    </Tooltip>
  );
};

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0 1px 0 1px;
`;

export default AdvancedSearchButton;

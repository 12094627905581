import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { get } from 'lodash';
import { StyledPatternSelect } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import { DayOfWeekMonday } from '../../../../models/day-of-week-monday-enum';
import useSelectVirtualScroll from '../../../grid/hooks/use-select-virtual-scroll';
import {
  getSelectedWeekDaysPatterns,
  getSelectLoadingId,
  getSelectOptions,
} from '../../../../store/common/common-selector';
import {
  clearSelectOptions,
  fetchPatterns,
  setSelectedWeekDaysPatterns,
} from '../../../../store/common/common-actions';

export const WeekPatternSelect = ({ weekNumber, disabled }) => {
  const dispatch = useDispatch();
  const selectLoadingId = useSelector(getSelectLoadingId);
  const selectedWeekDayPatterns = useSelector(getSelectedWeekDaysPatterns);
  const patterns = useSelector(getSelectOptions);
  const [value, setValue] = useState(null);
  const daysInWeek = 7;

  const { onTemplatesScroll, onSelectSearch, onSelectBlur, onSelectFocus } = useSelectVirtualScroll({
    selectLoadingId,
    fetchDataRequest: (payload) => dispatch(fetchPatterns(payload)),
    clearSelectOptions: () => dispatch(clearSelectOptions()),
  });

  const onSelect = (value) => {
    setValue(value);

    dispatch(
      setSelectedWeekDaysPatterns({
        [weekNumber]: Object.keys(DayOfWeekMonday).reduce(
          (acc, dayOfWeek) => ({
            ...acc,
            [dayOfWeek]: value || null,
          }),
          {}
        ),
      })
    );
  };

  useEffect(() => {
    if (!disabled) onSelect(null);
  }, [disabled]);

  useEffect(() => {
    if (Object.keys(selectedWeekDayPatterns).includes(weekNumber.toString())) {
      const daysPatterns = Object.entries(selectedWeekDayPatterns[weekNumber]);
      const firstFilledDayPattern = get(daysPatterns, '[0][1]');

      if (daysPatterns.length === daysInWeek) {
        if (daysPatterns.every(([_, patternName]) => patternName === firstFilledDayPattern)) {
          setValue(firstFilledDayPattern);
        } else {
          setValue(null);
        }
      }
    }
  }, [selectedWeekDayPatterns]);

  return (
    <StyledPatternSelect
      showSearch
      allowClear
      disabled={disabled}
      placeholder={'Select Pattern'}
      onSelect={onSelect}
      onClear={() => onSelect(null)}
      onFocus={() => onSelectFocus(`week_${weekNumber}`)}
      onBlur={onSelectBlur}
      loading={selectLoadingId === `week_${weekNumber}`}
      onSearch={(text) => onSelectSearch(text, `week_${weekNumber}`)}
      onPopupScroll={(event) => onTemplatesScroll(event, `week_${weekNumber}`)}
      filterOption={() => true}
      value={value}
    >
      {patterns.length &&
        patterns.map((pattern, index) => (
          <Select.Option value={pattern} key={`${weekNumber}_${pattern}_${index}`}>
            {pattern}
          </Select.Option>
        ))}
    </StyledPatternSelect>
  );
};

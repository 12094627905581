import {Pagination, Select, Table, Tooltip} from 'antd';
import {useSelector} from 'react-redux';

import {grid_columns_map} from './grid-configs';
import styles from './BaseGrid.module.scss';
import {isEmpty} from 'lodash';
import {getDoctor, getODDataIncomplete, getSelectedRow} from '../../pace/pace-selectors';
import styled from 'styled-components';

function OdIncompleteGrid({id, total, search, setSearch, loading}: any) {
  const selectorMap: any = {  odDataIncomplete: getODDataIncomplete};


  const data = useSelector((state) => selectorMap[id](state));

  const {page, size} = search;
  const selectedRow = useSelector(getSelectedRow);
  const doctor = useSelector(getDoctor);

  const columns = grid_columns_map[id].map((col: any) => {
    return {
      ...col,
      ellipsis: true,
    };
  });

  return (
    <>
      <div
        className={styles['table-container']}
      >
        <div className={styles['table-wrapper']}>
          <Table
            className={styles['table-common']}
            rowClassName={(record, i) => (i === selectedRow ? 'table-selected-row' : '')}
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={!isEmpty(doctor) ? false : loading}
            scroll={{x: '100%', y: 'calc(100vh - 17.5em)'}}
          />
        </div>

        <div className={styles['pagination-container']}>
          <Pagination
            current={page + 1}
            defaultPageSize={size}
            total={total}
            showTotal={(total) => id !== 'audit' && (
              <div style={{ display: "flex" }} className="wrapper">
                <div className={styles['total-container']}>
                  <div className={styles['total-title']}>TOTAL ENTRIES</div>
                  <div className={styles['total-count']}>
                    <span>{total}</span>
                  </div>
                </div>
              </div>
            )}
            onChange={(pageNumber, pageSize) => {
              setSearch({size: pageSize, page: (size !== pageSize) ? 0 : pageNumber - 1});
            }}
          />
          <img alt="logo" className={styles['logo']} src={'./images/logo.svg'}/>
        </div>
      </div>
    </>
  );
}

export default OdIncompleteGrid;
styled(Tooltip)`
  cursor: pointer;
  width: 15px;
  padding-left: 1px;
  padding-right: 1px;
`;
styled(Select)`
  width: 200px;
  height: 24px;

  & .ant-select-selection-item {
    ${({ suggested }) => !suggested && 'font-weight: 700'};
  }
`;
styled(Select)`
  width: 230px;
`;

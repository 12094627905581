import { TextCell } from '@med-fe/ui';
import { TemplateCell } from '../cell-components/TemplateCell';

export const templatesManagementColumns = [
  {
    title: 'Pattern Name',
    dataIndex: 'template',
    key: 'template',
    width: 250,
    render: ({ assigned, templateName, id }) => (
      <TemplateCell assigned={assigned} templateName={templateName} id={id} />
    ),
  },
  {
    title: 'Average Pace',
    dataIndex: 'performance',
    key: 'performance',
    width: 120,
    align: 'center',
    sorter: true,
    render: (value) => <TextCell value={value} strong />,
  },
  {
    title: 'First Exam',
    dataIndex: 'firstExamHours',
    key: 'firstExamHours',
    width: 95,
    sorter: true,
  },
  {
    title: 'Last Exam',
    dataIndex: 'lastExamHours',
    key: 'lastExamHours',
    width: 90,
    sorter: true,
  },
  {
    title: 'Total Exam Duration',
    dataIndex: 'totalExamDuration',
    key: 'totalExamDuration',
    width: 150,
    align: 'center',
    sorter: true,
    render: (value) => <TextCell value={value} strong />,
  },
  {
    title: 'Template duration',
    dataIndex: 'templateDuration',
    key: 'templateDuration',
    width: 140,
    align: 'center',
    sorter: true,
    render: (value) => <TextCell value={value} strong />,
  },
  {}, // workaround to align columns
];

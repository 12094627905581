import React from 'react';
import { Select } from 'antd';
import { StyledPatternSelect } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import useSelectVirtualScroll from '../../../grid/hooks/use-select-virtual-scroll';
import { getPatternByWeekDay, getSelectLoadingId, getSelectOptions } from '../../../../store/common/common-selector';
import {
  clearSelectOptions,
  fetchPatterns,
  setSelectedWeekDaysPatterns,
} from '../../../../store/common/common-actions';

export const DayPatternSelect = ({ weekNumber, rampSchedule }) => {
  const dispatch = useDispatch();
  const selectLoadingId = useSelector(getSelectLoadingId);
  const patterns = useSelector(getSelectOptions);
  const selectValue = useSelector((state) => getPatternByWeekDay(state, weekNumber, rampSchedule.dayOfWeek));

  const { onTemplatesScroll, onSelectSearch, onSelectBlur, onSelectFocus } = useSelectVirtualScroll({
    selectLoadingId,
    fetchDataRequest: (payload) => dispatch(fetchPatterns(payload)),
    clearSelectOptions: () => dispatch(clearSelectOptions()),
  });

  const onSelect = (value) => {
    dispatch(setSelectedWeekDaysPatterns({ [weekNumber]: { [rampSchedule.dayOfWeek]: value || null } }));
  };

  return (
    <StyledPatternSelect
      showSearch
      allowClear
      placeholder={'Select Pattern'}
      onSelect={onSelect}
      onClear={() => onSelect(null)}
      onFocus={() => onSelectFocus(`${weekNumber}_${rampSchedule.dayOfWeek}`)}
      onBlur={onSelectBlur}
      loading={selectLoadingId === `${weekNumber}_${rampSchedule.dayOfWeek}`}
      onSearch={(text) => onSelectSearch(text, `${weekNumber}_${rampSchedule.dayOfWeek}`)}
      onPopupScroll={(event) => onTemplatesScroll(event, `${weekNumber}_${rampSchedule.dayOfWeek}`)}
      filterOption={() => true}
      value={selectValue}
    >
      {patterns.length &&
        patterns.map((pattern, index) => (
          <Select.Option value={pattern} key={`${weekNumber}_${rampSchedule.dayOfWeek}_${pattern}_${index}`}>
            {pattern}
          </Select.Option>
        ))}
    </StyledPatternSelect>
  );
};

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

export const TemplateCell = ({ assigned, templateName, id }) => {
  const { path } = useRouteMatch();

  return (
    <Wrapper>
      <StatusWrap>
        <Status assigned={assigned} />
      </StatusWrap>
      <RouteLink to={`${path}details/${id}`}>{templateName}</RouteLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusWrap = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const Status = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ assigned }) => (assigned ? '#66B06E' : '#ADADAD')};
`;

const RouteLink = styled(Link)`
  font-weight: bold;
`;

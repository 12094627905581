/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, select } from 'redux-saga/effects';
import { api, showSuccessNotification } from '@med-fe/util';
import {
  fetchExceptionDetailsFailed,
  fetchExceptionDetailsSuccess,
  searchExceptionFailed,
  searchExceptionSuccess,
  searchException,
  createExceptionSuccess,
  createExceptionFailed,
  fetchOfficesSuccess,
  fetchEmployeesSuccess,
  fetchOffices,
} from './actions';
import { showErrorNotification } from '@med-fe/util';
import { getSearch } from './selectors';

export function* searchExceptionStartWorker({ payload }): Generator {
  try {
    const url = `/exceptions/search`;
    const data = yield call(api.post, url, payload);
    yield put(searchExceptionSuccess(data));
  } catch (e) {
    yield put(searchExceptionFailed(e));
    showErrorNotification();
  }
}

export function* fetchExceptionDetailsStartWorker({ payload }): Generator {
  try {
    const data = yield call(api.get, `/exceptions/${payload.id}`);
    yield put(fetchExceptionDetailsSuccess(data));
  } catch (e) {
    yield put(fetchExceptionDetailsFailed(e));
    showErrorNotification();
  }
}

export function* fetchOfficesStartWorker({ payload }): Generator {
  try {
    const data = yield call(api.get, `/offices`);
    yield put(fetchOfficesSuccess(data));
  } catch (e) {
    console.log(e);
    showErrorNotification(null, e);
  }
}
export function* fetchEmployeesStartWorker({ payload }): Generator {
  try {
    const data = yield call(api.get, `/doctors/all`);
    yield put(fetchEmployeesSuccess(data));
  } catch (e) {
    console.log(e);
    showErrorNotification(null, e);
  }
}

export function* createExceptionStartWorker({ payload }): Generator {
  try {
    const url = '/exceptions';
    const search = yield select(getSearch);
    const nullableFields = Object.keys(payload)
      .filter((key) => payload[key] === null)
      .toString();
    const data = yield call(payload.exceptionId ? api.put : api.post, url, payload);
    if (data === 0) {
      showErrorNotification(
        'Entry already exists for this employee and office. Please either end that entry or pick a different employee or office'
      );
    } else if (data === 3) {
      showErrorNotification('Unable to update this entry due to another duplicate Active Entry or End Date is passed');
    } else {
      showSuccessNotification(`Exception ${payload.exceptionId ? 'updated' : 'created'} successfully`);
    }
    yield put(createExceptionSuccess(data));
    yield put(searchException(search));
  } catch (e) {
    yield put(createExceptionFailed(e));
    showErrorNotification(null, e);
  }
}

import {
  FETCH_DOCTORS,
  FETCH_DOCTORS_SUCCESS,
  FETCH_DOCTORS_FAILED,
  SET_SEARCH_DOCTORS,
  FETCH_SELECTED_DOCTOR,
  FETCH_SELECTED_DOCTOR_SUCCESS,
  FETCH_SELECTED_DOCTOR_FAILED,
  SET_SELECTED_DOCTOR,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILED,
  ASSIGN_TEMPLATE,
  ASSIGN_TEMPLATE_SUCCESS,
  ASSIGN_TEMPLATE_FAILED,
  SET_SELECTED_WEEK_RANGE,
  FETCH_ALL_TEMPLATES,
  FETCH_ALL_TEMPLATES_SUCCESS,
  FETCH_ALL_TEMPLATES_FAILED,
  FETCH_DOCTORS_SPECIAL,
  FETCH_DOCTORS_SPECIAL_SUCCESS,
  FETCH_DOCTORS_SPECIAL_FAILED,
  FETCH_OD_DATA_INCOMPLETE,
  FETCH_OD_DATA_INCOMPLETE_SUCCESS,
  FETCH_OD_DATA_INCOMPLETE_FAILED,
  SET_SELECTED_ROW,
  SET_REASON_SEARCH_SPECIAL_RANGES,
  FETCH_SELECTED_DOCTOR_SPECIAL,
  FETCH_SELECTED_DOCTOR_SPECIAL_SUCCESS,
  FETCH_SELECTED_DOCTOR_SPECIAL_FAILED,
  ASSIGN_TEMPLATE_SPECIAL_SUCCESS,
  FETCH_DOCTOR_AVAILABILITY,
  FETCH_DOCTOR_AVAILABILITY_SUCCESS,
  FETCH_DOCTOR_AVAILABILITY_FAILED,
  BULK_ASSIGN_TEMPLATE_SPECIAL,
  BULK_ASSIGN_TEMPLATE_SPECIAL_SUCCESS,
  BULK_ASSIGN_TEMPLATE_SPECIAL_FAILED,
  BULK_ASSIGN_TEMPLATE,
  BULK_ASSIGN_TEMPLATE_SUCCESS,
  BULK_ASSIGN_TEMPLATE_FAILED,
  FETCH_REGIONS_SUCCESS,
  SET_SEARCH_SPECIAL_RANGES,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_SELECTED_DOCTORS_SPECIAL,
  FETCH_SELECTED_DOCTORS_SPECIAL_SUCCESS,
  FETCH_SELECTED_DOCTORS_SPECIAL_FAILED,
  FETCH_SELECTED_DOCTORS_SPECIAL_ALL,
  FETCH_SELECTED_DOCTORS_SPECIAL_ALL_SUCCESS,
  FETCH_SELECTED_DOCTORS_SPECIAL_ALL_FAILED,
  SET_SELECTED_TEMPLATE,
  SET_INITIAL_SELECTED_TEMPLATES,
  CLEAR_ALL_DOCTORS_TEMPLATES,
  CLEAR_DOCTOR_WEEK_TEMPLATES,
  SET_SELECTED_TEMPLATES,
  REMOVE_ALL_DOCTORS_TEMPLATES,
  SET_ACTIVE_TAB,
  BULK_ASSIGN_ACTIVITY,
  BULK_ASSIGN_ACTIVITY_SUCCESS,
  BULK_ASSIGN_ACTIVITY_FAILED,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESS,
  SET_IS_HOO_CHANGED_ACTION,
  FETCH_DOCTORS_HOO_CHANGED_SUCCESS,
  FETCH_DOCTORS_HOO_CHANGED,
  FETCH_DOCTORS_HOO_CHANGED_FAILED,
} from './pace-actions';
import { getIsBeforeThanToday, getIsSameOrLaterThanToday, getWeekRangeValues } from '../common/helpers/date';
import { first, get, isNil, omit } from 'lodash';

export const DOCTORS_STATE_KEY = 'DOCTORS_REDUCER';
export const TEMPLATES_STATE_KEY = 'TEMPLATES_REDUCER';

interface IDoctor {
  name: string;
  employeeId: string;
  office: string;
  personalSchedule: number[];
  officeSchedule: number[];
}

interface IDoctorSpecial {
  employeeName: string;
  employeeId: string;
  office: string;
  officeName: string;
  startDate: string | null;
  category: string;
  officeSchedule: number[];
}

interface IODDataIncomplete {
  employeeName: string;
  employeeNumber: number;
  employeeId: string;
  office: string;
  npiNumber: string;
  pattern: string;
  allowToBeScheduled: string;
}

export interface IDoctorsState {
  loading: boolean;
  loadingDoctor: boolean;
  total: number;
  pages: number;
  doctors: IDoctor[];
  selectedDoctorsSpecial: IDoctorSpecial[];
  doctorsWithSpecialRanges: IDoctorSpecial[];
  odDataIncomplete: IODDataIncomplete[];
  selected: { id: string; office: string };
  doctor: {
    name?: string;
    employeeId?: string;
    office?: string;
    personalSchedule?: {
      date: string;
      dayOfWeek: string;
      start: string | null;
      end: string | null;
      duration: number;
      templateName: string | null;
      templateId: number | null;
    }[];
    saved?: boolean;
  };
  error?: any;
  search?: {};
  specialRangesSearch?: {};
  specialRangesReasonsSearch?: {};
  selectedWeekRange?: string;
  selectedRow?: null | number;
  availability: {
    employeeId: string;
    office: string;
    data: {
      empty: boolean;
      startDate: string;
    }[];
  } | null;
  regions: any[];
  districts: any[];
  activeTab: string;
  isHooChangedAction?: boolean;
}

export interface ISearchDoctors {
  base: boolean;
  weeks: number;
  size: number;
  page: number;
  startDate: any[];
  search: boolean;
}

export const defaultSearchDoctors: ISearchDoctors = {
  base: true,
  weeks: 1,
  size: 15,
  page: 0,
  startDate: get(first(getWeekRangeValues()), 'value'),
  search: false,
};

const defaultPaceState: IDoctorsState = {
  loading: false,
  loadingDoctor: false,
  total: 0,
  pages: 0,
  doctors: [],
  selectedDoctorsSpecial: [],
  doctorsWithSpecialRanges: [],
  odDataIncomplete: [],
  selected: { id: '', office: '' },
  doctor: {},
  error: null,
  search: defaultSearchDoctors,
  specialRangesSearch: defaultSearchDoctors,
  specialRangesReasonsSearch: {
    isNoLunchShifts: true,
    isHOOChanged: false,
    isPractitionerDays: false,
    isNewHires: false,
    isIntegrations: false,
    isOtherReasons: false,
    isOutsideOfHOO: false,
  },
  selectedWeekRange: '',
  selectedRow: null,
  availability: null,
  regions: [],
  districts: [],
  activeTab: 'unmappedOds',
  isHooChangedAction: false,
};

export interface ITemplatesState {
  loading: boolean;
  templates: any[];
  allTemplates: any[];
  error: any;
  errorAssign: any;
  successAssign: boolean;
  selectedTemplates: any;
  assignInProgress: boolean;
  fetchingActivitiesInProgress: boolean;
  activities: [];
}

const defaultTemplatesState: ITemplatesState = {
  loading: false,
  templates: [],
  allTemplates: [],
  error: null,
  errorAssign: null,
  successAssign: false,
  selectedTemplates: null,
  assignInProgress: false,
  fetchingActivitiesInProgress: false,
  activities: [],
};

export const doctorsReducer = (state = defaultPaceState, { type, payload }: any) => {
  switch (type) {
    case FETCH_DOCTORS:
    case FETCH_DOCTORS_HOO_CHANGED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DOCTORS_SUCCESS:
    case FETCH_DOCTORS_HOO_CHANGED_SUCCESS:
      return {
        ...state,
        loading: false,
        doctors: payload.contents,
        total: payload.total,
        pages: payload.pages,
      };
    case FETCH_DOCTORS_FAILED:
    case FETCH_DOCTORS_HOO_CHANGED_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_DOCTORS_SPECIAL:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DOCTORS_SPECIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorsWithSpecialRanges: payload.contents,
        total: payload.total,
        pages: payload.pages,
      };
    case FETCH_DOCTORS_SPECIAL_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_SELECTED_DOCTOR_SPECIAL:
      return {
        ...state,
        loading: true,
        doctor: {
          ...state.doctor,
          saved: false,
        },
      };
    case FETCH_SELECTED_DOCTOR_SPECIAL_SUCCESS:
      return {
        ...state,
        doctor: payload,
        loading: false,
      };
    case FETCH_SELECTED_DOCTOR_SPECIAL_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case FETCH_SELECTED_DOCTORS_SPECIAL:
    case FETCH_SELECTED_DOCTORS_SPECIAL_ALL:
      return {
        ...state,
        loadingDoctor: true,
      };
    case FETCH_SELECTED_DOCTORS_SPECIAL_SUCCESS:
    case FETCH_SELECTED_DOCTORS_SPECIAL_ALL_SUCCESS:
      return {
        ...state,
        selectedDoctorsSpecial: payload,
        loadingDoctor: false,
      };
    case FETCH_SELECTED_DOCTORS_SPECIAL_FAILED:
    case FETCH_SELECTED_DOCTORS_SPECIAL_ALL_FAILED:
      return {
        ...state,
        error: payload,
        loadingDoctor: false,
      };
    case FETCH_OD_DATA_INCOMPLETE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_OD_DATA_INCOMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        odDataIncomplete: payload.contents,
        total: payload.total,
        pages: payload.pages,
      };
    case FETCH_OD_DATA_INCOMPLETE_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SET_SEARCH_DOCTORS:
      return {
        ...state,
        search: { ...state.search, ...payload },
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        selectedRow: payload,
      };
    case SET_REASON_SEARCH_SPECIAL_RANGES:
      return {
        ...state,
        specialRangesReasonsSearch: { ...state.specialRangesReasonsSearch, ...payload },
      };
    case SET_SEARCH_SPECIAL_RANGES:
      return {
        ...state,
        specialRangesSearch: { ...state.specialRangesSearch, ...payload },
      };
    case SET_SELECTED_DOCTOR:
      return {
        ...state,
        doctor: payload,
      };
    case FETCH_SELECTED_DOCTOR:
      return {
        ...state,
        loadingDoctor: true,
      };
    case FETCH_SELECTED_DOCTOR_SUCCESS:
      return {
        ...state,
        doctor: payload,
        loadingDoctor: false,
      };
    case FETCH_SELECTED_DOCTOR_FAILED:
      return {
        ...state,
        error: payload,
        loadingDoctor: false,
      };
    case SET_SELECTED_WEEK_RANGE:
      return {
        ...state,
        selectedWeekRange: payload,
      };
    case ASSIGN_TEMPLATE_SPECIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        doctor: {
          ...state.doctor,
          saved: true,
        },
      };
    case FETCH_DOCTOR_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: payload,
      };
    case FETCH_DOCTOR_AVAILABILITY_FAILED:
      return {
        ...state,
        error: payload,
      };
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        regions: payload,
      };
    case FETCH_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: payload,
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
        isHooChangedAction: false,
      };
    case SET_IS_HOO_CHANGED_ACTION:
      return {
        ...state,
        isHooChangedAction: payload,
      };
    case FETCH_DOCTOR_AVAILABILITY:
    default:
      return state;
  }
};

export const templatesReducer = (state = defaultTemplatesState, { type, payload }: any) => {
  switch (type) {
    case FETCH_TEMPLATES:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: null,
        [payload.day.dayOfWeek]: payload.data,
      };
    case FETCH_TEMPLATES_FAILED:
      return {
        ...state,
        loading: null,
        error: payload,
      };
    case FETCH_ALL_TEMPLATES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        allTemplates: payload,
        loading: false,
      };
    case FETCH_ALL_TEMPLATES_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ASSIGN_TEMPLATE:
    case BULK_ASSIGN_TEMPLATE:
      return {
        ...state,
        assignInProgress: true,
        errorAssign: null,
        successAssign: false,
      };
    case ASSIGN_TEMPLATE_SUCCESS:
    case BULK_ASSIGN_TEMPLATE_SUCCESS:
      return {
        ...state,
        assignInProgress: false,
        successAssign: true,
      };
    case ASSIGN_TEMPLATE_FAILED:
    case BULK_ASSIGN_TEMPLATE_FAILED:
      return {
        ...state,
        assignInProgress: false,
        errorAssign: payload,
      };
    case BULK_ASSIGN_TEMPLATE_SPECIAL:
      return {
        ...state,
        assignInProgress: true,
        errorAssign: null,
        successAssign: false,
      };
    case BULK_ASSIGN_TEMPLATE_SPECIAL_SUCCESS:
      return {
        ...state,
        assignInProgress: false,
        successAssign: true,
      };
    case BULK_ASSIGN_TEMPLATE_SPECIAL_FAILED:
      return {
        ...state,
        assignInProgress: false,
        errorAssign: payload,
      };
    case FETCH_ACTIVITIES:
      return {
        ...state,
        fetchingActivitiesInProgress: true,
      };
    case FETCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        fetchingActivitiesInProgress: false,
        activities: payload,
      };
    case BULK_ASSIGN_ACTIVITY:
      return {
        ...state,
        assignInProgress: true,
        errorAssign: null,
        successAssign: false,
      };
    case BULK_ASSIGN_ACTIVITY_SUCCESS:
      return {
        ...state,
        assignInProgress: false,
        successAssign: true,
      };
    case BULK_ASSIGN_ACTIVITY_FAILED:
      return {
        ...state,
        assignInProgress: false,
        errorAssign: payload,
      };
    case SET_INITIAL_SELECTED_TEMPLATES:
      return {
        ...state,
        selectedTemplates: payload,
      };
    case SET_SELECTED_TEMPLATES:
      return {
        ...state,
        selectedTemplates: {
          ...state.selectedTemplates,
          ...{
            [payload.id]: {
              ...state.selectedTemplates[payload.id],
              ...payload.templates,
            },
          },
        },
      };
    case SET_SELECTED_TEMPLATE:
      const id = `${payload.employeeId}_${payload.office}`;
      const doctorDayTemplate = !isNil(payload.option)
        ? {
            [id]: {
              ...state.selectedTemplates[id],
              [payload.date]: {
                children: payload.option.children,
                key: payload.option.key,
                value: payload.option.value,
                description: payload.option.description || payload.option.children,
                schedule: payload.option.schedule,
              },
            },
          }
        : {
            [id]: {
              ...omit(state.selectedTemplates[id], payload.date),
            },
          };

      return {
        ...state,
        selectedTemplates: {
          ...state.selectedTemplates,
          ...doctorDayTemplate,
        },
      };
    case CLEAR_ALL_DOCTORS_TEMPLATES:
      return {
        ...state,
        selectedTemplates: Object.keys(state.selectedTemplates).reduce(
          (result, employeeId) => ({
            ...result,
            [employeeId]: Object.fromEntries(
              Object.entries(state.selectedTemplates[employeeId]).filter(([date, _]) => getIsBeforeThanToday(date))
            ),
          }),
          {}
        ),
      };
    case CLEAR_DOCTOR_WEEK_TEMPLATES:
      const employeeIdOffice = `${payload.employeeId}_${payload.office}`;

      return {
        ...state,
        selectedTemplates: Object.keys(state.selectedTemplates).reduce((result, employeeId) => {
          let item = state.selectedTemplates[employeeId];

          if (employeeIdOffice === employeeId) {
            item = Object.keys(state.selectedTemplates[employeeIdOffice])
              .filter((date) => !payload.dates.filter((date) => getIsSameOrLaterThanToday(date)).includes(date))
              .reduce((acc, date) => ({ ...acc, [date]: state.selectedTemplates[employeeIdOffice][date] }), {});
          }

          return { ...result, [employeeId]: item };
        }, {}),
      };
    case REMOVE_ALL_DOCTORS_TEMPLATES:
      return {
        ...state,
        selectedTemplates: null,
      };
    default:
      return state;
  }
};

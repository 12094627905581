import React from 'react';
import { isEmpty } from 'lodash';
import { ODScheduleCell } from '../cell-components/ODScheduleCell';
import { joinTime } from '../../../common/helpers/date';

export const employeePatternColumns = (doctor, isCellDisabled) => {
  return [
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      fixed: 'left',
      width: 90,
      render: (text, { time, date }) => (
        <div className={isEmpty(time) || isCellDisabled(date) ? 'grayed-out' : ''}>{text}</div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      width: 90,
      render: (text, { time, date }) => (
        <div className={isEmpty(time) || isCellDisabled(date) ? 'grayed-out' : ''}>{text}</div>
      ),
    },
    {
      title: 'Office HOO',
      dataIndex: 'ooh',
      key: 'ooh',
      fixed: 'left',
      width: 140,
      render: ({ start, end }, { date }) => (
        <div className={isCellDisabled(date) ? 'grayed-out' : ''}>{joinTime(start, end)}</div>
      ),
    },
    {
      title: 'OD Schedule',
      dataIndex: 'time',
      key: 'time',
      fixed: 'left',
      width: 200,
      render: (time, { date }) => <ODScheduleCell schedules={time} isDisabled={isCellDisabled(date)} />,
    },
    ...(!isEmpty(doctor)
      ? doctor?.offices?.map((item) => ({
          dataIndex: ['template', item.office],
          key: item.office,
          width: 220,
          className: 'bordered-left',
        }))
      : []),
    {
      className: 'empty-column',
    },
  ];
};

import React, { useEffect, useMemo, useState } from 'react';
import { Spin, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { StyledDrawer } from './styled/styled';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { bulkAssignActivity, fetchActivities } from '../../../pace/pace-actions';
import {
  getActivities,
  getAssignInProgress,
  getErrorAssign,
  getFetchingActivitiesInProgress,
  getSuccessAssign,
} from '../../../pace/pace-selectors';

export const SlotBulkUpdateDrawer = ({ onClose, selectedItems, isDrawerVisible }) => {
  const dispatch = useDispatch();
  const fetchingActivitiesInProgress = useSelector(getFetchingActivitiesInProgress);
  const assignInProgress = useSelector(getAssignInProgress);
  const spinning = fetchingActivitiesInProgress || assignInProgress;
  const successAssign = useSelector(getSuccessAssign);
  const errorAssign = useSelector(getErrorAssign);
  const activities = useSelector(getActivities);
  const options = useMemo(() => activities.map((activity) => ({ value: activity, label: activity })), [activities]);
  const [fromActivity, setFromActivity] = useState<null | string>(null);
  const [toActivity, setToActivity] = useState<null | string>(null);

  useEffect(() => {
    dispatch(fetchActivities());
  }, []);

  useEffect(() => {
    if (successAssign && !errorAssign) {
      setFromActivity(null);
      setToActivity(null);
    }
  }, [successAssign, errorAssign]);

  const onAssign = () => {
    dispatch(
      bulkAssignActivity({
        fromActivity,
        toActivity,
        specialRangesByEmployeeOfficeDtos: [
          ...selectedItems.map(({ employeeId, office, startDate }) => ({
            employeeId,
            office,
            date: startDate,
          })),
        ],
      })
    );
  };

  return (
    <StyledDrawer
      placement='right'
      visible={isDrawerVisible}
      width={500}
      closeIcon={<img src={'./icons/close-blue.svg'} alt='close-icon' />}
      className='pace-drawer-panel'
      title={`Flex Slot Types - ${selectedItems.length} employees selected`}
      closable={true}
      onClose={onClose}
      $isSlotDrawer
    >
      <Spin spinning={spinning}>
        <Wrapper>
          <StyledSelect
            allowClear
            options={options.filter(({ value }) => !isEqual(value, toActivity))}
            onChange={(value) => setFromActivity(value)}
            value={{ value: fromActivity, label: fromActivity }}
          />
          <StyledArrowWrapper>
            <ArrowRightOutlined />
          </StyledArrowWrapper>
          <StyledSelect
            allowClear
            options={options.filter(({ value }) => !isEqual(value, fromActivity))}
            onChange={(value) => setToActivity(value)}
            value={{ value: toActivity, label: toActivity }}
          />
          <StyledButton type='primary' disabled={!fromActivity || !toActivity} onClick={onAssign}>
            Assign
          </StyledButton>
        </Wrapper>
      </Spin>
    </StyledDrawer>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
`;

const StyledSelect = styled(Select)`
  width: 120px;
`;

const StyledArrowWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledButton = styled(Button)`
  margin-left: 15px;
`;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Collapse, Spin, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { eightWeeksNumbers } from '@med-fe/util';
import { WeekPanelHeader } from '../styled';
import {
  getRampSettings,
  getSelectedWeekDaysPatterns,
  getSettingsLoader,
} from '../../../../store/common/common-selector';
import { ReasonRampSettingsDto } from '../../../../models/ramp-settings-dto';
import { WeekPatternSelect } from './WeekPatternSelect';
import { DaysList } from './DaysList';
import { get, isEmpty } from 'lodash';

const { Panel } = Collapse;
const { Text } = Typography;

export const WeeksPanel = () => {
  const rampsSettings: ReasonRampSettingsDto = useSelector(getRampSettings);
  const selectedWeekDayPatterns = useSelector(getSelectedWeekDaysPatterns);
  const settingsLoader: boolean = useSelector(getSettingsLoader);
  const [splitChecked, setSplitChecked] = useState(
    eightWeeksNumbers.reduce((acc, week) => ({ ...acc, [week]: false }), {})
  );

  useEffect(() => {
    if (rampsSettings?.rampSettingsDtoList && !isEmpty(selectedWeekDayPatterns)) {
      setSplitChecked(
        rampsSettings.rampSettingsDtoList.reduce((acc, { weekNumber, rampScheduleDtos }) => {
          const daysPatterns = selectedWeekDayPatterns.hasOwnProperty(weekNumber.toString())
            ? Object.entries(selectedWeekDayPatterns[weekNumber])
            : [];
          const firstFilledDayPattern = get(daysPatterns, '[0][1]');

          return {
            ...acc,
            [weekNumber]:
              daysPatterns.length === 1 ||
              daysPatterns.some(([_, patternName]) => patternName !== firstFilledDayPattern),
          };
        }, {})
      );
    }
  }, [rampsSettings, selectedWeekDayPatterns]);

  return (
    <Spin spinning={settingsLoader}>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className='site-collapse-custom-collapse'
        ghost={true}
        activeKey={Object.keys(splitChecked)
          .filter((key) => splitChecked[key])
          .map((key) => key.toString())}
      >
        {rampsSettings?.rampSettingsDtoList?.map(({ weekNumber, rampScheduleDtos }) => (
          <Panel
            showArrow={false}
            collapsible={splitChecked[weekNumber] ? 'header' : 'disabled'}
            header={
              <WeekPanelHeader>
                <Text strong>{`Week ${weekNumber + 1}`}</Text>
                <WeekPatternSelect weekNumber={weekNumber} disabled={splitChecked[weekNumber]} />
                <Checkbox
                  checked={splitChecked[weekNumber]}
                  onChange={(e) => {
                    e.stopPropagation();
                    setSplitChecked({ ...splitChecked, [weekNumber]: e.target.checked });
                  }}
                >
                  Split the week
                </Checkbox>
              </WeekPanelHeader>
            }
            key={weekNumber}
            className='site-collapse-custom-panel'
          >
            <DaysList rampScheduleDtos={rampScheduleDtos} weekNumber={weekNumber} />
          </Panel>
        ))}
      </Collapse>
    </Spin>
  );
};

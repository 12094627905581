import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tabs } from 'antd';

import {
  fetchDoctors,
  setSearchDoctors,
  fetchDoctorsSpecial,
  fetchOdDataIncomplete,
  setSearchSpecialRanges,
  setActiveTab,
  fetchDoctorsHooChanged,
} from './pace-actions';
import { paceTabsConfig } from './pace-tabs-config';
import {
  getActiveTab,
  getDoctorsTotal,
  getIsHooChangedAction,
  getIsLoading,
  getSearchDoctors,
  getSpecialRangesReasonsSearch,
  getSpecialRangesSearch,
} from './pace-selectors';

import BaseGrid from '../components/grid/BaseGrid';
import SearchEmployee from '../components/header/search/SearchEmployee';
import OdIncompleteGrid from '../components/grid/OdIncompleteGrid';
import SearchSpecialRanges from '../components/header/search/SearchSpecialRanges';
import { setSortCriterias } from '../components/templates/templates-actions';
import SearchNewOffices from '../components/header/search/SearchNewOffices';
import { getIsNewOfficesLoading, getNewOfficesTotal, getSearchNewOffices } from './new-offices/new-offices-selectors';
import { fetchNewOffices, setSearchNewOffices } from './new-offices/new-offices-actions';
import { reduce, values } from 'lodash';
import { searchElements } from '../constants';

const { TabPane } = Tabs;

function Pace() {
  const dispatch = useDispatch();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const activeTab = useSelector(getActiveTab);
  const total = useSelector(getDoctorsTotal);
  const newOfficesTotal = useSelector(getNewOfficesTotal);
  const search = useSelector(getSearchDoctors);
  const specialRangesSearch = useSelector(getSpecialRangesSearch);
  const specialRangesReasonsSearch = useSelector(getSpecialRangesReasonsSearch);
  const loading = useSelector(getIsLoading);
  const newOfficesLoading = useSelector(getIsNewOfficesLoading);
  const newOfficesSearch = useSelector(getSearchNewOffices);
  const isHooChangedAction = useSelector(getIsHooChangedAction);
  const [showException, setShowException] = useState(false);
  const resetedValuesToDispatch = useMemo(
    () => reduce(values(searchElements), (acc, it) => ({ ...acc, [it]: false }), {}),
    []
  );

  useEffect(() => {
    if (activeTab === 'unmappedOds') {
      if (isHooChangedAction) {
        dispatch(fetchDoctorsHooChanged(search));
      } else {
        dispatch(fetchDoctors(search));
      }
    } else if (activeTab === 'specialRanges') {
      let reasonSearch;
      if (isSearch) {
        reasonSearch = { ...specialRangesSearch, ...specialRangesReasonsSearch };
      } else {
        if (isAllSelected) {
          reasonSearch = {
            ...specialRangesSearch,
            ...resetedValuesToDispatch,
          };
        } else {
          reasonSearch = {
            ...specialRangesSearch,
            ...{ ...resetedValuesToDispatch, ...{ isNoLunchShifts: !isAllSelected } },
          };
        }
      }
      dispatch(fetchDoctorsSpecial({ ...reasonSearch, hideException: showException }));
      setIsSearch(false);
    } else if (activeTab === 'odDataIncomplete') {
      dispatch(fetchOdDataIncomplete(search));
    } else if (activeTab === 'newOffices') {
      dispatch(fetchNewOffices(newOfficesSearch));
    }
    dispatch(setSortCriterias({}));
    // eslint-disable-next-line
  }, [search, specialRangesSearch, newOfficesSearch, activeTab, isHooChangedAction, showException]);

  useEffect(() => {
    dispatch(setActiveTab('unmappedOds'));
  }, []);

  const onPageChange = (searchData, key) => {
    if (key === 'specialRanges') {
      dispatch(setSearchSpecialRanges(searchData));
    } else if (key === 'newOffices') {
      dispatch(setSearchNewOffices(searchData));
    } else {
      dispatch(setSearchDoctors(searchData));
    }
  };

  const onTabChange = (activeKey) => {
    if (activeKey === 'newOffices') {
      dispatch(fetchNewOffices({ page: 0 }));
    } else {
      dispatch(setSearchDoctors({ page: 0, dateFrom: null, dateTo: null }));
    }
    dispatch(setActiveTab(activeKey));
  };

  return (
    <>
      {activeTab === 'specialRanges' ? (
        <SearchSpecialRanges
          activeTab={activeTab}
          search={specialRangesSearch}
          isAllSelected={isAllSelected}
          setIsSearch={setIsSearch}
        />
      ) : activeTab === 'newOffices' ? (
        <SearchNewOffices activeTab={activeTab} search={newOfficesSearch} />
      ) : (
        <SearchEmployee activeTab={activeTab} search={search} />
      )}

      <Tabs defaultActiveKey={'unmappedOds'} onChange={onTabChange} destroyInactiveTabPane>
        {Object.entries(paceTabsConfig(null)).map(([key, tab]) => (
          <TabPane tab={tab.title.toUpperCase()} key={key} disabled={tab.disabled}>
            <TabContainer>
              {(key === 'unmappedOds' || key === 'specialRanges') && (
                <BaseGrid
                  id={key}
                  activeTab={activeTab}
                  total={total}
                  search={key === 'specialRanges' ? specialRangesSearch : search}
                  setSearch={(searchData) => onPageChange(searchData, key)}
                  loading={loading}
                  setIsAllSelected={setIsAllSelected}
                  setIsSearch={setIsSearch}
                  showException={showException}
                  setShowException={setShowException}
                />
              )}
              {key === 'odDataIncomplete' && (
                <OdIncompleteGrid
                  id={key}
                  activeTab={activeTab}
                  total={total}
                  search={search}
                  setSearch={onPageChange}
                  loading={loading}
                />
              )}
              {key === 'newOffices' && (
                <BaseGrid
                  id={key}
                  activeTab={activeTab}
                  total={newOfficesTotal}
                  search={newOfficesSearch}
                  setSearch={(searchData) => onPageChange(searchData, key)}
                  loading={newOfficesLoading}
                />
              )}
            </TabContainer>
          </TabPane>
        ))}
      </Tabs>
    </>
  );
}

export default Pace;

const TabContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

import { ConfigTypes } from '../../components/templates/models/config-types.enum';

export const getTemplateEventColor = (title) => {
  const name = title?.replace(/ /g, '').toLowerCase();

  //  TODO: handle all types: specialty exam,  exam, office use only, texam / cl, special testing, dispense / repair.
  switch (name) {
    case ConfigTypes.RcOv:
      return '#10A6E7';
    case ConfigTypes.Flex:
      return '#87959F';
    case ConfigTypes.TExam:
      return '#DDE4EE';
    case ConfigTypes.TExamCL:
      return '#AEC8DA';
    case ConfigTypes.Lunch:
      return '#DFD836';
    default:
      return '#79CEF1';
  }
};

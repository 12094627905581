import { api, showErrorNotification, showSuccessNotification } from '@med-fe/util';
import { getSearchAudit } from '../audit/audit-reducer';
import { call, put, select } from 'redux-saga/effects';
import { exportFileFailed, exportFileSuccess } from './header-actions';
import { getSearchDoctors, getSpecialRangesReasonsSearch, getSpecialRangesSearch } from '../../pace/pace-selectors';
import { getSearchNewOffices } from '../../pace/new-offices/new-offices-selectors';
import { getSearch } from '../ExceptiopnManagement/selectors';

export function* exportFileStartWorker({ payload }) {
  try {
    const maxSize = 100000; // according to BE implementation
    let search = {};
    console.log(payload.pathname);
    if (payload.pathname === '/audit') {
      search = yield select(getSearchAudit);
    } else if (payload.tab === 'unmappedOds' || payload.tab === 'odDataIncomplete') {
      search = yield select(getSearchDoctors);
    } else if (payload.tab === 'specialRanges') {
      search = { ...(yield select(getSpecialRangesSearch)), ...(yield select(getSpecialRangesReasonsSearch)) };
    } else if (payload.tab === 'newOffices') {
      search = yield select(getSearchNewOffices);
    }

    if (payload.pathname === '/exception-management') {
      search = yield select(getSearch);
    }

    yield call(
      api.downloadFile,
      payload.pathname === '/exception-management'
        ? '/exceptions/export'
        : `/export/${payload.pathname === '/audit' ? 'audit' : payload.tab}`,
      {
        ...search,
        page: 0,
        size: maxSize,
        type: payload.type,
        fileName: 'export',
      }
    );
    showSuccessNotification('File successfully generated');
    yield put(exportFileSuccess());
  } catch (e) {
    showErrorNotification(null, e);
    yield put(exportFileFailed());
    console.log(e);
  }
}

import React from 'react';
import { Button } from 'antd';
import { useSignIn } from './use-sign-in';
import { Wrapper, Logo, StyledText, Card } from '../styled';

export const Login = ({ instance }) => {
  const { onSignIn } = useSignIn({ instance });

  return (
    <Wrapper>
      <Card>
        <Logo alt='mini-logo' src='./images/sign-in-logo.svg' width={120} height={120} />
        <StyledText strong>{'You are not signed in.\nPlease sign in:'}</StyledText>
        <Button type='primary' size='small' onClick={onSignIn}>
          SIGN IN
        </Button>
      </Card>
    </Wrapper>
  );
};

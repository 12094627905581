import { call, put } from 'redux-saga/effects';
import { api } from '@med-fe/util';
import { showErrorNotification } from '@med-fe/util';
import { fetchNewOfficesFailed, fetchNewOfficesSuccess } from './new-offices-actions';

export function* fetchNewOfficesStartWorker({ payload }): Generator {
  try {
    const url = `/offices/search?page=${payload.page}&size=${payload.size}`;
    const data = yield call(api.post, url, { ...payload });
    yield put(fetchNewOfficesSuccess(data));
  } catch (e) {
    showErrorNotification();
    yield put(fetchNewOfficesFailed(e));
  }
}

import React from 'react';
import { IncompleteCell } from '../cell-components/IncompleteCell';

export const od_data_incomplete_columns = [
  {
    title: 'Employee name',
    dataIndex: 'employeeName',
    key: 'employeeName',
    width: 250,
  },
  {
    title: 'Employee Number',
    dataIndex: 'employeeNumber',
    key: 'employeeNumber',
    width: 140,
  },
  {
    title: 'Employee ID W2W',
    dataIndex: 'employeeIdW2W',
    key: 'employeeIdW2W',
    width: 140,
    render: (value) => <IncompleteCell value={value} />,
  },
  {
    title: 'Employee id',
    dataIndex: 'employeeId',
    key: 'id',
    width: 140,
    render: (value) => <IncompleteCell value={value} />,
  },
  {
    title: 'Office number',
    dataIndex: 'office',
    key: 'office',
    width: 140,
    render: (value) => <IncompleteCell value={value} />,
  },
  {
    title: 'Npi Number',
    dataIndex: 'npiNumber',
    key: 'npiNumber',
    width: 140,
    render: (value) => <IncompleteCell value={value} />,
  },
  {
    title: 'Pattern',
    dataIndex: 'pattern',
    key: 'pattern',
    width: 140,
    render: (value) => <IncompleteCell value={value} />,
  },
  {
    title: 'Allow to be Scheduled',
    dataIndex: 'allowToBeScheduled',
    key: 'allowToBeScheduled',
    width: 170,
    render: (value) => <IncompleteCell value={value} />,
  },
  {
    ellipsis: true,
  }, // empty column, workaround to have column width fixed
];

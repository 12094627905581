import React from 'react';
import { Select, Typography } from 'antd';
import {
  StyledLunchConfigForm,
  StyledFormItem,
  StyledFormItemCheckbox,
  StyledPrimaryButton,
  StyledConfCornerForm,
} from '../../../../common/styled';
import { SuffixIcon } from '@med-fe/ui';
import Checkbox from 'antd/es/checkbox/Checkbox';

const { Text } = Typography;

export const Lunch = () => {
  return (
    <>
      <Text strong>Lunch automatically is placed in the middle of the working day</Text>

      <StyledLunchConfigForm layout='vertical' className='search-container'>
        <StyledFormItemCheckbox key='hourBefore' name='hourBefore' label='1 hour before'>
          <Checkbox disabled checked={false} />
        </StyledFormItemCheckbox>
        <StyledFormItemCheckbox key='hourLater' name='hourLater' label='1 hour later'>
          <Checkbox disabled checked={false} />
        </StyledFormItemCheckbox>
        <StyledFormItem key='lunchOffset ' name='lunchOffset' label='Lunch Offset'>
          <Select allowClear placeholder={'Select'} suffixIcon={<SuffixIcon />} disabled />
        </StyledFormItem>
        <StyledConfCornerForm>
          <StyledPrimaryButton type={'primary'} disabled>
            INSERT
          </StyledPrimaryButton>
        </StyledConfCornerForm>
      </StyledLunchConfigForm>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select, Typography } from 'antd';
import { ReasonSection, StyledRampsDrawer } from '../styled';
import {
  fetchRampSettingsSuccess,
  fetchRampsSettings,
  setSelectedWeekDaysPatterns,
  updateRampsSettings,
} from '../../../../store/common/common-actions';
import { SuffixIcon } from '@med-fe/ui';
import { WeeksPanel } from './WeeksPanel';
import { getSettingsLoader } from '../../../../store/common/common-selector';

const { Text } = Typography;

export const RampsDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const settingsLoader: boolean = useSelector(getSettingsLoader);
  const specialRangesReasons = [
    { value: 'INTEGRATIONS', label: 'Integration' },
    { value: 'NEW_HIRES', label: 'New Hire' },
  ];
  const [reasonValue, setReasonValue] = useState(specialRangesReasons[0].value);

  const onDrawerClose = () => {
    dispatch(setSelectedWeekDaysPatterns(null));
    dispatch(fetchRampSettingsSuccess(null));
    onClose();
  };

  const saveSettings = () => {
    dispatch(updateRampsSettings());
  };

  useEffect(() => {
    dispatch(fetchRampsSettings({ specialRangesReasons: reasonValue }));
  }, [reasonValue]);

  return (
    <StyledRampsDrawer
      title={'RAMPS SETTINGS'}
      placement={'right'}
      closable={true}
      onClose={onDrawerClose}
      visible={visible}
      width={520}
      headerStyle={{ backgroundColor: '#cbcaca', fontWeight: 'bold' }}
    >
      <ReasonSection>
        <Text strong>Special Ranges Reasons</Text>
        <Select
          disabled={settingsLoader}
          placeholder={'Select Reason'}
          suffixIcon={<SuffixIcon />}
          options={specialRangesReasons}
          onSelect={setReasonValue}
          value={reasonValue}
        />
        <Button disabled={settingsLoader} type={'primary'} onClick={saveSettings}>
          SAVE
        </Button>
      </ReasonSection>
      <WeeksPanel />
    </StyledRampsDrawer>
  );
};

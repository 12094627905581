export const EXPORT_FILE = 'EXPORT_FILE';
export const EXPORT_FILE_SUCCESS = 'EXPORT_FILE_SUCCESS';
export const EXPORT_FILE_FAILED = 'EXPORT_FILE_FAILED';

export const exportFile = (payload) => ({
  type: EXPORT_FILE,
  payload,
});

export const exportFileSuccess = () => ({
  type: EXPORT_FILE_SUCCESS,
});

export const exportFileFailed = () => ({
  type: EXPORT_FILE_FAILED,
});

import React, { useEffect } from 'react';
import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getLoading, getSearch, getSorting, getTotal } from './templates-selector';
import { setSearchTemplates, searchTemplates, setSortCriterias } from './templates-actions';
import BaseGrid from '../grid/BaseGrid';
import { SearchTemplates } from '../header/search/SearchTemplates';

export const Templates = () => {
  const dispatch = useDispatch();
  const search = useSelector(getSearch);
  const sorting = useSelector(getSorting);
  const total = useSelector(getTotal);
  const loading = useSelector(getLoading);

  useEffect(() => {
    dispatch(searchTemplates({ ...search, sorting }));
  }, [search]);

  const onPageChange = (pageData) => {
    dispatch(setSearchTemplates({ ...search, ...omit(pageData, sorting) }));
    if (pageData.sorting) {
      dispatch(setSortCriterias(pageData.sorting));
    }
  };

  return (
    <>
      <SearchTemplates />
      <BaseGrid id='templatesManagement' total={total} search={search} setSearch={onPageChange} loading={loading} />
    </>
  );
};
